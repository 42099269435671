import { useEffect, useState } from "react";
import { ItemMenu } from "../../components/UI/ItemMenu";
import { useHistory, createSearchParams, useLocation } from "react-router-dom";
import {
  GetWindowState,
  defaultReducer,
  usePageState,
  useQuery,
} from "../../Utils";
import { PopUpWrapper } from "../../components/PopUpWrapper";
import {
  StringInputMODE,
  StringInputMenu,
} from "../../components/UI/StringInputMenu";

import {
  EDIT_PROFILE_MODE,
  ItemMenuChangeValue,
} from "../../components/UI/ItemMenuChangeValue";

export const PauseScreen = (props) => {
  const history = useHistory();
  const location = useLocation();
  const state = GetWindowState();

  const [popup, setPopUp] = useState(null);
  const [pageState, setPageState] = usePageState(
    defaultReducer,
    state
      ? state
      : {
          menu: {
            LEFT: { label: "Back", route: "settings" },
            UP: null,
            RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
            MIDDLE: null,
            DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
          },
          window: null,
          activeItemIndex: 0,
          menuItems: [
            {
              unit: " ",
              label: "Type",
              selectInput: true,
              local: true,
              value: props?.pro?.pauseScreenValues?.type,
              possibleValues: ["Vitals", "Logo"],
            },
            {
              unit: "min",
              label: "Delay",
              selectInput: true,
              local: true,
              value: props?.pro?.pauseScreenValues?.delay / 1000 / 60,
              possibleValues: [1, 5, 10, 20, 30, 60, 120],
            },
          ],
        }
  );

  const setWindowState = (state, windowState) => {
    if(windowState!=undefined){
      setPageState((prev) => {
        return {
          ...prev,
          menu: state,
          window: { ...prev.window, state: windowState, menu: state },
        };
      });
      props.navigation.setMenu(state);
      return;
    }
    setPageState((prev) => {
      return {
        ...prev,
        menu: state.menu,
        window: { ...prev.window, state: state, menu: state.menu },
      };
    });
    props.navigation.setMenu(state.menu);
  };

  const renderPopUp = (state) => {
    let popUp = null;
    // switch (state.window?.name) {
    //   case "profiles":
    //     popUp = <StringInputMenu  name={"profiles"}setState={setWindowState} state={state} />;
    // }
    return popUp;
  };

  const handleLeft = (navigator, action, menu) => {
    if (pageState.window != null) {
      if (menu[action.button].route == "NAVIGATE_Cancel")
        setPageState((prev) => {
          return { ...prev, window: null };
        });
      if (menu[action.button].route == "NAVIGATE_Clear")
        setPageState((prev) => {
          return { ...prev, window: null };
        });
      return;
    }
  };

  const handleRight = (navigator, action, menu) => {
    if (pageState.window != null) {
      if (menu[action.button].route == "NAVIGATE_Cancel")
        setPageState((prev) => {
          return { ...prev, window: null };
        });
      if (menu[action.button].route == "NAVIGATE_Clear")
        setPageState((prev) => {
          return { ...prev, window: null };
        });
      return;
    }
  };

  const handleDown = (navigator, action, menu) => {
    if (pageState.window != null) {
      return;
    }
    setPageState((prev) => {
      let prevIndex = prev.activeItemIndex;
      let menuItems = prev.menuItems;
      let newActiveIndex =
        prevIndex < menuItems.length - 1 ? prevIndex + 1 : prevIndex;
      return { ...prev, activeItemIndex: newActiveIndex };
    });
  };
  const handleUp = (navigator, action, menu) => {
    console.log({ navigator: navigator });
    console.log({ action: action });
    console.log({ menu: menu });
    if (pageState.window != null) {
      if (menu[action.button].route == "NAVIGATE_EDIT")
        setPageState((prevState) => {
          return {
            ...prevState,
            window: { ...prevState.window, mode: StringInputMODE.MODE_EDIT },
          };
        });

      return;
    }
    setPageState((prev) => {
      let prevIndex = prev.activeItemIndex;
      let newActiveIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
      return { ...prev, activeItemIndex: newActiveIndex };
    });
  };

  const localNavigation = (navigator, action, menu) => {
    if (action.type == "CLICK") {
      if (pageState.window?.state?.windowNavigation != null) {
        console.log("executing window naw");
        pageState.window?.state?.windowNavigation(
          navigator,
          action,
          menu,
          pageState?.window?.state
        );
        return;
      }
    }

    if (action.type == "CLICK") {
      switch (action.button) {
        case "DOWN":
          handleDown(navigator, action, menu);
          break;
        case "LEFT":
          handleLeft(navigator, action, menu);
          break;
        case "UP":
          handleUp(navigator, action, menu);
          break;
        case "RIGHT":
          setPageState((prevState) => {
            if (prevState.menuItems[prevState.activeItemIndex].window != null) {
              return {
                ...prevState,
                window: {
                  name: prevState.menuItems[prevState.activeItemIndex].window,
                },
              };
            } else if (
              prevState.menuItems[prevState.activeItemIndex].route != null
            ) {
              history.push(
                prevState.menuItems[prevState.activeItemIndex].route
              );
              return prevState;
            } else if (
              prevState.menuItems[prevState.activeItemIndex]?.selectInput
            ) {
            } else {
              return prevState;
            }
          });
          //   handleRight(activeItemIndex)
          break;
      }
    }
  };
  // const updateNavigation = () => {
  //   setPageState((old) => {
  //     const showUpButton = old.activeItemIndex > 0;
  //     const showDownButton = old.activeItemIndex < old.menuItems?.length - 1;
  //     old.menu.UP = showUpButton
  //       ? { label: "UP", route: "UP", local: true }
  //       : null;
  //     old.menu.DOWN = showDownButton
  //       ? { label: "DOWN", route: "DOWN", local: true }
  //       : null;
  //     return old;
  //   });
  // };

  useEffect(() => {
    props.navigation.setMenu(pageState.menu);
    props.navigation.addLocalNavigation(localNavigation);
    return () => {
      props.navigation.addLocalNavigation(null);
    };
  }, [pageState]);

  useEffect(() => {
    const state = pageState;
    const searchParams = new URLSearchParams();
    searchParams.append("windowState", JSON.stringify(state));
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [pageState]);

  // useEffect(() => {
  //   updateNavigation();
  // }, [pageState.activeItemIndex]);
  return (
    <ItemMenuChangeValue
      name={"screen"}
      label={"screen"}
      onComplete={(pauseScreenData) => {
        let delay = parseInt(pauseScreenData[1].value);
        props.pro.setPauseScreenDelay(delay * 1000 * 60);
        props.pro.setPauseScreenType(pauseScreenData[0].value);
      }}
      onCancel={() => {
        setPageState((prev) => {
          prev.window.state.editProfileValueMode =
            EDIT_PROFILE_MODE.MODE_DEFAULT;
          console.log({ prevStat: prev });
          return {
            ...prev,
            window: {
              ...prev.window,
              state: {
                ...prev.window.state,
                editProfileValueMode: EDIT_PROFILE_MODE.MODE_DEFAULT,
                menuItems: prev.window.state.savedState,
                savedState: null,
                updatedMenuItems: null,
              },
            },
            menu: {
              LEFT: { label: "Back", route: "settings" },
              UP: null,
              RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
              MIDDLE: null,
              DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
            },
          };
        });
      }}
      value={[]}
      menuItems={pageState.menuItems}
      setState={setWindowState}
      menu={state?.window?.menu}
      state={state?.window?.state}
    />
  );
};
