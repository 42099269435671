import { Box, Typography } from "@mui/material";
import { useQuery } from "../../Utils";
import { ItemMenu } from "./ItemMenu";
import { useHistory, useLocation } from "react-router";
import { useEffect, useState } from "react";
import { usePageState, defaultReducer } from "../../Utils";
import { GetWindowState } from "../../Utils";
import { CustomizeProfile, changeProfileValue, isProfileCustom } from "./CustomizeProfile";
import { Translate, translate } from "react-i18nify";
import { EDIT_PROFILE_MODE } from "./SelectProfileMenu";
import { ConfirmRiskyProfile } from "./ConfirmRiskyProfile";
import { PopUpWrapper } from "../PopUpWrapper";
import { colors } from "../../Style";
import { ItemMenuChangeValue } from "./ItemMenuChangeValue";

const findActiveIndex = (value, values) => {
  return values.indexOf(value);
};
const findHighestId = (values) => {
  return values.reduce((highest, obj) => {
    return obj.id > highest ? obj.id : highest;
  }, 0);
};

export const ACTIVE_MENU = {
  ACTIVE_MENU_SETTINGS: "ACTIVE_MENU_SETTINGS",
  ACTIVE_MENU_PROFILE: "ACTIVE_MENU_PROFILE",
};
const generateMenuItems = (testProfile, allProfiles,selectedProfile) => {
  let profiles = allProfiles?.map((item) => item.name);
  return [
    { label: "Start test", route: "start_test", local: true },
    {
      label: "Name",
      value: testProfile.name,
      local: true,
      notSelectable: true,
      editName: "name",
    },
    {
      label: "Duration",
      editName: "duration",
      local: true,
      selectInput: true,
      style: "border",
      value: testProfile.duration,
      possibleValues: [
        120, 180, 240, 300, 360, 420, 480, 540, 600, 660, 720, 780, 840, 900,
        960, 1020, 1080, 1140, 1200,
      ],
    },
    {
      label: "Test Warmup",
      editName: "warmup",
      local: true,
      selectInput: true,
      style: "border",
      value: testProfile.warmup,
      possibleValues: [0, 30, 60, 90, 120],
    },
    {
      label: "Recovery Time",
      editName: "recovery",
      local: true,
      selectInput: true,
      style: "border",
      value: testProfile.recovery,
      possibleValues: [0, 30, 60, 90, 120],
    },
    {
      label: "Profile",
      editName: "profile",
      value: selectedProfile?.name,
      local: true,
      valueColor:selectedProfile?.name=="CUSTOM"?colors.COLORS_ALARM_LOW:null,
      selectInput: true,
      possibleValues: profiles,
    },
  ];
};

export const cycleValues = (direction, value, values, activeIndex) => {
  let currentIndex = activeIndex;

  let newIndex;
  if (direction === "up") {
    newIndex = currentIndex + 1;
    if (newIndex === values.length) {
      newIndex = values.length - 1;
    }
  } else if (direction === "down") {
    newIndex = currentIndex - 1;
    if (newIndex < 0) {
      newIndex = 0;
    }
  } else {
    return activeIndex;
  }

  return newIndex;
};
export const cycleEditValues = (direction, values, currentValue, menuIndex) => {
  let newIndex;
  if (direction == "down") {
    let index = findActiveIndex(currentValue, values[menuIndex].possibleValues);
    newIndex = index - 1;
    if (newIndex < 0) {
      newIndex = values[menuIndex].possibleValues.length - 1;
    }
  } else if (direction == "up") {
    let index = findActiveIndex(currentValue, values[menuIndex].possibleValues);
    newIndex = index + 1;
    if (newIndex === values[menuIndex].possibleValues.length) {
      newIndex = 0;
    }
  }
  return newIndex;
};

const updateEditNavigation = (newValue, activeLetter) => {
  let menu = {};
  if (activeLetter == 0) {
    menu = {
      LEFT: { label: "Undo", route: "NAVIGATE_UNDO", local: true },
      UP: { label: "UP", route: "VALUE_UP", local: true },
      RIGHT: { label: "Next", route: "NAVIGATE_NEXT", local: true },
      MIDDLE: { label: "Value" },
      DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
    };
  } else if (
    activeLetter == newValue.toString().length - 1 &&
    newValue.toString()[activeLetter] === " "
  ) {
    menu = {
      LEFT: { label: "Previous", route: "NAVIGATE_PREVIOUS", local: true },
      UP: { label: "UP", route: "VALUE_UP", local: true },
      RIGHT: { label: "Save", route: "NAVIGATE_SAVE", local: true },
      MIDDLE: { label: "Value" },
      DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
    };
  } else if (activeLetter >= newValue.toString().length) {
    menu = {
      LEFT: { label: "Previous", route: "NAVIGATE_PREVIOUS", local: true },
      UP: { label: "UP", route: "VALUE_UP", local: true },
      RIGHT: { label: "Save", route: "NAVIGATE_SAVE", local: true },
      MIDDLE: { label: "Value" },
      DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
    };
  } else {
    menu = {
      LEFT: { label: "Previous", route: "NAVIGATE_PREVIOUS", local: true },
      UP: { label: "UP", route: "VALUE_UP", local: true },
      RIGHT: { label: "Next", route: "NAVIGATE_NEXT", local: true },
      MIDDLE: { label: "Value" },
      DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
    };
  }

  return menu;
};
const updateNavigation = (index, values, alwaysUp) => {
  let menu = {};
  let activeIndex = index;
  if (activeIndex == values.length - 1) {
    if (values[index].notSelectable) {
      menu = {
        LEFT: { label: "Back", route: "NAVIGATE_BACK", local: true },
        UP: { label: "UP", route: "VALUE_UP", local: true },
        RIGHT: null,
        MIDDLE: null,
        DOWN: null,
      };
    } else {
      menu = {
        LEFT: { label: "Back", route: "NAVIGATE_BACK", local: true },
        UP: { label: "UP", route: "VALUE_UP", local: true },
        RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
        DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
      };
    }
  } else if (activeIndex == 0) {
    menu = {
      LEFT: { label: "Back", route: "NAVIGATE_BACK", local: true },
      UP: alwaysUp ? { label: "UP", route: "VALUE_UP", local: true } : null,
      RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
      DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
      MIDDLE: null,
    };
  } else {
    if (values[index].notSelectable) {
      menu = {
        LEFT: { label: "Back", route: "NAVIGATE_BACK", local: true },
        UP: { label: "UP", route: "VALUE_UP", local: true },
        RIGHT: null,
        DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
        MIDDLE: null,
      };
      return menu;
    }
    menu = {
      LEFT: { label: "Back", route: "NAVIGATE_BACK", local: true },
      UP: { label: "UP", route: "VALUE_UP", local: true },
      RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
      DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
      MIDDLE: null,
    };
  }
  return menu;
};

const SELECTED_MENU = {
  MAIN_MENU: "MAIN_MENU",
  PROFILE_MENU: "PROFILE_MENU",
};

const defaultNewProfile = {
  name: "Set Name",
  minSpO2: 88,
  maxSpO2: 92,
  minO2: 2.0,
  maxO2: 7.0,
  initialO2: 2.0,
  minPulse: 40,
  maxPulse: 130,
  flowResponse: 20,
};

export const Test = (props) => {
  let query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const state = GetWindowState();
  let profileId = query.get("profile");
  console.log({ testProps: props });

  const navigation = (navigator, action, menu, state) => {
    console.log({ action: action });
    console.log({ menu: menu });
    console.log({ statebbb: state });
    // if(state.)
    if (action.type === "CLICK") {
      switch (menu[action.button].route) {
        case "NAVIGATE_CANCEL":
          cancelClicked(state);
          break;
        case "NAVIGATE_CONFIRM":
          confirmClicked(state);
          break;
        case "NAVIGATE_SAVE":
          saveClicked(state);
          break;
        case "NAVIGATE_BACK":
          props.onCancel();
          break;
        case "NAVIGATE_UNDO":
          undoClicked(state);
          break;
        case "NAVIGATE_ACCEPT":
          break;
        case "NAVIGATE_SELECT":
          selectClicked(state);
          break;
        case "NAVIGATE_EDIT":
          editClicked(state);
          break;
        case "VALUE_UP":
          valueUp(state);
          break;
        case "VALUE_DOWN":
          valueDown(state);
          break;
      }
      // switch (menu[action.button].route) {
      //   case "VALUE_DOWN":
      //     let newValue = cycleValues(
      //       "up",
      //       null,
      //       state.testSetup.menuItems,
      //       state.testSetup.activeItemIndex
      //     );
      //     setWindowState({
      //       ...state,
      //       testSetup:{...state.testSetup,activeItemIndex:newValue,
      //         menu: updateNavigation(newValue, state.testSetup.menuItems),}

      //     });
      //     break;
      //   case "VALUE_UP":
      //     let newValue1 = cycleValues(
      //       "down",
      //       null,
      //       state.menuItems,
      //       state.activeItemIndex
      //     );
      //     setWindowState({
      //       ...state,
      //       activeItemIndex: newValue1,
      //       menu: updateNavigation(newValue1, state.testSetup.menuItems),
      //     });
      //     break;
      // }
    }
  };
  const defaultMenu = {
    LEFT: { label: "Back", route: "profiles", local: true },
    UP: null,
    RIGHT: { label: "Select", route: "select", local: true },
    MIDDLE: null,
    DOWN: { label: "DOWN", route: "down", local: true },
  };

  const getProfileData = (isNew, id, profiles) => {
    if (isNewProfile) {
      return defaultNewProfile;
    } else {
      return profiles.find((profile) => profile.id == id);
    }
  };
  const generateTopMenuItems = (profile) => {
    return [
      { label: "Name", value: profile.name, editable: true },
      { label: "Save" },
      {
        label: "Delete",
        window: "confirmDelete",
        labelColor: colors.COLORS_ALARM_LOW,
      },
    ];
  };
  let isNewProfile = profileId == "new" ? true : false;
  let profile = getProfileData(
    isNewProfile,
    profileId,
    props?.pro?.defaultProfiles
  );

  const shouldInit = props.menu == undefined ? true : false;
  const menuItems = props.menuItems;
  const defaultState = {
    activeMenu: ACTIVE_MENU.ACTIVE_MENU_SETTINGS,
    selectedProfile: props.pro.defaultProfiles[0],
    savedProfile: props.pro.defaultProfiles[0],
    allProfiles: [...props.pro.defaultProfiles],
    windowNavigation: navigation,
    customizeProfile: {
      name: props.name,
      disabled: true,
      editActiveIndex: 0,
      editProfileValueMode: EDIT_PROFILE_MODE.MODE_DEFAULT,
      value: props.pro.defaultProfiles[0],
      modifyNavigation: true,
      newValue: props.pro.defaultProfiles[0],
      values: props.pro.defaultProfiles,
    },
    testSetup: {
      activeItemIndex: 0,
      activeMenu: ACTIVE_MENU.ACTIVE_MENU_SETTINGS,
      editProfileValueMode: EDIT_PROFILE_MODE.MODE_DEFAULT,
      menuItems: generateMenuItems(
        props.testProfile,
        props.pro.defaultProfiles,
        props.pro.defaultProfiles[0]
      ),
      testProfile: JSON.parse(JSON.stringify(props.testProfile)),
      savedTestProfile: JSON.parse(JSON.stringify(props.testProfile)),
    },
  };

  const menu = props.menu
    ? props.menu
    : {
        LEFT: { label: "Back", route: "NAVIGATE_BACK", local: true },
        UP: defaultState.modifyNavigation
          ? { label: "UP", route: "VALUE_UP", local: true }
          : null,
        RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
        MIDDLE: null,
        DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
      };
  defaultState.menu = menu;
  const windowState = props?.state
    ? {
        ...props.state,
        menu: menu,
      }
    : defaultState;

  console.log({ windowStateddddd: windowState });
  const settestSetupState = (menu, state) => {
    let newState = { ...windowState };
    newState.testSetup = { ...state };
    props.setState(windowState.menu, windowState);
  };
  const setWindowState = (windowState) => {
    console.log({ setWindowState: windowState });
    if (windowState.activeMenu == ACTIVE_MENU.ACTIVE_MENU_SETTINGS) {
      props.setState(windowState.testSetup.menu, windowState);
      return;
    }
    props.setState(windowState.menu, windowState);
  };
  const valueUp = (state) => {
    if (state.activeMenu == ACTIVE_MENU.ACTIVE_MENU_SETTINGS) {
      if (
        state.testSetup.editProfileValueMode ==
        EDIT_PROFILE_MODE.MODE_EDIT_VALUE
      ) {
        let savedState = JSON.parse(JSON.stringify(state.testSetup.menuItems));
        let newValue = cycleEditValues(
          "up",
          state.testSetup.menuItems,
          state.testSetup.menuItems[state.testSetup.activeItemIndex].value,
          state.testSetup.activeItemIndex
        );
        let menuItems = state.testSetup.menuItems;

        let getNewValue =
          menuItems[state.testSetup.activeItemIndex].possibleValues[newValue];

        let newState = { ...state };

        newState.testSetup.testProfile[
          menuItems[state.testSetup.activeItemIndex].editName
        ] = getNewValue;

        menuItems = generateMenuItems(
          newState.testSetup.testProfile,
          newState.allProfiles,
          state.selectedProfile
        );
        newState.testSetup.menuItems = menuItems;
        if (
          state.testSetup.menuItems[state.testSetup.activeItemIndex].label ==
          "Profile"
        ) {
          let profileName = getNewValue;
          let newProfile = state.allProfiles.find(
            (profile) => profile.name == profileName
          );
          newState.selectedProfile = newProfile;
          newState.customizeProfile.value = newProfile;
          newState.customizeProfile.newValue = newProfile;
          newState.testSetup.menuItems[state.testSetup.activeItemIndex].value =
            getNewValue;
        }
        setWindowState(newState);
        return;
      }
      let newValue = cycleValues(
        "down",
        null,
        state.testSetup.menuItems,
        state.testSetup.activeItemIndex
      );
      setWindowState({
        ...state,
        testSetup: {
          ...state.testSetup,
          activeItemIndex: newValue,
          menu: updateNavigation(newValue, state.testSetup.menuItems),
        },
      });
    } else if (state.activeMenu == ACTIVE_MENU.ACTIVE_MENU_PROFILE) {
      if (
        state.customizeProfile.editProfileValueMode ==
        EDIT_PROFILE_MODE.MODE_DEFAULT
      ) {
        let newActiveIndex;
        let newSelectedMenu = state.activeMenu;
        let prevIndex = state.customizeProfile.editActiveIndex;
        let profileMenuItems = generateProfileValues(state.selectedProfile);
        newActiveIndex = prevIndex - 1;
        if (newActiveIndex == -1) {
          newSelectedMenu = ACTIVE_MENU.ACTIVE_MENU_SETTINGS;
          newActiveIndex = state.testSetup.menuItems.length - 1;
        }

        // windowState?.customizeProfile?.state
        setWindowState({
          ...state,
          activeMenu: newSelectedMenu,
          testSetup: {
            ...state.testSetup,
            activeItemIndex: newActiveIndex,
            menu: updateNavigation(newActiveIndex, state.testSetup.menuItems),
          },

          customizeProfile: {
            ...state.customizeProfile,
            editActiveIndex:
              newSelectedMenu == ACTIVE_MENU.ACTIVE_MENU_SETTINGS
                ? -1
                : newActiveIndex,
          },
        });
      }
      else if (
        state.customizeProfile.editProfileValueMode ==
        EDIT_PROFILE_MODE.MODE_EDIT_VALUE
      ) {
        let profileMenu = generateProfileValues(state.selectedProfile);
        let newValue = changeProfileValue(
          state.selectedProfile,
          profileMenu[state.customizeProfile.editActiveIndex].editName,
          "up"
        );
        setWindowState({
          ...state,
          selectedProfile: newValue,
          menu: updateNavigationForProfileValue(state)
        });
      }
    }
  };
  const valueDown = (state) => {
    if (state.activeMenu == ACTIVE_MENU.ACTIVE_MENU_SETTINGS) {
      if (
        state.testSetup.editProfileValueMode ==
        EDIT_PROFILE_MODE.MODE_EDIT_VALUE
      ) {
        let savedState = JSON.parse(JSON.stringify(state.testSetup.menuItems));
        let newValue = cycleEditValues(
          "down",
          state.testSetup.menuItems,
          state.testSetup.menuItems[state.testSetup.activeItemIndex].value,
          state.testSetup.activeItemIndex
        );
        let menuItems = state.testSetup.menuItems;

        let getNewValue =
          menuItems[state.testSetup.activeItemIndex].possibleValues[newValue];

        let newState = { ...state };

        newState.testSetup.testProfile[
          menuItems[state.testSetup.activeItemIndex].editName
        ] = getNewValue;

        menuItems = generateMenuItems(
          newState.testSetup.testProfile,
          newState.allProfiles,
          state.selectedProfile
        );
        newState.testSetup.menuItems = menuItems;
        if (
          state.testSetup.menuItems[state.testSetup.activeItemIndex].label ==
          "Profile"
        ) {
          let profileName = getNewValue;
          let newProfile = state.allProfiles.find(
            (profile) => profile.name == profileName
          );
          newState.selectedProfile = newProfile;
          newState.customizeProfile.value = newProfile;
          newState.customizeProfile.newValue = newProfile;
          newState.testSetup.menuItems[state.testSetup.activeItemIndex].value =
            getNewValue;
        }
        setWindowState(newState);
        return;
      }
      let newSelectedMenu = state.activeMenu;
      let newActiveIndex;
      let editActiveIndex = -1;
      let prevIndex = state.testSetup.activeItemIndex;
      let profileMenuItems = generateProfileValues(state.selectedProfile);
      if (state.activeMenu == ACTIVE_MENU.ACTIVE_MENU_SETTINGS) {
        newActiveIndex = prevIndex + 1;
        if (newActiveIndex == state.testSetup.menuItems.length) {
          newSelectedMenu = ACTIVE_MENU.ACTIVE_MENU_PROFILE;
          newActiveIndex = 0;
          editActiveIndex = 0;
        }
      }
      // let newValue = cycleValues(
      //   "up",
      //   null,
      //   state.testSetup.menuItems,
      //   state.testSetup.activeItemIndex
      // );
      setWindowState({
        ...state,
        activeMenu: newSelectedMenu,
        menu:
          newSelectedMenu == ACTIVE_MENU.ACTIVE_MENU_PROFILE
            ? updateNavigation(newActiveIndex, profileMenuItems, true)
            : null,
        customizeProfile: {
          ...state.customizeProfile,
          editActiveIndex: editActiveIndex,
          updatedProfile: state.selectedProfile,
        },
        testSetup: {
          ...state.testSetup,
          activeItemIndex: newActiveIndex,
          menu: updateNavigation(newActiveIndex, state.testSetup.menuItems),
        },
      });
    } else if (state.activeMenu == ACTIVE_MENU.ACTIVE_MENU_PROFILE) {
      if (
        state.customizeProfile.editProfileValueMode ==
        EDIT_PROFILE_MODE.MODE_DEFAULT
      ) {
        let newActiveIndex;
        let prevIndex = state.customizeProfile.editActiveIndex;
        let profileMenuItems = generateProfileValues(state.selectedProfile);
        newActiveIndex =
          prevIndex < profileMenuItems.length - 1 ? prevIndex + 1 : prevIndex;

        // windowState?.customizeProfile?.state
        setWindowState({
          ...state,
          menu: updateNavigation(newActiveIndex, profileMenuItems, true),
          customizeProfile: {
            ...state.customizeProfile,
            editActiveIndex: newActiveIndex,
          },
          testSetup: {
            ...state.testSetup,
          },
        });
      }
      else if (
        state.customizeProfile.editProfileValueMode ==
        EDIT_PROFILE_MODE.MODE_EDIT_VALUE
      ) {
        let profileMenu = generateProfileValues(state.selectedProfile);
        let newValue = changeProfileValue(
          state.selectedProfile,
          profileMenu[state.customizeProfile.editActiveIndex].editName,
          "down"
        );
        setWindowState({
          ...state,
          selectedProfile: newValue,
          menu: updateNavigationForProfileValue(state)
        });
      }
    }
  };

  const editClicked = (state) => {
    let updatedProfile = { ...state.profiles[state.activeIndex] };
    setWindowState({
      ...state,
      editActiveIndex: 0,
      updatedProfile: updatedProfile,
    });
  };

  const cancelClicked = (state) => {
    if (
      state.testSetup.editProfileValueMode == EDIT_PROFILE_MODE.MODE_EDIT_VALUE
    ) {
      let menuItems = generateMenuItems(
        state.testSetup.savedTestProfile,
        state.allProfiles,
          state.selectedProfile
      );
      setWindowState({
        ...state,
        menu: updateNavigation(
          state.testSetup.activeItemIndex,
          menuItems,
          true
        ),
        testSetup: {
          ...state.testSetup,
          testProfile: JSON.parse(
            JSON.stringify(state.testSetup.savedTestProfile)
          ),
          menuItems: menuItems,
          savedState: null,
          editProfileValueMode: EDIT_PROFILE_MODE.MODE_DEFAULT,
          menu: updateNavigation(
            state.testSetup.activeItemIndex,
            menuItems,
            true
          ),
        },
      });
    }
  };
  const confirmClicked = (state) => {
    if (
      state.testSetup.editProfileValueMode == EDIT_PROFILE_MODE.MODE_EDIT_VALUE
    ) {
      let menuItems = generateMenuItems(
        state.testSetup.testProfile,
        state.allProfiles,
        state.selectedProfile
      );
      setWindowState({
        ...state,
        testSetup: {
          ...state.testSetup,
          savedTestProfile: JSON.parse(
            JSON.stringify(state.testSetup.testProfile)
          ),
          editProfileValueMode: EDIT_PROFILE_MODE.MODE_DEFAULT,
          menu: updateNavigation(
            state.testSetup.activeItemIndex,
            menuItems,
            true
          ),
        },
      });
    }
  };
  const undoClicked = (state) => {
    if (
      state.testSetup.editProfileValueMode == EDIT_PROFILE_MODE.MODE_EDIT_VALUE
    ) {
      //props.onCancel()
      setWindowState({
        ...state,
        testSetup: {
          ...state.testSetup,
          savedState: null,
          editProfileValueMode: EDIT_PROFILE_MODE.MODE_DEFAULT,
          menu: updateNavigationForValue(state),
        },
      });
    }
    else if (
      state.customizeProfile.editProfileValueMode == EDIT_PROFILE_MODE.MODE_EDIT_VALUE
    ) {
      //props.onCancel()
      let profileMenuItems=generateProfileValues(state.savedProfile)
      setWindowState({
        ...state,
        menu: updateNavigation(state.customizeProfile.editActiveIndex,profileMenuItems,state.customizeProfile.editActiveIndex==0),
        selectedProfile:JSON.parse(JSON.stringify(state.savedProfile)),
        customizeProfile: {
          ...state.customizeProfile,
          editProfileValueMode: EDIT_PROFILE_MODE.MODE_DEFAULT,
          menu: updateNavigationForValue(state),
        },
      });
    }
  };

  const updateNavigationForValue = (state) => {
    let menu = {
      LEFT: { label: "Cancel", route: "NAVIGATE_CANCEL", local: true },
      RIGHT: { label: "Confirm", route: "NAVIGATE_CONFIRM", local: true },
      MIDDLE: { label: "Value" },
      UP: { label: "UP", route: "VALUE_UP", local: true },
      DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
    };
    // const showUpButton = state.activeItemIndex > 0;
    // const showDownButton = state.activeItemIndex < state.menuItems.length - 1;
    // menu.UP = showUpButton
    //   ? { label: "UP", route: "VALUE_UP", local: true }
    //   : null;
    // menu.DOWN = showDownButton
    //   ? { label: "DOWN", route: "VALUE_DOWN", local: true }
    //   : null;
    return menu;
  };
  const updateNavigationForProfileValue = (state) => {
    let menu = {
      LEFT: { label: "Undo", route: "NAVIGATE_UNDO", local: true },
      RIGHT: { label: "Save", route: "NAVIGATE_SAVE", local: true },
      MIDDLE: { label: "Value" },
      UP: { label: "UP", route: "VALUE_UP", local: true },
      DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
    };
    // const showUpButton = state.activeItemIndex > 0;
    // const showDownButton = state.activeItemIndex < state.menuItems.length - 1;
    // menu.UP = showUpButton
    //   ? { label: "UP", route: "VALUE_UP", local: true }
    //   : null;
    // menu.DOWN = showDownButton
    //   ? { label: "DOWN", route: "VALUE_DOWN", local: true }
    //   : null;
    return menu;
  };

  const selectClicked = (state) => {
    if (state.activeMenu == ACTIVE_MENU.ACTIVE_MENU_SETTINGS) {
      if (
        state.testSetup.editProfileValueMode == EDIT_PROFILE_MODE.MODE_DEFAULT
      ) {
        if(state.testSetup.menuItems[state.testSetup.activeItemIndex].label=="Start test"){
          props.onComplete({treatmentProfile:state.selectedProfile,testProfile:state.testSetup.testProfile})
          return;
        }
        setWindowState({
          ...state,
          testSetup: {
            ...state.testSetup,
            editProfileValueMode: EDIT_PROFILE_MODE.MODE_EDIT_VALUE,
            menu: updateNavigationForValue(state),
          },
        });
      }
    } else if (state.activeMenu == ACTIVE_MENU.ACTIVE_MENU_PROFILE) {
      if (
        state.customizeProfile.editProfileValueMode ==
        EDIT_PROFILE_MODE.MODE_DEFAULT
      ) {
        setWindowState({
          ...state,
          menu: updateNavigationForProfileValue(state),
          testSetup: {
            ...state.testSetup
          },
          customizeProfile: {
            ...state.customizeProfile,
            editProfileValueMode: EDIT_PROFILE_MODE.MODE_EDIT_VALUE
          },
        });
      }

    }
  };
  const saveClicked = (state) => {
    if (
      state.customizeProfile.editProfileValueMode == EDIT_PROFILE_MODE.MODE_EDIT_VALUE
    ) {
      //props.onCancel()
      let selectedProfile=state.selectedProfile
      selectedProfile.name="CUSTOM"
      let menuItems = generateMenuItems(
        state.testSetup.testProfile,
        state.allProfiles,
        selectedProfile
      );
      setWindowState({
        ...state,
        menu: updateNavigation(state.customizeProfile.editActiveIndex,generateProfileValues(selectedProfile),state.customizeProfile.editActiveIndex==0),
        savedProfile:selectedProfile,
        selectedProfile:selectedProfile,
        testSetup:{
          ...state.testSetup,
          menuItems:menuItems
        },
        customizeProfile: {
          ...state.customizeProfile,
          editProfileValueMode: EDIT_PROFILE_MODE.MODE_DEFAULT,
          menu: updateNavigationForValue(state),
        },
      });
    }
  };

  useEffect(() => {
    console.log("should init:" + shouldInit);
    if (true) {
      windowState.windowNavigation = navigation;
      props.setState(menu, windowState);
    }
  }, [windowState.name]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "500px",
        border: "1px solid white",
        background: "black",
        alignItems: "center",
      }}
    >
      <ItemMenu
        width="96%"
        name={"screen"}
        label={"screen"}
        value={[]}
        
        selected={
          windowState.testSetup.editProfileValueMode ==
          EDIT_PROFILE_MODE.MODE_EDIT_VALUE
        }
        activeItemIndex={windowState.testSetup.activeItemIndex}
        menuItems={windowState.testSetup.menuItems}
        disabled={windowState.activeMenu != ACTIVE_MENU.ACTIVE_MENU_SETTINGS}
      />
      <ItemMenu
        width={"85%"}
        modifyNavigation={true}
        activeItemIndex={windowState.customizeProfile.editActiveIndex}
        selected={
          windowState.customizeProfile.editProfileValueMode ==
          EDIT_PROFILE_MODE.MODE_EDIT_VALUE
        }
        showErrors={
          windowState.customizeProfile.editProfileValueMode ===
          EDIT_PROFILE_MODE.MODE_EDIT_VALUE
        }
        menuItems={generateProfileValues(windowState.selectedProfile)}
        disabled={windowState.activeMenu != ACTIVE_MENU.ACTIVE_MENU_PROFILE}
        name={"customizeProfile"}
        value={generateProfileValues({ ...windowState.selectedProfile })}
        format={(data) => data}
        state={windowState?.customizeProfile}
      />
    </Box>
  );
};

export const generateProfileValues = (profile) => {
  let profileValues = [
    {
      label: "Min SpO2",
      value: profile.minSpO2,
      editName: "minSpO2",
      labelError: profile.error?.minSpO2?.labelError ? true : false,
      valueError: profile.error?.minSpO2?.valueError ? true : false,
      risky: profile.error?.minSpO2?.risky ? true : false,
      unit: "%",
    },
    {
      label: "Max SpO2",
      value: profile.maxSpO2,
      editName: "maxSpO2",
      labelError: profile.error?.maxSpO2?.labelError ? true : false,
      valueError: profile.error?.maxSpO2?.valueError ? true : false,
      unit: "%",
    },
    {
      label: "Min O2 Flow",
      value: profile.minO2,
      editName: "minO2",
      labelError: profile.error?.minO2?.labelError ? true : false,
      valueError: profile.error?.minO2?.valueError ? true : false,
      unit: "l/min",
    },
    {
      label: "Max O2 Flow",
      value: profile.maxO2,
      editName: "maxO2",
      labelError: profile.error?.maxO2?.labelError ? true : false,
      valueError: profile.error?.maxO2?.valueError ? true : false,
      unit: "l/min",
    },
    {
      label: "Initial O2",
      value: profile.initialO2,
      editName: "initialO2",
      labelError: profile.error?.initialO2?.labelError ? true : false,
      valueError: profile.error?.initialO2?.valueError ? true : false,
      unit: "l/min",
    },
    {
      label: "Min Pulse Rate",
      value: profile.minPulse,
      editName: "minPulse",
      labelError: profile.error?.minPulse?.labelError ? true : false,
      valueError: profile.error?.minPulse?.valueError ? true : false,
      unit: "bpm",
    },
    {
      label: "Max Pulse Rate",
      value: profile.maxPulse,
      editName: "maxPulse",
      labelError: profile.error?.maxPulse?.labelError ? true : false,
      valueError: profile.error?.maxPulse?.valueError ? true : false,
      risky: profile.error?.maxPulse?.risky ? true : false,
      unit: "bpm",
    },
  ];
  return profileValues;
};
export const cycleCharacter = (direction, value, characters, activeIndex) => {
  // get the current character at the active index
  value = value.toString();
  let currentChar = value[activeIndex];

  // find the index of the current character in the characters array
  let currentIndex = characters.indexOf(currentChar);

  // calculate the new index based on the direction
  let newIndex;
  if (direction === "up") {
    newIndex = currentIndex + 1;
    if (newIndex === characters.length) {
      newIndex = 0;
    }
  } else if (direction === "down") {
    newIndex = currentIndex - 1;
    if (newIndex < 0) {
      newIndex = characters.length - 1;
    }
  } else {
    return value; // invalid direction, return original value
  }

  // update the value at the active index with the new character
  let newValue =
    value.slice(0, activeIndex) +
    characters[newIndex] +
    value.slice(activeIndex + 1);

  // if the activeIndex is not the last index and the new character is a space, skip it
  if (activeIndex < value.length - 1 && characters[newIndex] === " ") {
    return cycleCharacter(direction, newValue, characters, activeIndex);
  }

  return newValue;
};
const isRiskyProfile = (profile) => {
  if (profile.minSpO2 < 85 || profile.maxPulse > 150) {
    return true;
  }
  return false;
};
