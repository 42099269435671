import { Box } from "@mui/material";
import uPlot from "uplot";
import UplotReact from "uplot-react";
import "uplot/dist/uPlot.min.css";
import { colors } from "../../Style";
import { TREND_MODE } from "./Trends";
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

export const getLabels = (mode, value) => {
  if (mode == TREND_MODE.TREND_MODE_12_MIN) {
    switch (value) {
      case 0:
        return "T-00:12";
      case 1:
        return "T-00:09";
      case 2:
        return "T-00:06";
      case 3:
        return "T-00:03";
      case 4:
        return "T-00:00";
    }
  }
  if (mode == TREND_MODE.TREND_MODE_1_HOUR) {
    switch (value) {
      case 0:
        return "T-01:00";
      case 1:
        return "T-00:45";
      case 2:
        return "T-00:30";
      case 3:
        return "T-00:15";
      case 4:
        return "T-00:00";
    }
  }

  if (mode == TREND_MODE.TREND_MODE_8_HOUR) {
    switch (value) {
      case 0:
        return "T-08:00";
      case 1:
        return "T-06:00";
      case 2:
        return "T-04:00";
      case 3:
        return "T-02:00";
      case 4:
        return "T-00:00";
    }
  }
  if (mode == TREND_MODE.TREND_MODE_24_HOUR) {
    switch (value) {
      case 0:
        return "T-24:00";
      case 1:
        return "T-18:00";
      case 2:
        return "T-12:00";
      case 3:
        return "T-06:00";
      case 4:
        return "T-00:00";
    }
  }
};
function showCirclePlugin(props) {
  let circles = [];
  let cursortt;
  let size=props.size
  //let data=props.data
//   function init(u, opts, data) {
//     let over = u.over;

//     let tt = cursortt = document.createElement("div");
//     tt.className = "tooltip";
//     tt.textContent = "(x,y)";
//     tt.style.pointerEvents = "none";
//     tt.style.position = "absolute";
//     tt.style.right=0;
//     tt.style.background = "rgba(0,0,255,0.1)";
//     over.appendChild(tt);
// }
  function calculateXPosition(index, maxDataPoints, chartWidth) {
    const proportion = index / maxDataPoints;
    const xPosition = proportion * chartWidth;
    return xPosition;
  }


  function init(u,opts,data) {
    let over = u.over;

    if(data.length!=7){
      return;
    }
    else{
      try{
        for (let i = 0; i < data[0].length; i++) {
          //profileChange
             if(data[4][i]==true){

                let xValue=calculateXPosition(i,size[1],720)
                if(xValue<720){
                let tt = cursortt = document.createElement("div");
                tt.className = "tooltip";
                tt.style.width="0px"
                tt.style.height="0px"
                tt.style.borderLeft="5px solid transparent"
                tt.style.borderRight="5px solid transparent"
                tt.style.borderTop="5px solid white"
                tt.style.pointerEvents = "none";
                tt.style.position = "absolute";
                tt.style.right=xValue+"px";
                over.appendChild(tt);
              }
            }
            //patientChange
            if(data[5][i]==true){

              let xValue=calculateXPosition(i,size[1],720)
              if(xValue<720){
              let tt = cursortt = document.createElement("div");
              tt.className = "turnOnOff";
              tt.style.width="1px"
              tt.style.borderLeft="1px dashed white"
              tt.style.height="234px"
              tt.style.position = "absolute";
              tt.style.right=xValue+"px";
              over.appendChild(tt);
              }
          }
          //turnOnOff
            if(data[6][i]==true){
              let xValue=calculateXPosition(i,size[1],720)
              if(xValue<720){
                let tt = cursortt = document.createElement("div");
                tt.className = "turnOnOff";
                tt.style.width="2px"
                tt.style.background="white"
                tt.style.height="234px"
                tt.style.position = "absolute";
                tt.style.right=xValue+"px";
                over.appendChild(tt);
              }

          }

          } 
    }
    catch(e){
        console.log(e)
    }
    }

    // const seriesData = data[seriesIndex];

    console.log("finished")
  }

  return {
    hooks: {
        init,
        calculateXPosition
    },
};
}

export const UPlotChart = (props) => {
  const labels = ["T-01:00", "T-00:45", "T-00:30", "T-00:15", "T-00:00"];
  return (
    <Box sx={{ width: "100%", height: "100%", background: "black" }}>
      <UplotReact
        data={props.data}
        options={{
          width: 900,
          height: 300,
          legend: {
            show: false,
          },
          cursor: { show: false },
          plugins: [
            showCirclePlugin(
                {
                    size:props.xRange,
                    data: props.data, // Specify the index of the series to apply the circle plugin to
                  }
            ),
          ],
          gridWidth: 0, // set the grid width to 0
          gridColor: colors.COLORS_ALARM_LOW, // set the grid color to null
          series: [
            {},
            {
              stroke: colors.SPO2,
              label: "SpO2",
              width: 1,
              value: "SpO2",
              scale: "%",
              value: (self, raw) => raw,
            },
            {
              stroke: colors.PULSE,
              label: "Pulse",
              width: 1,
              value: "Pulse",
              scale: "bpm",
              value: (self, raw) => raw,
            },
            {
              stroke: colors.O2,
              label: "O2",
              width: 1,
              scale: "l/min",
              value: (self, raw) => raw,
            },
            {
                stroke: colors.COLORS_ALARM_HIGH,
                label: "profileChange",
                width: 1,
                scale: "profileChange",
                value: (self, raw) => raw,
              },
          ],
          axes: [
            {
              grid: {
                show: true,
                stroke: "gray",
                width: 1,
                split: 2,
                dash: [],
              },

              // space: 30,
              // splits:[0,1,2,3,4],
              labelSize: 75,
              labelFont: "bold 12px Arial red",
              font: "bold 18px Arial red",
              gap: 20,
              stroke: "gray",
              // values: (self, ticks) => ticks.map(rawValue => {
              //     console.log({ticks:ticks})
              //     console.log({rawValue:rawValue})
              //     return getLabels(mode,rawValue)
              // }),
              space: 144,
              values: (_, ticks) =>
                ticks.map((v) => {
                  let index = ticks.findIndex((x) => x === v);

                  return getLabels(props.mode, index);
                }), // Map the tick values to labels
            },

            {
              grid: {
                show: true,
                stroke: "gray",
                width: 1,
                split: 2,
                dash: [],
              },
              show: true,
              labelSize: 75,
              labelFont: "bold 12px Arial",
              font: "bold 18px Arial",
              gap: 5,
              size: 50,
              scale: "bpm",
              stroke: colors.PULSE,
              side: 3,
              ticks: {
                show: true,
                stroke: colors.PULSE,
                width: 51,
                dash: [],
                size: 3,
              },
              space: 32,
              splits: [40, 72, 104, 136, 168, 200, 250],
            },
            {
              show: true,
              labelSize: 75,
              labelFont: "bold 12px Arial",
              font: "bold 18px Arial",
              size: 75,
              color: "red",
              scale: "l/min",
              values: (self, ticks) => ticks.map((rawValue) => rawValue),
              side: 1,
              stroke: colors.O2,
              ticks: {
                show: true,
                stroke: colors.O2,
                width: 51,
                dash: [],
                size: 3,
              },
              space: 4,
              splits: [0, 3, 6, 9, 12, 15, "O2"],
            },
            {
              grid: { show: true },
              show: true,
              labelSize: 75,
              labelFont: "bold 12px Arial red",
              font: "bold 18px Arial",
              gap: 4,
              size: 50,
              scale: "%",
              values: (self, ticks) =>
                ticks.map((rawValue) => rawValue.toFixed(0)),
              side: 3,
              stroke: colors.SPO2,
              ticks: {
                show: true,
                stroke: colors.SPO2,
                width: 51,
                dash: [],
                size: 3,
              },
              space: 4,
              splits: [80, 84, 88, 92, 96, 100, 120],
            },
          ],
          scales: {
            "%": {
              space: 4,
              auto: false,
              range: [80, 102.5],
            },
            bpm: {
              space: 32,
              auto: false,
              range: [40, 220],
            },
            "l/min": {
              auto: false,
              space: 3,
              range: [0, 17],
            },
            "profileChange": {
              auto: false,
              space: 1,
            },
            // x: props.xScale,
            x: {
              auto: false,
              time: false,
              range: props.xRange,
            },
          },
        }}
      />
    </Box>
  );
};
