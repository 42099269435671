// Define enums
export const ALARM_SEVERITY = {
    NONE: 0,
    INFO: 1,
    WARNING: 2,
    ALARM: 3,
    CRITICAL_INFO: 4,
    CRITICAL_ALARM: 5,
};

export const ALARM_CODE = {
    NONE: 0,
    HIGH_SPO2: 111,
    LOW_SPO2: 112,
    VERY_LOW_SPO2: 113,
    CRITICALLY_LOW_SPO2: 114,
    HIGH_PULSE: 120,
    CRITICALLY_HIGH_PULSE: 121,
    LOW_PULSE: 123,
    CRITICALLY_LOW_PULSE: 124,
    O2_FLOW_ERROR: 230,
    BATTERY_WARNING: 240,
    BATTERY_CRITICAL: 241,
    NO_POWER: 242,
    BATTERY_FAIL: 340,
    OXIMETER_NO_SIGNAL: 8887,
    OXIMETER_NO_SIGNAL_DELAY_0: 8888,
    OXIMETER_NO_SIGNAL_DELAY_1: 8889,
    OXIMETER_NO_SIGNAL_DELAY_5: 8890,
    OXIMETER_NO_SIGNAL_DELAY_15: 8891,
    OXIMETER_NO_SIGNAL_DELAY_30: 8892,
    OXIMETER_NOT_CONNECTED: 8893
};

export const ALARM_INTENSITY = {
    SILENT: 0,
    LOW: 1,
    MEDIUM: 2,
    HIGH: 3,
    MUTE: 4,
};

export class Alarm {
    constructor(severity, alarmCode, higherAlarmCode, intensity, delay, timeout, name) {
        this.severity = severity;
        this.alarmCode = alarmCode;
        this.higherAlarmCode = higherAlarmCode;
        this.intensity = intensity;
        this.delay = delay;
        this.timeout = timeout;
        this.name = name;
        this.timestamp = Date.now();
        this.delayReached = false;
    }

    isHigherSeverity(otherSeverity) {
        return this.severity > otherSeverity;
    }

    checkDelay() {
        const currentTime = Date.now();
        if (currentTime - this.timestamp >= this.delay) {
            this.delayReached = true;
            return true;
        }
        return false;
    }
}

export function generateAlarm(alarmCode) {
    let alarmSeverity, higherAlarmCode, intensity, delay, timeout, name;

    // set values for each alarm code
    switch (alarmCode) {
        case ALARM_CODE.O2_FLOW_ERROR:
            alarmSeverity = ALARM_SEVERITY.CRITICAL_ALARM;
            higherAlarmCode = ALARM_CODE.NONE;
            intensity = ALARM_INTENSITY.HIGH;
            delay = 5;
            timeout = 0;
            name = "Flow error";
            break;
        case ALARM_CODE.OXIMETER_NOT_CONNECTED:
            alarmSeverity = ALARM_SEVERITY.CRITICAL_ALARM;
            higherAlarmCode = ALARM_CODE.NONE;
            intensity = ALARM_INTENSITY.HIGH;
            delay = 5;
            timeout = 0;
            name = "Not connected";
            break;
        case ALARM_CODE.OXIMETER_NO_SIGNAL:
            alarmSeverity = ALARM_SEVERITY.CRITICAL_ALARM;
            higherAlarmCode = ALARM_CODE.NONE;
            intensity = ALARM_INTENSITY.HIGH;
            delay = 0;
            timeout = 0;
            name = "No signal";
            break;
        case ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_0:
            alarmSeverity = ALARM_SEVERITY.ALARM;
            higherAlarmCode = ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_1;
            intensity = ALARM_INTENSITY.HIGH;
            delay = 0;
            timeout = 0;
            name = "No signal";
            break;
        case ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_1:
            alarmSeverity = ALARM_SEVERITY.ALARM;
            higherAlarmCode = ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_5;
            intensity = ALARM_INTENSITY.HIGH;
            delay = 0;
            timeout = 0;
            name = "No signal";
            break;
        case ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_5:
            alarmSeverity = ALARM_SEVERITY.ALARM;
            higherAlarmCode = ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_15;
            intensity = ALARM_INTENSITY.HIGH;
            delay = 0;
            timeout = 0;
            name = "No signal";
            break;
        case ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_15:
            alarmSeverity = ALARM_SEVERITY.ALARM;
            higherAlarmCode = ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_30;
            intensity = ALARM_INTENSITY.HIGH;
            delay = 0;
            timeout = 0;
            name = "No signal";
            break;
        case ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_30:
            alarmSeverity = ALARM_SEVERITY.ALARM;
            higherAlarmCode = ALARM_CODE.OXIMETER_NO_SIGNAL;
            intensity = ALARM_INTENSITY.HIGH;
            delay = 0;
            timeout = 0;
            name = "No signal";
            break;
        case ALARM_CODE.NO_POWER:
            alarmSeverity = ALARM_SEVERITY.CRITICAL_ALARM;
            higherAlarmCode = ALARM_CODE.NONE;
            intensity = ALARM_INTENSITY.SILENT;
            delay = 0;
            timeout = 0;
            name = "No power";
            break;

        case ALARM_CODE.BATTERY_CRITICAL:
            alarmSeverity = ALARM_SEVERITY.CRITICAL_ALARM;
            higherAlarmCode = ALARM_CODE.BATTERY_FAIL;
            intensity = ALARM_INTENSITY.SILENT;
            delay = 0;
            timeout = 0;
            name = "Battery Critical";
            break;

        case ALARM_CODE.BATTERY_FAIL:
            alarmSeverity = ALARM_SEVERITY.CRITICAL_ALARM;
            higherAlarmCode = ALARM_CODE.NONE;
            intensity = ALARM_INTENSITY.SILENT;
            delay = 0;
            timeout = 0;
            name = "Battery Fail";
            break;

        case ALARM_CODE.BATTERY_WARNING:
            alarmSeverity = ALARM_SEVERITY.ALARM;
            higherAlarmCode = ALARM_CODE.ALARM_CODE_BATTERY_CRITICAL;
            intensity = ALARM_INTENSITY.SILENT;
            delay = 0;
            timeout = 0;
            name = "Battery Low";
            break;

        case ALARM_CODE.HIGH_PULSE:
            alarmSeverity = ALARM_SEVERITY.ALARM;
            higherAlarmCode = ALARM_CODE.CRITICALLY_HIGH_PULSE;
            intensity = ALARM_INTENSITY.MEDIUM;
            delay = 60 * 2;
            timeout = 0;
            name = "High pulse";
            break;

        case ALARM_CODE.CRITICALLY_HIGH_PULSE:
            alarmSeverity = ALARM_SEVERITY.CRITICAL_ALARM;
            higherAlarmCode = ALARM_CODE.NONE;
            intensity = ALARM_INTENSITY.HIGH;
            delay = 5;
            timeout = 0;
            name = "Critically high pulse";
            break;

        case ALARM_CODE.HIGH_SPO2:
            alarmSeverity = ALARM_SEVERITY.WARNING;
            higherAlarmCode = ALARM_CODE.NONE;
            intensity = ALARM_INTENSITY.SILENT;
            delay = 0;
            timeout = 0;
            name = "High SpO2";
            break;

        case ALARM_CODE.CRITICALLY_LOW_SPO2:
            alarmSeverity = ALARM_SEVERITY.CRITICAL_ALARM;
            higherAlarmCode = ALARM_CODE.NONE;
            intensity = ALARM_INTENSITY.HIGH;
            delay = 5;
            timeout = 0;
            name = "Critically low SpO2";
            break;
        case ALARM_CODE.VERY_LOW_SPO2:
            alarmSeverity = ALARM_SEVERITY.ALARM;
            higherAlarmCode = ALARM_CODE.CRITICALLY_LOW_SPO2;
            intensity = ALARM_INTENSITY.MEDIUM;
            delay = 60 * 2;
            timeout = 0;
            name = "Very Low SpO2";
            break;
        case ALARM_CODE.LOW_SPO2:
            alarmSeverity = ALARM_SEVERITY.WARNING;
            higherAlarmCode = ALARM_CODE.VERY_LOW_SPO2;
            intensity = ALARM_INTENSITY.SILENT;
            delay = 60 * 1;
            timeout = 0;
            name = "Low SpO2";
            break;

        case ALARM_CODE.LOW_PULSE:
            alarmSeverity = ALARM_SEVERITY.ALARM;
            higherAlarmCode = ALARM_CODE.CRITICALLY_LOW_SPO2;
            intensity = ALARM_INTENSITY.MEDIUM;
            delay = 60 * 2;
            timeout = 0;
            name = "Low Pulse";
            break;

        case ALARM_CODE.CRITICALLY_LOW_PULSE:
            alarmSeverity = ALARM_SEVERITY.CRITICAL_ALARM;
            higherAlarmCode = ALARM_CODE.NONE;
            intensity = ALARM_INTENSITY.HIGH;
            delay = 5;
            timeout = 0;
            name = "Critically low pulse";
            break;
        case ALARM_CODE.NONE:
            alarmSeverity = ALARM_SEVERITY.NONE;
            higherAlarmCode = ALARM_CODE.NONE;
            intensity = ALARM_INTENSITY.SILENT;
            delay = 5;
            timeout = 0;
            name = "NONE";
            break;
        default:
            // if the alarm code is not recognized, return null
            return null;
    }

    // create and return an Alarm object with the set values
    return new Alarm(alarmSeverity, alarmCode, higherAlarmCode, intensity, delay, timeout, name);
}

export const findLowerAlarms = (alarmCode) => {
    const alarms = []
    for (let key in ALARM_CODE) {
        alarms.push(generateAlarm(ALARM_CODE[key]))
    }

    const lowerAlarms = [];
    let currentAlarm = alarms.find(alarm => alarm.higherAlarmCode === alarmCode.alarmCode);

    while (currentAlarm && currentAlarm.higherAlarmCode !== ALARM_CODE.NONE) {
        lowerAlarms.push(currentAlarm.alarmCode);
        currentAlarm = alarms.find(alarm => alarm.higherAlarmCode === currentAlarm.alarmCode);
    }

    if (currentAlarm && currentAlarm.higherAlarmCode === ALARM_CODE.NONE) {
        lowerAlarms.push(currentAlarm.alarmCode);
    }
    return lowerAlarms;
}

export const getHighestSeverityAlarm = (alarms, alarmsToLookFor) => {

    if (alarms == undefined || alarms.size == 0) {
        return;
    }
    let highestSeverityAlarm = alarms
        ?.filter((alarm) => alarmsToLookFor.includes(alarm.alarmCode))
        ?.reduce(
            (prev, curr) => (prev.severity > curr.severity ? prev : curr),
            0
        );
    return highestSeverityAlarm;
};