import { Box, Typography } from "@mui/material";
import O2MaticLogo from "../../assets/Logo_white.svg";
import hearth from "../../assets/ic_hearth.svg";
import { colors } from "../../Style";
import { PRO_MODE } from "../../ProSimulator/ProSimulator";
import { useState, useEffect } from "react";
import { ALARM_CODE, getHighestSeverityAlarm } from "../../ProSimulator/Alarm";
import { OximeterState } from "../../ProSimulator/OximeterSimulator";
import { roundToNearest } from "../../Utils";
export const PauseScreen = (props) => {
  let pro = props.pro;
  let type = props.pro?.pauseScreenValues?.type;
  let isManual = pro?.mode === PRO_MODE.MAN;
  let oximeter = pro?.oximeter;
  const [flowAlarms, setFlowAlarms] = useState(false);

  useEffect(() => {
    let flowError = getHighestSeverityAlarm(props.pro?.alarms, [
      ALARM_CODE.O2_FLOW_ERROR,
    ]);

    if (flowError != 0 && flowError != undefined) {
      setFlowAlarms(true);
    } else {
      setFlowAlarms(false);
    }
  }, [props.pro?.alarms]);
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {type == "Logo" ? (
        <Box
          component="img"
          src={O2MaticLogo}
          alt="O2matic"
          sx={{
            width: "150px",
            p: { sm: 1, sx: 1 },
            position: "absolute",
            left: pro?.pauseScreenValues?.position?.x,
            top: pro?.pauseScreenValues?.position?.y,
          }}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "500px",
            height: "75px",
            justifyContent: "space-around",
            position: "absolute",
            left: pro?.pauseScreenValues?.position?.x,
            top: pro?.pauseScreenValues?.position?.y,
          }}
        >
          <Box
            sx={{
              width: "150px",
              height: "70px",
              display: "flex",
              flexDirection: "row",
              border: "2px solid " + colors.O2,
              borderRadius: "20px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                color: "white",
                fontWeight: "bold",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {pro?.oxygenOutput > 15
                ? "15.0+"
                : flowAlarms && !isManual
                ? pro?.oxygenInput.value<2?pro?.oxygenInput?.value?.toFixed(1):roundToNearest(pro?.oxygenInput?.value)
                : pro?.oxygenOutput<2?pro?.oxygenOutput?.toFixed(1):roundToNearest(pro?.oxygenOutput)
                }
            </Typography>
          </Box>

          <Box
            sx={{
              width: "75px",
              height: "75px",
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src={hearth}
              alt="Pulse"
              sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
              }}
            />
            <Typography
              variant="h4"
              sx={{
                color: "white",
                fontWeight: "bold",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                position: "absolute",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
              }}
            >
              {oximeter?.state != OximeterState.SIGNAL_DATA
                ? "---"
                : oximeter?.pulse}
            </Typography>
          </Box>

          <Box
            sx={{
              width: "150px",
              height: "70px",
              display: "flex",
              flexDirection: "row",
              border: "2px solid " + colors.SPO2,
              borderRadius: "20px",
              justifyContent: props.isManual ? "center" : "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                color: "white",
                fontWeight: "bold",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              {oximeter?.state != OximeterState.SIGNAL_DATA
                ? "---"
                : oximeter.spo2?.toFixed(props.decimalPoints)}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};
