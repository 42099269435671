import { useHistory, useLocation } from "react-router";
import { GetWindowState, defaultReducer, usePageState } from "../../Utils";
import { useEffect, useState } from "react";
import hearth from "../../assets/ic_hearth_filled.svg";
import uPlot from "uplot";
import UplotReact from "uplot-react";
import "uplot/dist/uPlot.min.css";
import React from "react";
import { Box, Typography } from "@mui/material";
import { colors } from "../../Style";
import { calculateTrendInfoTestData, createAverageArray, generateTestChartData } from "./chartsUtils";
import { UPlotChart } from "./UPlotChart";
import { DownArrow } from "../../components/UI/Arrows";

export const TREND_MODE = {
  TREND_MODE_12_MIN: "12m",
  TREND_MODE_1_HOUR: "1h",
  TREND_MODE_8_HOUR: "8h",
  TREND_MODE_24_HOUR: "24h",
};

export const Trends = (props) => {
  const deviceData = Array.from(props?.pro?.data);
  //const deviceData = calculateTrendInfoTestData()

  const deviceDataToTrendData = (data, length, mode) => {
    if (data == undefined || data.length == 0) {
      return [];
    }
    const time = [];
    const spo2 = [];
    const o2 = [];
    const pulse = [];
    const profileChange = [];
    const patientChange = [];
    const turnOnOff = [];

    if (mode === TREND_MODE.TREND_MODE_12_MIN) {
      data = data.reverse();
      for (let i = length; i >= 0; i--) {
        if (length - i > data.length) {
          break;
        }
        time.push(i);
        spo2.push(data[length - i]?.spo2);
        pulse.push(data[length - i]?.pulse);
        o2.push(data[length - i]?.o2);
        profileChange.push(false)
        patientChange.push(false)
        turnOnOff.push(false)

      }
      return [time, spo2, pulse, o2,profileChange,patientChange,turnOnOff];
    }
    data = data.reverse();
    let inner = length-data.length;
    for (let i = length; i >= 0; i--) {
      if (length - i > data.length) {
        break;
      }
      // for (let x = 0; x < inner/length; x++) {
      //   time.push(i * x);
      //   spo2.push(data[length - i]?.spo2);
      //   pulse.push(data[length - i]?.pulse);
      //   o2.push(data[length - i]?.o2);
      // }
      time.push(i);
      spo2.push(data[length - i]?.spo2);
      pulse.push(data[length - i]?.pulse);
      o2.push(data[length - i]?.o2);
      profileChange.push(data[length - i]?.profileChange?true:false)
      patientChange.push(data[length - i]?.patientChange?true:false)
      turnOnOff.push(data[length - i]?.turnOnOff?true:false)
    }
    return [time, spo2, pulse, o2,profileChange,patientChange,turnOnOff];
    // let returnData=createAverageArray(data,length)
    // return returnData
  };
  const history = useHistory();
  const location = useLocation();
  const state = GetWindowState();

  const defaultMenu = {
    LEFT: { label: "8H", route: TREND_MODE.TREND_MODE_8_HOUR, local: true },
    UP: { label: "TrendInfo", route: "trendInfo" },
    RIGHT: { label: "12M", route: TREND_MODE.TREND_MODE_12_MIN, local: true },
    MIDDLE: null,
    DOWN: { label: "Main", route: "home" },
  };

  const [pageState, setPageState] = usePageState(
    defaultReducer,
    state
      ? state
      : {
          menu: { ...defaultMenu },
          mode: TREND_MODE.TREND_MODE_1_HOUR,
        }
  );

  const updateNavigation = () => {
    setPageState((old) => {
      let menu = {};
      if (old.mode === TREND_MODE.TREND_MODE_12_MIN) {
        menu = {
          LEFT: {
            label: "1H",
            route: TREND_MODE.TREND_MODE_1_HOUR,
            local: true,
          },
          UP: { label: "TrendInfo", route: "trendInfo" },
          RIGHT: null,
          MIDDLE: null,
          DOWN: { label: "Main", route: "home" },
        };
      }
      if (old.mode === TREND_MODE.TREND_MODE_1_HOUR) {
        menu = {
          LEFT: {
            label: "8H",
            route: TREND_MODE.TREND_MODE_8_HOUR,
            local: true,
          },
          UP: { label: "TrendInfo", route: "trendInfo" },
          RIGHT: {
            label: "12M",
            route: TREND_MODE.TREND_MODE_12_MIN,
            local: true,
          },
          MIDDLE: null,
          DOWN: { label: "Main", route: "home" },
        };
      }

      if (old.mode === TREND_MODE.TREND_MODE_8_HOUR) {
        menu = {
          LEFT: {
            label: "24H",
            route: TREND_MODE.TREND_MODE_24_HOUR,
            local: true,
          },
          UP: { label: "TrendInfo", route: "trendInfo" },
          RIGHT: {
            label: "1H",
            route: TREND_MODE.TREND_MODE_1_HOUR,
            local: true,
          },
          MIDDLE: null,
          DOWN: { label: "Main", route: "home" },
        };
      }

      if (old.mode === TREND_MODE.TREND_MODE_24_HOUR) {
        menu = {
          LEFT: null,
          UP: { label: "TrendInfo", route: "trendInfo" },
          RIGHT: {
            label: "8H",
            route: TREND_MODE.TREND_MODE_8_HOUR,
            local: true,
          },
          MIDDLE: null,
          DOWN: { label: "Main", route: "home" },
        };
      }
      old.menu = menu;
      props.navigation.setMenu(menu);
      return old;
    });
  };

  const localNavigation = (navigator, action, menu) => {
    console.log({ pageState: pageState });

    if (action.type == "CLICK") {
      switch (menu[action.button].route) {
        case TREND_MODE.TREND_MODE_12_MIN:
          setPageState((state) => {
            return {
              ...state,
              mode: TREND_MODE.TREND_MODE_12_MIN,
            };
          });
          break;
        case TREND_MODE.TREND_MODE_1_HOUR:
          setPageState((state) => {
            return {
              ...state,
              mode: TREND_MODE.TREND_MODE_1_HOUR,
            };
          });
          break;
        case TREND_MODE.TREND_MODE_8_HOUR:
          setPageState((state) => {
            return {
              ...state,
              mode: TREND_MODE.TREND_MODE_8_HOUR,
            };
          });
          break;
        case TREND_MODE.TREND_MODE_24_HOUR:
          setPageState((state) => {
            return {
              ...state,
              mode: TREND_MODE.TREND_MODE_24_HOUR,
            };
          });
          break;
      }
    }
  };

  useEffect(() => {
    const state = pageState;
    const searchParams = new URLSearchParams();
    searchParams.append("windowState", JSON.stringify(state));
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });

    if (
      pageState?.window?.menu == null ||
      pageState?.window?.menu == undefined
    ) {
      props.navigation.setMenu(pageState.menu);
    }
    props.navigation.addLocalNavigation(localNavigation);
    return () => {
      props.navigation.addLocalNavigation(null);
    };
  }, [pageState]);

  useEffect(() => {
    updateNavigation();
  }, [pageState.mode]);

  const [time, setTime] = useState(Date.now());
  const [timestamps, setTimestamps] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const now = Date.now();

    setTimestamps(getTimestamps(pageState.mode));
  }, [pageState.mode, time]);

  const data = generateTestChartData();
  return (
    <Box sx={{ width: "100%", height: "100%", background: "black",position:"relative"}}>
      <Box sx={{ width: "100%", height: "100%", background: "black" }}>
        <UPlotChart
          mode={pageState.mode}
          xRange={[0, getTrendEntryNumber(pageState.mode)]}
          data={deviceDataToTrendData(
            deviceData,
            getTrendEntryNumber(pageState.mode),
            pageState.mode
          )}
        />
      </Box>
      <Typography sx={{position:"absolute",top:12,left:4,fontWeight:"bold",color:colors.SPO2}}>SpO2</Typography>
      <Box sx={{position:"absolute",top:12,left:75,fontWeight:"bold",color:colors.SPO2}}>        <Box
          component="img"
          src={hearth}
          alt="Pulse"
          sx={{
            width: "20px",
            height: "20px",
          }}
        /></Box>
      <Typography sx={{position:"absolute",top:12,right:45,fontWeight:"bold",color:colors.O2}}>O2</Typography>
      {pageState.mode!=TREND_MODE.TREND_MODE_12_MIN?renderGraphLegend():null}
    </Box>
  );
};

const renderGraphLegend=()=>{
  return(
    <Box sx={{width:"200px",height:"80px",position:"absolute",right:0,bottom:-77,background:"black",pl:1}}>
    <Box sx={{display:"flex",width:"100%",flexDirection:"row",justifyContent:"start",alignItems:"center"}}>
      <Box sx={{display:"flex",flexDirection:"row",width:"20px",height:"25px",justifyContent:"center",alignContent:"center",alignItems:"center",mr:1}}>
        <Box sx={{width:"20px",height:"1px",background:colors.COLORS_SUBITEM_DEFAULT}}/>
      </Box>
      <Box sx={{display:"flex",flexDirection:"row",width:"60px",height:"100%",justifyContent:"end"}}>
        <Typography sx={{width:"100%",fontSize:"12px",color:colors.COLORS_SUBITEM_DEFAULT}}>On/Off</Typography>
      </Box>
    </Box>

    <Box sx={{display:"flex",width:"100%",flexDirection:"row",justifyContent:"start",alignItems:"center"}}>
      <Box sx={{display:"flex",flexDirection:"row",width:"20px",height:"25px",justifyContent:"center",alignContent:"center",alignItems:"center",mr:1}}>
        <Box sx={{width:"8px",height:"1px",background:colors.COLORS_SUBITEM_DEFAULT,mr:"4px"}}/>
        <Box sx={{width:"8px",height:"1px",background:colors.COLORS_SUBITEM_DEFAULT}}/>
      </Box>
      <Box sx={{display:"flex",flexDirection:"row",width:"60px",height:"100%",justifyContent:"end"}}>
        <Typography sx={{width:"100%",fontSize:"12px",color:colors.COLORS_SUBITEM_DEFAULT}}>Patient</Typography>
      </Box>
    </Box>

    <Box sx={{display:"flex",width:"100%",flexDirection:"row",justifyContent:"start",alignItems:"center"}}>
      <Box sx={{display:"flex",flexDirection:"row",width:"20px",height:"25px",justifyContent:"center",alignContent:"start",mr:1}}>
        <DownArrow sx={{width:"15px",height:"25px"}}/>
      </Box>
      <Box sx={{display:"flex",flexDirection:"row",width:"60px",height:"100%",justifyContent:"end"}}>
        <Typography sx={{width:"100%",fontSize:"12px",color:colors.COLORS_SUBITEM_DEFAULT}}>Profile</Typography>
      </Box>
    </Box>
  </Box>
  )
}

export const getTrendEntryNumber = (mode) => {
  if (mode === TREND_MODE.TREND_MODE_12_MIN) {
    return 13.35 * 60;
  }
  if (mode === TREND_MODE.TREND_MODE_1_HOUR) {
    return ((60 * 68))-13;
  }

  if (mode === TREND_MODE.TREND_MODE_8_HOUR) {
    return (8 * 60 * 84);
  }

  if (mode === TREND_MODE.TREND_MODE_24_HOUR) {
    return (24 * 60 * 56);
  }
};
export const getTimestamps = (mode) => {
  const now = Date.now();

  if (mode === TREND_MODE.TREND_MODE_12_MIN) {
    return [
      now - 12 * 60 * 1000,
      now - 9 * 60 * 1000,
      now - 6 * 60 * 1000,
      now - 3 * 60 * 1000,
      now,
    ];
  }
  if (mode === TREND_MODE.TREND_MODE_1_HOUR) {
    return [
      now - 60 * 60 * 1000, // T-01:00
      now - 45 * 60 * 1000, // T-00:45
      now - 30 * 60 * 1000, // T-00:30
      now - 15 * 60 * 1000, // T-00:15
      now,
    ];
  }

  if (mode === TREND_MODE.TREND_MODE_8_HOUR) {
    return [
      now - 8 * 60 * 60 * 1000,
      now - 6 * 60 * 60 * 1000,
      now - 4 * 60 * 60 * 1000,
      now - 2 * 60 * 60 * 1000,
      now,
    ];
  }

  if (mode === TREND_MODE.TREND_MODE_24_HOUR) {
    return [
      now - 24 * 60 * 60 * 1000,
      now - 18 * 60 * 60 * 1000,
      now - 12 * 60 * 60 * 1000,
      now - 6 * 60 * 60 * 1000,
      now,
    ];
  }
};
