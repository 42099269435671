import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { OximeterState } from "../../ProSimulator/OximeterSimulator";
import { colors } from "../../Style";
import { ALARM_CODE } from "../../ProSimulator/Alarm";
import { getHighestSeverityAlarm } from "../../ProSimulator/Alarm";
import { blink } from "../../Style";
import { Translate } from "react-i18nify";
export const Spo2Component = (props) => {
  let color = props.color;
  let name = props.name;
  let unit = props.unit;
  let error = props.error;
  let value = props.value;
  let oximeter = props.pro?.oximeter;
  const [colorTextLowSpo2, setColorTextLowSpo2] = useState("white");
  const [colorTextHighSpo2, setColorTextHighSpo2] = useState("white");
  const [blinkSpeed, setBlinkSpeed] = useState("none");

  const [spo2Alarms, setSpo2Alarms] = useState(false);

  const [oximeterState, setOximeterState] = useState(oximeter?.state);

  const getTextColorFromAlarmSpO2 = (alarm) => {
    switch (alarm?.alarmCode) {
      case ALARM_CODE.CRITICALLY_LOW_SPO2:
        return colors.COLORS_ALARM_HIGH;
      case ALARM_CODE.VERY_LOW_SPO2:
        return colors.COLORS_ALARM_MEDIUM;
      case ALARM_CODE.LOW_SPO2:
        return colors.COLORS_ALARM_LOW;
      case ALARM_CODE.HIGH_SPO2:
        return colors.COLORS_ALARM_LOW;
      default:
        return "white";
    }
  };
  const getBlinkSpeedFromAlarmSpO2 = (alarm) => {
    switch (alarm?.alarmCode) {
      case ALARM_CODE.CRITICALLY_LOW_SPO2:
        return "0.5s";
      case ALARM_CODE.VERY_LOW_SPO2:
        return "1.0s";
      case ALARM_CODE.LOW_SPO2:
        return "none";
      case ALARM_CODE.HIGH_SPO2:
        return "0.5s";
      default:
        return "white";
    }
  };

  useEffect(() => {
    if (props.isManual) {
      setSpo2Alarms(false);
      setColorTextLowSpo2(getTextColorFromAlarmSpO2("white"));
      setColorTextHighSpo2(getTextColorFromAlarmSpO2("white"));
      setBlinkSpeed("none");
      return;
    }
    let highestAlarmLowSpo2 = getHighestSeverityAlarm(props.pro?.alarms, [
      ALARM_CODE.CRITICALLY_LOW_SPO2,
      ALARM_CODE.VERY_LOW_SPO2,
      ALARM_CODE.LOW_SPO2,
    ]);
    let highestAlarmHighSpo2 = getHighestSeverityAlarm(props.pro?.alarms, [
      ALARM_CODE.HIGH_SPO2,
    ]);
    if (highestAlarmHighSpo2 != 0 || highestAlarmLowSpo2 != 0) {
      setSpo2Alarms(true);
    } else {
      setSpo2Alarms(false);
    }

    setColorTextLowSpo2(getTextColorFromAlarmSpO2(highestAlarmLowSpo2));
    setColorTextHighSpo2(getTextColorFromAlarmSpO2(highestAlarmHighSpo2));
    setBlinkSpeed(getBlinkSpeedFromAlarmSpO2(highestAlarmLowSpo2));
  }, [props.pro?.alarms]);

  useEffect(() => {
    setOximeterState(oximeter?.state);
  }, [oximeter?.state]);
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "16%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Typography sx={{ color: colors.TEXT_COLOR }} variant="h6">
          {name}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            position: "absolute",
            right: "100px",
            top: "20px",
            color: "gray",
          }}
        >
          {unit}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "75%",
          height: "35%",
          display: "flex",
          flexDirection: "row",
          border: "2px solid " + color,
          borderRadius: "25px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {oximeterState != OximeterState.SIGNAL_DATA ? (
          <Typography variant="h2" sx={{ color: "white" }}>
            ---
          </Typography>
        ) : (
          <Typography
            variant="h2"
            sx={{
              color:
                colorTextLowSpo2 != "white"
                  ? colorTextLowSpo2
                  : colorTextHighSpo2,
            }}
          >
            {value?.toFixed(props.decimalPoints)}
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          width: "2px",
          height: "8%",
          display: "flex",
          flexDirection: "row",
          background: color,
          justifyContent: "center",
        }}
      ></Box>
      <Box
        sx={{
          width: "100%",
          height: "15%",
          display: "flex",
          flexDirection: "row",
          border: "2px solid " + color,
          borderRadius: "20px",
          justifyContent: props.isManual ? "center" : "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            ml: 2,
            color:
              oximeterState != OximeterState.SIGNAL_DATA
                ? "white"
                : colorTextLowSpo2,
            display: props.isManual ? "none" : "flex",
          }}
        >
          {props?.min}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            color: colors.COLORS_ALARM_LOW,
            display: props.isManual ? "flex" : "none",
            fontWeight: "bold",
          }}
        >
          <Translate value="ManualMode" />
        </Typography>
        <Typography
          variant="h5"
          sx={{
            mr: 2,
            color:
              oximeterState != OximeterState.SIGNAL_DATA
                ? "white"
                : colorTextHighSpo2,
            display: props.isManual ? "none" : "flex",
          }}
        >
          {props?.max}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "18%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            display:
              spo2Alarms &&
              oximeterState == OximeterState.SIGNAL_DATA &&
              !props.isManual
                ? "block"
                : "none",
            color:
              colorTextLowSpo2 != "white"
                ? colorTextLowSpo2
                : colorTextHighSpo2,
            fontWeight: "bold",
            animation: `${blink} ${blinkSpeed} infinite`,
          }}
        >
          {error}
        </Typography>
      </Box>
    </Box>
  );
};
