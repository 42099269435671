import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router";
import { GetWindowState } from "../../Utils";
import O2MaticLogo from "../../assets/Logo_white.svg";
import { getDateString, getTimeString } from "../../TimeUtils";
import { usePageState, defaultReducer } from "../../Utils";
import { colors } from "../../Style";
import { MiddleComponent } from "./MiddleComponent";
import { Spo2Component } from "./Spo2Component";
import { O2Component } from "./O2Component";
import { BatteryChargeState } from "../../ProSimulator/ProBattery";
import { BatteryView } from "../../components/BatteryView";
import { INCREMENT_MODE, PRO_MODE } from "../../ProSimulator/ProSimulator";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { BUTTON_EVENT } from "../../components/Navigation/NavigationButton";
import { Translate, translate } from "react-i18nify";
import { PopUpWrapper } from "../../components/PopUpWrapper";
import { PIN_ROLE, PinMenu } from "../../components/UI/PinMenu";
import { ConfirmRiskyProfile } from "../../components/UI/ConfirmRiskyProfile";
export const Home = (props) => {
  const history = useHistory();
  const location = useLocation();
  const state = GetWindowState();
  let navigation = props.navigation;

  let isManual = props.pro?.mode === PRO_MODE.MAN;
  let isTestMode = props.pro?.mode === PRO_MODE.TEST;
  let incrementMode = props.pro?.incrementMode;
  let isAdminPinEnabled = props.pro?.adminPIN;

  const [popup, setPopUp] = useState(null);
  const [pageState, setPageState] = usePageState(
    defaultReducer,
    state
      ? state
      : {
          window: null,
        }
  );

  const setWindowState = (state, windowState) => {
    setPageState((prev) => {
      return {
        ...prev,
        window: { ...prev.window, state: windowState, menu: state },
      };
    });
    props.navigation.setMenu(state);
  };
  const renderPopUp = (state) => {
    let popUp = null;
    switch (state.windowName) {
      case "setPin":
        popUp = (
          <PinMenu
            name={"setPin"}
            label={translate("InputCurrentPin")}
            setState={setWindowState}
            role={PIN_ROLE.VERIFY_PIN}
            currentPin={props.pro.pin.usb}
            menu={state?.window?.menu}
            state={state?.window?.state}
            onComplete={(eneteredPin) => {
              if (eneteredPin == props.pro.pin.usb) {
                console.log("PIN CORRECT");
                setPageState((prev) => {
                  return {
                    ...prev,
                    // menu: { ...defaultMenu },
                    windowName: null,
                    window: null,
                  };
                });
                history.push({
                  pathname: "/admin",
                });
              }
            }}
            onCancel={() => {
              setPageState((prev) => {
                return {
                  ...prev,
                  windowName: null,
                  window: null,
                  menu: updateNavigation(),
                };
              });
            }}
          />
        );
        break;
      case "confirmEndTest":
        popUp = (
          <ConfirmRiskyProfile
          width="250px"
            message={
              <Typography
                variant="body"  
              >
                <Translate value={"EndTest?"} />
              </Typography>
            }
            name={"EndTest"}
            onComplete={(state) => {
              props.pro.endTest();
              setPageState((prev) => {
                return {
                  ...prev,
                  windowName: null,
                  window: null,
                  menu: updateNavigation(),
                  updated: null,
                };
              });
            }}
            onCancel={() => {
              setPageState((prev) => {
                return {
                  ...prev,
                  menu: updateNavigation(),
                  windowName: null,
                  window: null,
                };
              });
            }}
            setState={setWindowState}
            menu={state?.window?.menu}
            state={state?.window?.state}
          />
        );
        break;
    }
    return popUp;
  };

  useEffect(() => {
    const state = pageState;

    const searchParams = new URLSearchParams();
    searchParams.append("windowState", JSON.stringify(state));
    //searchParams.append("deviceState", JSON.stringify(props.pro));
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });

    if (
      pageState?.window?.menu == null ||
      pageState?.window?.menu == undefined
    ) {
      props.navigation.setMenu(pageState.menu);
    }

    return () => {
      props.navigation.addLocalNavigation(null);
    };
  }, [pageState]);
  useEffect(() => {
    let pop = renderPopUp(pageState);
    setPopUp(pop);
  }, [pageState.windowName, pageState.window]);

  useEffect(() => {
    navigation.addLocalNavigation((navigation, action, menu) => {
      if (pageState.window?.state?.windowNavigation != null) {
        pageState.window?.state?.windowNavigation(
          navigator,
          action,
          menu,
          pageState?.window?.state
        );
        return;
      }
      if (action.type == BUTTON_EVENT.CLICK) {
        let localAction = menu[action.button];
        switch (localAction.route) {
          case "Oxygen_Increase":
            props.pro.increaseOxygen();
            console.log("increase oxygen");
            break;
          case "Oxygen_Decrease":
            props.pro.decreaseOxygen();
            console.log("Oxygen_Decrease oxygen");
            break;
          case "confirmEndTest":
            setPageState((prev) => {
              return {
                ...prev,
                // menu: { ...defaultMenu },
                windowName: "confirmEndTest",
                window: null,
              };
            });
            return;
            break;
          case "Change_step":
            props.pro.switchIncrementMode();
            console.log("Change_step oxygen");
            break;
          case "admin":
            if (props.pro.adminPIN) {
              console.log("Show admin pin");
              setPageState((prev) => {
                return {
                  ...prev,
                  // menu: { ...defaultMenu },
                  windowName: "setPin",
                  window: null,
                };
              });
              return;
            }
            navigation.push("/admin");
        }
      }
      console.log("local nav Home");
    });
  }, [props.navigation]);

  const updateNavigation = () => {
    let menu = {};
    if (isManual) {
      menu = {
        LEFT: {
          label:
            incrementMode === INCREMENT_MODE.LARGE
              ? "Small step"
              : "Large step",
          route: "Change_step",
          local: true,
        },
        UP: {
          label: <PlayArrowIcon sx={{ transform: "rotate(-90deg)" }} />,
          route: "Oxygen_Increase",
          local: true,
        },
        RIGHT: { label: "Trend", route: "trends" },
        MIDDLE: {
          label: (
            <Typography variant="h6" sx={{ lineHeight: 1 }}>
              Oxygen
            </Typography>
          ),
        },
        DOWN: {
          label: <PlayArrowIcon sx={{ transform: "rotate(90deg)" }} />,
          route: "Oxygen_Decrease",
          local: true,
        },
      };
    } else if (isTestMode) {
      menu = {
        LEFT: { label: "EndTest", route: "confirmEndTest",local:true },
        UP: { label: "Trends", route: "trends" },
        RIGHT: null,
        MIDDLE: null,
        DOWN: { label: "Trend info", route: "trendInfo" },
      };
    } else {
      menu = {
        LEFT: { label: "Patient", route: "patient" },
        UP: { label: "Trends", route: "trends" },
        RIGHT: {
          label: "Admin",
          route: "admin",
          isAdminPinEnabled: isAdminPinEnabled,
          local: true,
        },
        MIDDLE: null,
        DOWN: { label: "Trend info", route: "trendInfo" },
      };
    }
    return menu;
  };
  useEffect(() => {
    navigation.setMenu(updateNavigation());
  }, [isManual, incrementMode, isAdminPinEnabled, isTestMode]);
  return (
    <PopUpWrapper popup={popup}>
      <Box sx={{ width: "33%", height: "100%" }}>
        <O2Component
          isManual={isManual}
          pro={props.pro}
          decimalPoints={1}
          min={props.pro?.profile?.minO2}
          max={props.pro?.profile?.maxO2}
          value={props.pro?.oxygenOutput}
          color={colors.O2}
          name={translate("O2")}
          unit={translate("l/min")}
          error={translate("FlowError")}
        />
      </Box>
      <Box sx={{ width: "18%", height: "100%" }}>
        <MiddleComponent isManual={isManual} pro={props.pro}></MiddleComponent>
      </Box>
      <Box sx={{ width: "33%", height: "100%" }}>
        <Spo2Component
          isManual={isManual}
          pro={props.pro}
          decimalPoints={0}
          min={props.pro?.profile?.minSpO2}
          max={props.pro?.profile?.maxSpO2}
          value={props.pro?.oximeter.spo2}
          color={colors.SPO2}
          name="SpO₂"
          unit="%"
          error="SpO2"
        />
      </Box>
    </PopUpWrapper>
  );
};
