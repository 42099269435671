import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { OximeterState } from "../../ProSimulator/OximeterSimulator";
import { colors } from "../../Style";
import { ALARM_CODE } from "../../ProSimulator/Alarm";
import { getHighestSeverityAlarm } from "../../ProSimulator/Alarm";
import { blink } from "../../Style";
import { Translate } from "react-i18nify";
import { roundToNearest } from "../../Utils";
export const O2Component = (props) => {
  let color = props.color;
  let name = props.name;
  let unit = props.unit;
  let error = props.error;
  let value = props.value;
  let isManual = props.isManual;

  const [flowAlarms, setFlowAlarms] = useState(false);

  useEffect(() => {
    let flowError = getHighestSeverityAlarm(props.pro?.alarms, [
      ALARM_CODE.O2_FLOW_ERROR,
    ]);

    if (flowError != 0 && flowError != undefined) {
      setFlowAlarms(true);
    } else {
      setFlowAlarms(false);
    }
  }, [props.pro?.alarms]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "16%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Typography sx={{ color: colors.TEXT_COLOR }} variant="h6">
          {name}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            position: "absolute",
            right: "100px",
            top: "20px",
            color: "gray",
          }}
        >
          {unit}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "75%",
          height: "35%",
          display: "flex",
          flexDirection: "row",
          border: "2px solid " + color,
          borderRadius: "25px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color:
              props.pro?.oxygenOutput > 15 ? colors.COLORS_ALARM_LOW : "white",
          }}
        >
          {props.pro?.oxygenOutput > 15
            ? "15.0+"
            : flowAlarms && !isManual
            ? props.pro.oxygenInput.value.toFixed(props.decimalPoints)
            : value < 2
            ? value.toFixed(props.decimalPoints)
            : roundToNearest(value)}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "2px",
          height: "8%",
          display: "flex",
          flexDirection: "row",
          background: color,
          justifyContent: "center",
        }}
      ></Box>
      <Box
        sx={{
          width: "100%",
          height: "15%",
          display: "flex",
          flexDirection: "row",
          border: "2px solid " + color,
          borderRadius: "20px",
          justifyContent: props.isManual ? "center" : "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            ml: 2,
            color: "white",
            display: props.isManual ? "none" : "flex",
          }}
        >
          {props?.min}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            color: colors.COLORS_ALARM_LOW,
            display: props.isManual ? "flex" : "none",
            fontWeight: "bold",
          }}
        >
          <Translate value="ManualMode" />
        </Typography>
        <Typography
          variant="h5"
          sx={{
            mr: 2,
            color: "white",
            display: props.isManual ? "none" : "flex",
          }}
        >
          {props?.max}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "18%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            display:
              flowAlarms && props.pro?.oxygenOutput <= 15 ? "block" : "none",
            color: colors.COLORS_ALARM_HIGH,
            animation: `${blink} 0.5s infinite`,
            fontWeight: "bold",
          }}
        >
          {error}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            display:
              props.pro?.oxygenOutput > 15 && isManual ? "block" : "none",
            color: colors.COLORS_ALARM_LOW,
            fontWeight: "bold",
          }}
        >
          Flow inaccurate
        </Typography>
      </Box>
    </Box>
  );
};
