import react, { useCallback, useRef, useState, useMemo, useEffect } from "react";
import { useLocation } from 'react-router-dom';
export function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export const defaultReducer = (state, action) => {
  console.log({"setting state":state})
  return action;
};


export const GetWindowState = () => {
  let query = useQuery();
  let location = useLocation();
  let url = location.pathname;
  let state = JSON.parse(query.get("windowState"));
  return state;
};



//   function useReducer(reducer, initialState) {
//     const [state, setState] = useState(initialState);

//     function dispatch(action) {
//       const nextState = reducer(state, action);
//       setState(nextState);
//     }

//     return [state, dispatch];
//   }

export const usePageState = (reducer, initialState) => {
  const [state, setState] = useState(initialState);

  function dispatch(action) {
    const nextState = reducer(state, action);
    setState(nextState);
  }

  return [state, dispatch];
}

export const useLongPress = (
  onLongPress,
  onStartTouch,
  onClick,
  onRelease,
  { shouldPreventDefault = true, delay = 300 } = {}
) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeout = useRef();
  const target = useRef();

  const start = useCallback(
    (event) => {
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener(
          "touchend",
          preventDefault,
          {
            passive: false,
          }
        );
        target.current = event.target;
      }
      onStartTouch(event);
      timeout.current = setTimeout(() => {
        onLongPress(event);
        setLongPressTriggered(true);
      }, delay);
    },
    [onLongPress, delay, shouldPreventDefault, onStartTouch]
  );

  const clear = useCallback(
    (event, shouldTriggerClick = true) => {
      timeout.current && clearTimeout(timeout.current);
      if (longPressTriggered && onRelease) {
        onRelease(event);
      } else if (shouldTriggerClick && !longPressTriggered && onClick) {
        onClick(event);
        onRelease(event);
      }
      setLongPressTriggered(false);
      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener(
          "touchend",
          preventDefault
        );
      }
    },
    [shouldPreventDefault, onClick, onRelease, longPressTriggered]
  );

  return {
    onMouseDown: (e) => start(e),
    onTouchStart: (e) => start(e),
    onMouseUp: (e) => clear(e),
    onMouseLeave: (e) => clear(e, false),
    onTouchEnd: (e) => clear(e),
  };
};


const isTouchEvent = event => {
  return "touches" in event;
};

const preventDefault = event => {
  if (!isTouchEvent(event)) return;

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

export const roundToNearest=(value)=> {
  var nearest = Math.round(value * 2) / 2;
  return nearest.toFixed(1);
}