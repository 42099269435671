import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import AspectRatio from "@mui/joy/AspectRatio";
import { NavigationButton } from "./NavigationButton";
import React, { useEffect, useState } from "react";
import { BatteryChargeState } from "../../ProSimulator/ProBattery";
import O2MaticLogo from "../../assets/Logo_white.svg";
import {
  getDateString,
  getDateStringFromDate,
  getTimeString,
  getTimeStringFromDate,
  secondsToMMSS,
} from "../../TimeUtils";

import { colors } from "../../Style";
import { Oximeter } from "../Oximeter";
import useWindowDimensions from "../Dimensions";
import { OxygenInput } from "../OxygenInput";
import { BatteryInput } from "../BatteryInput";
import { BatteryView } from "../../components/BatteryView";
import { NavigationMenu } from "./NavigationMenu";
import { ControlPanel } from "./ControlPanel";
import { MuteIndicator } from "../MuteIndicator";
import { PopUpWrapper } from "../PopUpWrapper";
import { PowerDown } from "../UI/PowerDown";
import { BatteryPopUp } from "../UI/BatteryPopUp";
import { ALARM_CODE, getHighestSeverityAlarm } from "../../ProSimulator/Alarm";
import { Translate } from "react-i18nify";
import { PauseScreen } from "../UI/PauseScreen";
import { Redirect, useLocation } from "react-router";
import { GetWindowState, getWindowState, useQuery } from "../../Utils";
import { createKeyValuePair, readKeyValuePair } from "../../AzureBlobUtils";
import { Link } from "react-router-dom";
import CustomLink from "../UI/CustomLink";
import { Contact } from "../Contact";
import { HubSpotForm } from "../HubSpotForm";
import { PRO_MODE } from "../../ProSimulator/ProSimulator";

export const Layout = (props) => {
  let activeProfile = props.pro?.profile;
  let patientIDName =
    props.pro?.patient?.patientId + "-" + props.pro?.patient?.patientName;
  let location = useLocation();
  let [noPower, setNoPower] = useState(
    props.pro?.battery.chargeState == BatteryChargeState.NO_POWER
  );
  let query = useQuery();

  let path = window.location.origin;
  const [batteryPopUp, setBatteryPopUp] = useState({ visible: false });
  const [pauseScreenPopUp, setPauseScreenPopUp] = useState(false);

  useEffect(() => {
    if (
      noPower == false &&
      props.pro?.battery.chargeState == BatteryChargeState.NO_POWER
    ) {
      let timeout = setTimeout(() => {
        setBatteryPopUp((prev) => {
          return { ...prev, visible: false };
        });
      }, 10000);
      let severity = getHighestSeverityAlarm(props.pro.alarms, [
        ALARM_CODE.BATTERY_WARNING,
        ALARM_CODE.BATTERY_CRITICAL,
        ALARM_CODE.BATTERY_FAIL,
      ]);
      setBatteryPopUp((prev) => {
        return {
          ...prev,
          visible: true,
          timeout: timeout,
          severity: severity == 0 ? 0 : severity.alarmCode,
        };
      });
    } else if (props.pro?.battery.chargeState != BatteryChargeState.NO_POWER) {
      clearTimeout(batteryPopUp.timeout);
      setBatteryPopUp((prev) => {
        return { ...prev, visible: false };
      });
    }
    setNoPower(props.pro?.battery.chargeState == BatteryChargeState.NO_POWER);
  }, [props.pro?.battery.chargeState]);

  useEffect(() => {
    if (
      noPower == false &&
      props.pro?.battery.chargeState == BatteryChargeState.NO_POWER
    ) {
      setBatteryPopUp((prev) => {
        return { ...prev, visible: true };
      });
    }
    setNoPower(props.pro?.battery.chargeState == BatteryChargeState.NO_POWER);
    setPauseScreenPopUp(props?.pro?.pauseScreenValues?.isOn);
  }, [props.pro?.counter, props.pro?.pauseScreenValues.isOn]);

  const showLayout = props?.pro?.isRunning;


  const getTransformOrigin=(width,height,boxWidth,boxHeight,boxCalculatedWidth,boxCalculatedHeight)=>{
    let vertical="top"
    let horitonzal="center"
    console.table({width:width,
    height:height,
  boxWidth:boxWidth,
boxHeight:boxHeight,
boxCalculatedWidth:boxCalculatedWidth,
boxCalculatedHeight:boxCalculatedHeight})
    if(width<boxWidth||height<boxHeight){
      console.log("smaller")
      horitonzal="left"
      if(height+100<boxCalculatedHeight){
        console.log("centering height")
        horitonzal="center"
        // if(height<boxCalculatedHeight){
        //   horitonzal="left"
        // }
      }
    }
    else{
      if(width<height){
        horitonzal="left"
      }
    }


    return horitonzal+" "+vertical
  }
  const { height, width } = useWindowDimensions();

  let boxWidth = width > 950 ? 950 : width;
  let boxHeigth = (boxWidth * 700) / 950;
  let scale = (boxWidth / 950) * 100;

  if (height < boxHeigth) {
    scale = (height / 700) * 100;
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        transform: "scale(" + scale + "%)",
        transformOrigin:getTransformOrigin(width,height,950,480,boxWidth,boxHeigth),
        justifyContent:boxWidth+50>width?"start":"center"
        // justifyContent:width>1000||height < boxHeigth?"center":"start",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          maxWidth: "900px",
        }}
      >
        <Box
          sx={{
            width: "900px",
            height: "480px",
            background: "black",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          <PopUpWrapper popup={<BatteryPopUp state={batteryPopUp} />}>
            <PopUpWrapper
              popup={
                <PowerDown
                  visible={props.pro?.turnOffTimer?.isOn}
                  count={props.pro?.turnOffTimer?.time}
                />
              }
            >
              <PopUpWrapper
                popup={
                  <Box
                    sx={{
                      display: pauseScreenPopUp ? "block" : "none",
                      width: "900px",
                      height: "480px",
                      background: "black",
                    }}
                  >
                    <PauseScreen pro={props.pro} />
                  </Box>
                }
              >
                <Box
                  sx={{
                    visibility: showLayout ? "visible" : "hidden",
                    width: "900px",
                    height: "480px",
                    background: "black",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "16%",
                      background: noPower
                        ? colors.COLORS_LAYOUT_BACKGROUND_NO_POWER
                        : "black",
                      display: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        width: "25%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src={O2MaticLogo}
                        alt="O2matic"
                        sx={{ p: { sm: 1, sx: 1 } }}
                      />
                    </Box>
                    <Box
                      sx={{
                        width: "50%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "row" }}>
                        <Typography sx={{ color: colors.TEXT_COLOR }}>
                          <Translate value="ActiveProfile" />
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "bold",
                            color: activeProfile?.isRisky
                              ? colors.COLORS_ALARM_MEDIUM
                              : activeProfile?.isCustom
                              ? colors.COLORS_ALARM_LOW
                              : colors.TEXT_COLOR,
                          }}
                        >
                          {activeProfile?.isCustom
                            ? "CUSTOM"
                            : activeProfile?.name}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{ color: colors.TEXT_COLOR }}
                        variant="body2"
                      >
                        Patient: {patientIDName}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "25%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "75%",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{ color: colors.TEXT_COLOR }}
                          variant="body2"
                        >
                          {getDateStringFromDate(props?.pro?.deviceTime)}
                        </Typography>
                        <Typography
                          sx={{ fontWeight: "bold", color: colors.TEXT_COLOR }}
                        >
                          {getTimeStringFromDate(props?.pro?.deviceTime)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "25%",
                          height: "100%",
                          display: "flex",
                        }}
                      >
                        {/* //battery */}
                        <Box
                          sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <BatteryView pro={props.pro} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "68%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {props.children}
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "16%",
                      background: noPower
                        ? colors.COLORS_LAYOUT_BACKGROUND_NO_POWER
                        : "black",
                      display: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        width: "25%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "end",
                        alignItems: "start",
                      }}
                    >
                      {props?.pro?.mode == PRO_MODE.TEST ? 
                      <Box sx={{width:"225px",height:"70px",border:"1px solid white",display:"flex",flexDirection:"column",justifyContent:"space-around",alignItems:"center"}}>
                        <Typography variant="h5" sx={{alignSelf:"start", width:"100%",textAlign:"center",color:colors.COLORS_ALARM_LOW}}>{props?.pro?.testSetup?.name}</Typography>
                        <Typography variant="body" sx={{color:colors.COLORS_SUBITEM_DEFAULT, width:"100%",textAlign:"center"}}>{props?.pro?.testSetup?.part }- {secondsToMMSS(props?.pro?.testSetup?.timeLeft) }</Typography>
                      </Box>
                      : (
                        <Typography
                          sx={{ m: "5px", color: colors.TEXT_COLOR }}
                          variant="body1"
                        >
                          <Translate value="Location" />:{props.pro?.location}
                        </Typography>
                      )}
                    </Box>

                    <Box
                      sx={{
                        width: "50%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                      }}
                    >
                      <NavigationMenu navigation={props.navigation} />
                    </Box>
                    <Box
                      sx={{
                        width: "25%",
                        height: "100%",
                        display: showLayout ? "flex" : "none",
                        flexDirection: "column",
                        justifyContent: "end",
                        p: 1,
                        pr: 2,
                        alignItems: "end",
                      }}
                    >
                      <MuteIndicator mute={props.pro?.mute} test="123" />
                    </Box>
                  </Box>
                </Box>
              </PopUpWrapper>
            </PopUpWrapper>
          </PopUpWrapper>

          <Box
            sx={{ display: "flex", height: "100%", flexDirection: "column" }}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                position: "absolute",
                flexDirection: "column",
                zIndex:45
              }}
            >
              <Oximeter oximeter={props?.pro?.oximeter} />
              <OxygenInput oxygenInput={props?.oxygenInput} />
              <BatteryInput battery={props.batteryInput} />
              <Contact />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: "900px",
            height: "150px",
            background: "black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ControlPanel
            pro={props.pro}
            navigation={props.navigation}
            buttonCallback={props.buttonCallback}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography>
              Version:0.2.0-BETA - Only for training purposes
            </Typography>
            <Typography>
              Disclaimer: The software version that is being simulated is 1.7, device in front
              of you might be running older version
            </Typography>
          </Box>

          {/* <Redirect navigate={"https://o2matic.com/#kontakt"}></Redirect> */}
        </Box>
      </Box>
    </Box>

    // </AspectRatio>
  );
};
