import { BatteryChargeState, ProBattery } from "./ProBattery";
import { generateAlarm, ALARM_CODE, findLowerAlarms } from "./Alarm";
import { OximeterSimulator, OximeterState } from "./OximeterSimulator";
import { OxygenInput } from "./OxygenInput";
import { isProfileCustom, isRiskyProfile } from "../components/ProfileUtils";



export const OXIMETER_ALARM_DELAY = {
    OXIMETER_DELAY_0: 0,
    OXIMETER_DELAY_1: 1 * 60,
    OXIMETER_DELAY_5: 5 * 60,
    OXIMETER_DELAY_15: 15 * 60,
    OXIMETER_DELAY_30: 30 * 60
}

export const TEST_MODE = {
    WARMUP: "START IN",
    DURATION: "TIME LEFT",
    RECOVERY: "RECOVERY",
}

export const PRO_MODE = {
    AUTO: "AUTO",
    MAN: "MAN",
    TEST: "TEST",
}
export const INCREMENT_MODE = {
    SMALL: "SMALL",
    LARGE: "LARGE"
}

export const FLOW_RESPONSE = {
    20: "NORMAL",
    100: "FAST"
}


const defaultProfiles = [
    { name: "COPD", minSpO2: 88, maxSpO2: 92, minO2: 0.0, maxO2: 6.0, initialO2: 2.0, minPulse: 45, maxPulse: 130, flowResponse: 20 },
    { name: "HYPOXEMIA", minSpO2: 94, maxSpO2: 98, minO2: 0.0, maxO2: 15.0, initialO2: 5.0, minPulse: 45, maxPulse: 130, flowResponse: 20 },
    { name: "ACTIVITY", minSpO2: 90, maxSpO2: 94, minO2: 0.0, maxO2: 15.0, initialO2: 5.0, minPulse: 45, maxPulse: 150, flowResponse: 100 },
]

const PrecisionManualModeLevels = [0, 2, 10, 15, 20];
const PrecisionManualModeIncrements = [0.1, 0.5, 1, 5];
const ManualModeLevels = [0, 10, 15, 20];
const ManualModeIncrements = [0.5, 1, 5];

const patientId = 1;
const patientName = "UNSET";
const location = "UNSET";
const oximeterSignalAlarmDelay = OXIMETER_ALARM_DELAY.OXIMETER_DELAY_30;
const profile = { id: 1, name: "COPD", minSpO2: 88, maxSpO2: 92, minO2: 0.0, maxO2: 6.0, initialO2: 2.0, minPulse: 45, maxPulse: 130, flowResponse: 20 }

export let defaultSetup = {
    patient: {
        patientId: patientId,
        patientName: patientName
    },
    location: location,
    oximeterSignalAlarmDelay: oximeterSignalAlarmDelay,
    profile: profile,
    defaultProfiles: defaultProfiles
}

class ProReproductor {
    constructor() {

    }
}

class ProSimulator {
    constructor(patient, location, oximeterSignalAlarmDelay, profile, defaultProfiles) {

        this.isRunning = false;
        this.isStarting = false;
        this.mute = { isMuted: false, duration: 2 * 60 };
        this.turnOffTimer = { isOn: false, duration: 3, interval: null, time: 3 }
        this.defaultProfiles = [
            { id: 1, name: "COPD", minSpO2: 88, maxSpO2: 92, minO2: 0.0, maxO2: 6.0, initialO2: 2.0, minPulse: 45, maxPulse: 130, flowResponse: 20 },
            { id: 2, name: "HYPOXEMIA", minSpO2: 94, maxSpO2: 98, minO2: 0.0, maxO2: 15.0, initialO2: 5.0, minPulse: 45, maxPulse: 130, flowResponse: 20 },
            { id: 3, name: "ACTIVITY", minSpO2: 90, maxSpO2: 94, minO2: 0.0, maxO2: 15.0, initialO2: 5.0, minPulse: 45, maxPulse: 150, flowResponse: 100 },
        ]
        this.pin = { master: "1234", usb: "1111" };
        this.deviceTime = new Date()
        this.adminPIN = false;
        this.counter = 0;
        this.oximeterCounter = 0;
        this.patient = patient;
        this.location = location;
        this.listeners = new Set();
        this.oximeterSignalAlarmDelay = oximeterSignalAlarmDelay;
        this.profile = profile;
        this.oxygenOutput = this.profile.initialO2;
        this.alarms = [];

        this.mode = PRO_MODE.AUTO
        this.incrementMode = INCREMENT_MODE.LARGE
        this.battery = new ProBattery()
        this.oximeter = new OximeterSimulator()
        this.oxygenInput = new OxygenInput()
        this.formating = { isFormating: false, duration: 10, interval: null, time: 10 }
        this.pauseScreenValues = { type: "Vitals", delay: 10 * 1000 * 60, isOn: false, timeout: null, position: { x: 0, y: 0, update: 60 } }
        this.data = []



        this.muteAlarms = () => {
            this.mute.isMuted = true
            this.mute.time = this.mute.duration
            clearInterval(this.mute.timeout)
            this.mute.timeout = setInterval(() => {
                this.mute.time = this.mute.time - 1
                if (this.mute.time < 1) {
                    this.mute.isMuted = false
                    clearInterval(this.mute.timeout)
                }

            }, 1000)
        }




        this.start = this.start.bind(this)
        this.stop = this.stop.bind(this)
        this.setPatient = this.setPatient.bind(this)
        this.saveToLocalStorage = this.saveToLocalStorage.bind(this)
        this.subscribe = this.subscribe.bind(this)
        this.unsubscribeAll = this.unsubscribeAll.bind(this)
        this.generateProData = this.generateProData.bind(this)
        this.generateFlowAlarms = this.generateFlowAlarms.bind(this)
        this.generateBatteryAlarms = this.generateBatteryAlarms.bind(this)
        this.generatePulseAlarms = this.generatePulseAlarms.bind(this)
        this.generateSpO2Alarms = this.generateSpO2Alarms.bind(this)
        this.adjustOxygenOutput = this.adjustOxygenOutput.bind(this)
        this.increaseOxygenOutput = this.increaseOxygenOutput.bind(this)
        this.decreaseOxygenOutput = this.decreaseOxygenOutput.bind(this)
        this.currentStatus = this.currentStatus.bind(this)
        this.turnOn = this.turnOn.bind(this)
        this.turnOff = this.turnOff.bind(this)
        this.startTurnOff = this.startTurnOff.bind(this)
        this.stopTurnOff = this.stopTurnOff.bind(this)
        this.setMode = this.setMode.bind(this)
        this.switchMode = this.switchMode.bind(this)
        this.switchIncrementMode = this.switchIncrementMode.bind(this)
        this.increaseOxygen = this.increaseOxygen.bind(this)
        this.decreaseOxygen = this.decreaseOxygen.bind(this)
        this.setLocation = this.setLocation.bind(this)
        this.setOximeterSignalAlarmDelay = this.setOximeterSignalAlarmDelay.bind(this)
        this.setProfile = this.setProfile.bind(this)
        this.setPatient = this.setPatient.bind(this)
        this.format = this.format.bind(this)
        this.setUSBPin = this.setUSBPin.bind(this)
        this.setMasterPin = this.setMasterPin.bind(this)
        this.switchAdminPIN = this.switchAdminPIN.bind(this)
        this.pauseScreen = this.pauseScreen.bind(this)
        this.setPauseScreenDelay = this.setPauseScreenDelay.bind(this)
        this.setPauseScreenType = this.setPauseScreenType.bind(this)
        this.isPauseScreen = this.isPauseScreen.bind(this)
        this.setDeviceTime = this.setDeviceTime.bind(this)
        this.addTreatmentProfile = this.addTreatmentProfile.bind(this)
        this.modifyTreatmentProfile = this.modifyTreatmentProfile.bind(this)
        this.removeTreatmentProfile = this.removeTreatmentProfile.bind(this)
        this.setTestMode = this.setTestMode.bind(this)
        this.endTest = this.endTest.bind(this)
        this.testVerify = this.testVerify.bind(this)

        this.oximeterUpdate = () => {
            this._fireChangeEvent();
            this.oximeterCounter = 0;
        }
        this.oxygenInputUpdate = () => {
            this._fireChangeEvent();
        }
        this.batteryInputUpdate = () => {
            this._fireChangeEvent();
        }


        // start the tick loop

    }
    incrementTime() {
        try {
            this.deviceTime.setSeconds(this.deviceTime.getSeconds() + 1);
        } catch (e) {
            this.deviceTime = new Date()
        }
    }

    testVerify() {
        if(this.testSetup==null||this.testSetup.times==null){
            if(this.mode==PRO_MODE.TEST){
                this.mode=PRO_MODE.AUTO
            } 
            return;
        }
        if (this.testSetup.times.warmup <= 0
            && this.testSetup.times.duration <= 0
            && this.testSetup.times.recovery <= 0
        ) {
            if(this.mode==PRO_MODE.TEST){
                this.mode=PRO_MODE.AUTO
            }
        }
        if(this.testSetup.timer==null){
            this.startTestTimer()
        }
    }

    setTestMode(test) {
        this.testSetup = {
            savedProfile: this.profile,
            testProfile: test.treatmentProfile,
            name: test.testProfile.name,
            //start in - 90:00 Time left - 00:00  Recovery - 00:00
            part: TEST_MODE.WARMUP,
            timeLeft: 0,
            times: {
                warmup: test.testProfile.warmup,
                duration: test.testProfile.duration,
                recovery: test.testProfile.recovery,
            },
            timer: null,

        }
        this.setProfile(this.testSetup.testProfile)
        this.mode = PRO_MODE.TEST

    }
    startTestTimer(){
        this.testSetup.timer = setInterval(() => {
            if (this?.testSetup?.times?.warmup > 0) {
                this.testSetup.times.warmup--
                this.testSetup.part = TEST_MODE.WARMUP
                this.testSetup.timeLeft = this.testSetup.times.warmup
            }
            else if (this?.testSetup?.times?.duration > 0) {
                this.testSetup.times.duration--
                this.testSetup.part = TEST_MODE.DURATION
                this.testSetup.timeLeft = this.testSetup.times.duration
            }
            else if (this?.testSetup?.times?.recovery > 0) {
                this.testSetup.times.recovery--
                this.testSetup.part = TEST_MODE.RECOVERY
                this.testSetup.timeLeft = this.testSetup.times.recovery
            }
            else {
                clearInterval(this.testSetup.timer)
                this.setProfile(this.testSetup.savedProfile)
                this.mode = PRO_MODE.AUTO
                this.testSetup = null
            }
        }, 1000)
    }
    endTest() {
        this.mode = PRO_MODE.AUTO
        clearInterval(this.testSetup.timer)
        this.setProfile(this.testSetup.savedProfile)
        this.testSetup = null
    }


    markTreatmentCustom() {
        let defaultProfile = this.defaultProfiles.find(defaltP => defaltP.id == this.profile.id)
        if (defaultProfile == undefined) {
            this.profile.isCustom = true
        }
        else if (isProfileCustom(defaultProfile, this.profile)) {
            this.profile.isCustom = true
        }
        if (isRiskyProfile(this.profile)) {
            this.profile.isRisky = true
        }
    }

    addTreatmentProfile(profile) {
        this.defaultProfiles.push(profile)
        this.markTreatmentCustom()
    }

    modifyTreatmentProfile(profile) {
        let index = this.defaultProfiles.findIndex(item => item.id == profile.id)
        this.defaultProfiles[index] = profile
        this.markTreatmentCustom()
    }
    removeTreatmentProfile(profile) {
        const updatedArray = this.defaultProfiles.filter(obj => obj.id !== profile.id);
        this.defaultProfiles = updatedArray;
        this.markTreatmentCustom()
    }

    setDeviceTime(time) {
        this.deviceTime = time
    }
    setPauseScreenDelay(delay) {
        this.adjustPositionPauseScreen()
        this.pauseScreenValues.delay = delay;
        this.pauseScreen();
        this._fireChangeEvent()
    }
    setPauseScreenType(type) {
        this.adjustPositionPauseScreen()
        this.pauseScreenValues.type = type;
        this.pauseScreen();
        this._fireChangeEvent()
    }
    turnOn() {
        if (this.isRunning) {
            return;
        }
        setTimeout(()=>{

            this.isStarting = false;
        },2000)
        this.isRunning = true;
        this.data.push({ o2: -1, spo2: -1, pulse: -1, profileChange: false, patientChange: false, turnOnOff: true, timestamp: new Date() })
        this.setMode(PRO_MODE.AUTO)
        this.oxygenOutput = 0.0
        this._fireChangeEvent()
    }
    pauseScreen() {
        this.pauseScreenValues.isOn = false
        this._fireChangeEvent()
        clearTimeout(this.pauseScreenValues.timeout)
        this.pauseScreenValues.timeout = setTimeout(() => {
            if (!this.isRunning) {
                clearTimeout(this.pauseScreenValues.timeout)
                return;
            }
            this.pauseScreenValues.isOn = true
            this._fireChangeEvent({ type: "navigate", route: "/home" });
            clearTimeout(this.pauseScreenValues.timeout)
        }, this.pauseScreenValues.delay)
    }
    startTurnOff() {
        this.turnOffTimer.isOn = true
        this.turnOffTimer.time = this.turnOffTimer?.duration
        clearInterval(this.turnOffTimer.interval)
        this.turnOffTimer.interval = setInterval(() => {
            this.turnOffTimer.time = this.turnOffTimer.time - 1
            if (this.turnOffTimer.time < 0) {
                this.turnOffTimer.isOn = false
                clearInterval(this.turnOffTimer.timeout)
                this.turnOff()
            }

        }, 1000)
    }
    stopTurnOff() {
        this.turnOffTimer.isOn = false
        this.turnOffTimer.time = this.turnOffTimer?.duration
        clearInterval(this.turnOffTimer.interval)
    }


    turnOff() {
        if (!this.isRunning) {
            return;
        }
        this.isRunning = false;
        clearInterval(this.mute.timeout)
        this.mute.isMuted = false
        this._fireChangeEvent()
        this.data.push({ o2: -1, spo2: -1, pulse: -1, profileChange: false, patientChange: false, turnOnOff: true, timestamp: new Date() })
    }
    saveToLocalStorage() {
        localStorage.setItem("proSimulator", JSON.stringify(this));
    }

    isPauseScreen() {
        return this.pauseScreenValues.isOn
    }

    adjustPositionPauseScreen() {
        if (this.pauseScreenValues.position.update < 0) {
            this.pauseScreenValues.position.update = 3
            if (this.pauseScreenValues.type == "Logo") {
                let x = Math.random() * 940;
                let y = Math.random() * 450;
                if (x > 940 - 200) {
                    x = 940 - 200
                }
                if (y > 450 - 75) {
                    y = 450 - 75
                }
                this.pauseScreenValues.position.x = x
                this.pauseScreenValues.position.y = y
            }
            else if (this.pauseScreenValues.type == "Vitals") {
                let x = Math.random() * 940;
                let y = Math.random() * 250;
                if (x > 940 - 500) {
                    x = 940 - 540
                }
                if (y > 450 - 80) {
                    y = 450 - 90
                }
                this.pauseScreenValues.position.x = x
                this.pauseScreenValues.position.y = y
            }


        }
        this.pauseScreenValues.position.update--
    }

    saveData() {
        if (!this.isRunning) {
            this.data.push({ o2: null, spo2: null, pulse: null, profileChange: false, patientChange: false, turnOnOff: false, timestamp: new Date() })
            return;
        }
        if (this.oximeter.state === OximeterState.SIGNAL_DATA) {
            this.data.push({ o2: this.oxygenOutput, spo2: this.oximeter.spo2, pulse: this.oximeter.pulse, timestamp: new Date() })
        }
        else {
            this.data.push({ o2: this.oxygenOutput, spo2: -1, pulse: -1, timestamp: new Date() })
        }

    }
    start() {

        this.oximeter?.subscribe(this.oximeterUpdate)

        this.oxygenInput.subscribe(this.oxygenInputUpdate)
        this.battery.subscribe(this.batteryInputUpdate)

        this.runner = setInterval(() => {
            this.saveData()
            this.incrementTime()
            this.battery?.autoDecayCharge();
            this.incrementOximeterCounter()
            this.adjustPositionPauseScreen();
            this.generateBatteryAlarms();
            this.generateOximeterAlarms()
            this.generateFlowAlarms();
            if (this.mode === PRO_MODE.AUTO) {

                if (this.oximeter.state === OximeterState.SIGNAL_DATA) {
                    this.generateSpO2Alarms();
                    this.generatePulseAlarms();
                    if (this.counter % 3 == 0) {
                        this.adjustOxygenOutput();
                    }

                }
            }
            else if(this.mode === PRO_MODE.TEST){
                this.testVerify()
            }

            this._fireChangeEvent()
            // this.currentStatus()
            this.counter++
            this.saveToLocalStorage()

        }, 1000);
    }


    stop() {
        clearInterval(this.mute.timeout)
        this.mute.isMuted = false
        clearInterval(this.runner);
        clearInterval(this?.testSetup?.timer);
        this.testSetup=null;
        this._fireChangeEvent()
        this.oximeter.unsubscribe(this.oximeterUpdate)
        this.oxygenInput.unsubscribe(this.oxygenInputUpdate)
    }

    format() {
        this.data = []
        this.formating.isFormating = true
        this.formating.time = this.formating?.duration
        clearInterval(this.formating.interval)
        this.formating.interval = setInterval(() => {
            this.formating.time = this.formating.time - 1
            if (this.formating.time < 0) {
                this.formating.isFormating = false
                clearInterval(this.formating.interval)
            }

        }, 1000)
    }

    setUSBPin(pin) {
        this.pin.usb = pin
    }

    switchAdminPIN() {
        this.adminPIN = !this.adminPIN
        this._fireChangeEvent()
    }
    setMasterPin(pin) {
        this.pin.master = pin
    }


    subscribe(listener) {
        this.listeners.add(listener);
    }

    unsubscribeAll(listener) {
        this.listeners = new Set()
    }
    unsubscribe(listener) {
        this.listeners.delete(listener);
    }

    incrementOximeterCounter() {
        this.oximeterCounter += 1;
    }
    generateProData() {
        let data = {}
        data.patient = this.patient;
        data.location = this.location;
        data.oximeterSignalAlarmDelay = this.oximeterSignalAlarmDelay;
        data.profile = this.profile;
        data.oxygenOutput = this.oxygenOutput;
        data.spo2 = this.oximeter.spo2;
        data.pulse = this.oximeter.pulse;
        data.alarms = this.alarms;
        data.listeners = this.listeners;
        data.mode = this.mode
        return data;
    }

    _fireChangeEvent() {
        for (let listener of this.listeners) {
            listener(this.generateProData());
        }
    }

    _fireChangeEvent(navigate) {
        for (let listener of this.listeners) {
            listener(navigate);
        }
    }



    generateOximeterAlarms() {
        let newAlarm;
        if (this.oximeter.state == OximeterState.NOT_CONNECTED) {
            newAlarm = generateAlarm(ALARM_CODE.OXIMETER_NOT_CONNECTED)
        }
        else if (this.oximeter.state == OximeterState.NO_SIGNAL) {
            this.removeAlarmByCode(ALARM_CODE.OXIMETER_NOT_CONNECTED)
            if (this.oximeterCounter > this.oximeterSignalAlarmDelay || this.oximeterCounter > OXIMETER_ALARM_DELAY.OXIMETER_DELAY_30) {
                newAlarm = generateAlarm(ALARM_CODE.OXIMETER_NO_SIGNAL)
            }
            else if (this.oximeterCounter > OXIMETER_ALARM_DELAY.OXIMETER_DELAY_30) {
                newAlarm = generateAlarm(ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_30)
                if (!this.alarmExists(newAlarm)) {
                    console.log("play a sound 30")
                }
            }
            else if (this.oximeterCounter > OXIMETER_ALARM_DELAY.OXIMETER_DELAY_15) {
                newAlarm = generateAlarm(ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_15)
                if (!this.alarmExists(newAlarm)) {
                    console.log("play a sound 15")
                }
            }
            else if (this.oximeterCounter > OXIMETER_ALARM_DELAY.OXIMETER_DELAY_5) {
                newAlarm = generateAlarm(ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_5)
                if (!this.alarmExists(newAlarm)) {
                    console.log("play a sound 5")
                }
            }
            else if (this.oximeterCounter > OXIMETER_ALARM_DELAY.OXIMETER_DELAY_1) {
                newAlarm = generateAlarm(ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_1)
                if (!this.alarmExists(newAlarm)) {
                    console.log("play a sound 1")
                }
            }
            else if (this.oximeterCounter > OXIMETER_ALARM_DELAY.OXIMETER_DELAY_0) {
                newAlarm = generateAlarm(ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_0)
                if (!this.alarmExists(newAlarm)) {
                    console.log("play a sound")
                }
            }





        } else {
            this.alarms = this.alarms.filter(alarm => {
                if (alarm.alarmCode == ALARM_CODE.OXIMETER_NOT_CONNECTED ||
                    alarm.alarmCode == ALARM_CODE.OXIMETER_NO_SIGNAL ||
                    alarm.alarmCode == ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_0 ||
                    alarm.alarmCode == ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_1 ||
                    alarm.alarmCode == ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_5 ||
                    alarm.alarmCode == ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_15 ||
                    alarm.alarmCode == ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_30) {
                    return false
                }
                return true
            });
        }

        this.addAlarm(newAlarm)
    }

    alarmExists(alarm) {
        return this.alarms.some(obj => obj.alarmCode === alarm.alarmCode);
    }


    generateFlowAlarms() {
        let newAlarm;
        if (this.oxygenOutput > this.oxygenInput.value) {
            newAlarm = generateAlarm(ALARM_CODE.O2_FLOW_ERROR)
        } else {
            this.alarms = this.alarms.filter(alarm => {
                if (alarm.alarmCode == ALARM_CODE.O2_FLOW_ERROR) {
                    return false
                }
                return true
            });
        }
        this.addAlarm(newAlarm)
    }
    generateBatteryAlarms() {
        let newAlarm;
        if (this.battery.charge <= 10 || this.battery.chargeState == BatteryChargeState.FAIL) {
            newAlarm = generateAlarm(ALARM_CODE.BATTERY_FAIL)
        } else if (this.battery.charge <= 40) {
            newAlarm = generateAlarm(ALARM_CODE.BATTERY_CRITICAL)
        } else if (this.battery.charge <= 60) {
            newAlarm = generateAlarm(ALARM_CODE.BATTERY_WARNING)
        }
        this.alarms = this.alarms.filter(alarm => {
            if (alarm.alarmCode == ALARM_CODE.BATTERY_FAIL || alarm.alarmCode == ALARM_CODE.BATTERY_CRITICAL || alarm.alarmCode == ALARM_CODE.BATTERY_WARNING) {
                return false
            }
            return true
        });
        this.addAlarm(newAlarm)
    }

    generatePulseAlarms() {
        let newAlarm;
        if (this.oximeter.pulse < 40) {
            //generate ALARM_CODE_CRITICALLY_LOW_PULSE 
            newAlarm = generateAlarm(ALARM_CODE.CRITICALLY_LOW_PULSE)
        }
        else if (this.oximeter.pulse > 200) {
            // generate ALARM_CODE_CRITICALLY_HIGH_PULSE 
            newAlarm = generateAlarm(ALARM_CODE.CRITICALLY_HIGH_PULSE)
        }
        else if (this.oximeter.pulse < this.profile.minPulse) {
            // generate ALARM_CODE_LOW_PULSE  
            newAlarm = generateAlarm(ALARM_CODE.LOW_PULSE)
        }
        else if (this.oximeter.pulse > this.profile.maxPulse) {
            // generate ALARM_CODE_HIGH_PULSE   
            newAlarm = generateAlarm(ALARM_CODE.HIGH_PULSE)
        }
        this.alarms = this.alarms.filter(alarm => {
            if (alarm.alarmCode == ALARM_CODE.CRITICALLY_LOW_PULSE || alarm.alarmCode == ALARM_CODE.CRITICALLY_HIGH_PULSE || alarm.alarmCode == ALARM_CODE.LOW_PULSE || alarm.alarmCode == ALARM_CODE.HIGH_PULSE) {
                return false
            }
            return true
        });
        if (newAlarm == undefined) { return }
        this.addAlarm(newAlarm)
    }

    generateSpO2Alarms() {
        let newAlarm;
        if (this.oximeter.spo2 < 80) {
            //genereate ALARM_CODE_CRITICALLY_LOW_SPO2 red SPO2 and min value
            newAlarm = generateAlarm(ALARM_CODE.CRITICALLY_LOW_SPO2)
        }
        else if (this.oximeter.spo2 < this.profile.minSpO2 - 3) {
            //generate ALARM_CODE_VERY_LOW_SPO2 orange 
            newAlarm = generateAlarm(ALARM_CODE.VERY_LOW_SPO2)
        }
        else if (this.oximeter.spo2 < this.profile.minSpO2) {
            //generate ALARM_CODE_LOW_SPO2 yellow
            newAlarm = generateAlarm(ALARM_CODE.LOW_SPO2)
        }
        else if (this.oximeter.spo2 > this.profile.maxSpO2) {
            //generate ALARM_CODE_HIGH_SPO2   yellow
            newAlarm = generateAlarm(ALARM_CODE.HIGH_SPO2)
        }
        this.alarms = this.alarms.filter(alarm => {
            if (alarm.alarmCode == ALARM_CODE.CRITICALLY_LOW_SPO2 || alarm.alarmCode == ALARM_CODE.VERY_LOW_SPO2 || alarm.alarmCode == ALARM_CODE.LOW_SPO2 || alarm.alarmCode == ALARM_CODE.HIGH_SPO2) {
                return false
            }
            return true
        });
        if (newAlarm == undefined) { return }
        this.addAlarm(newAlarm)
    }


    addAlarm(newAlarm) {
        if (newAlarm == null) {
            return;
        }

        let exists = this.alarms.some(alarm => alarm.alarmCode === newAlarm.alarmCode)
        if (exists) {
            return;
        }
        let indexToRemove = -1;
        let lowerAlarms = findLowerAlarms(newAlarm);
        lowerAlarms.forEach(alarm => this.removeAlarmByCode(alarm))
        this.alarms.forEach((alarm, index) => {
            if (alarm.higherAlarmCode === newAlarm.alarmCode) {
                indexToRemove = index;
            }
        });

        if (indexToRemove >= 0) {
            this.alarms.splice(indexToRemove, 1);
        }

        this.alarms.push(newAlarm);

        return this.alarms;
    }
    removeAlarm(alarm) {
        const index = this.alarms.findIndex(a => a.alarmCode === alarm.alarmCode);
        if (index !== -1) {
            this.alarms.splice(index, 1);
        }
    }
    removeAlarmByCode(alarm) {
        const index = this.alarms.findIndex(a => a.alarmCode === alarm);
        if (index !== -1) {
            this.alarms.splice(index, 1);
        }
    }


    // setters with validation
    setPatient(patient) {

        if (patient.patientId.toString().length >= 10) {
            throw new Error('Patient ID must be less than 10-digit long number.');
        }
        if (patient.patientName.length > 10) {
            throw new Error('Patient name must be at most 10 characters long.');
        }
        this.patient = patient
        this.data = []
        this.data.push({ o2: this.oxygenOutput, spo2: this.oximeter.spo2, pulse: this.oximeter.pulse, profileChange: false, patientChange: true, turnOnOff: false, timestamp: new Date() })
    }

    setLocation(location) {
        if (location.length > 10) {
            throw new Error('Location must be at most 10 characters long.');
        }
        this.location = location;
        this._fireChangeEvent()
    }

    setOximeterSignalAlarmDelay(delay) {
        if (![OXIMETER_ALARM_DELAY.OXIMETER_DELAY_0,
        OXIMETER_ALARM_DELAY.OXIMETER_DELAY_1,
        OXIMETER_ALARM_DELAY.OXIMETER_DELAY_5,
        OXIMETER_ALARM_DELAY.OXIMETER_DELAY_15,
        OXIMETER_ALARM_DELAY.OXIMETER_DELAY_30].includes(delay)) {
            throw new Error('Oximeter signal alarm delay must be 0, 1, 5, 15 or 30 minutes.');
        }
        this.oximeterSignalAlarmDelay = delay;
        this._fireChangeEvent()
    }

    setProfile(profile) {
        if (profile.minSpO2 < 80 || profile.minSpO2 > 98 || profile.minSpO2 > (profile.maxSpO2 - 2)) {
            throw new Error('Invalid profile: minSpO2 must be between 80 and 98, and at most 2 less than maxSpO2.');
        }
        if (profile.maxSpO2 > 100 || profile.maxSpO2 < (profile.minSpO2 + 2)) {
            throw new Error('Invalid profile: maxSpO2 must be between 82 and 100, and at least 2 more than minSpO2.');
        }
        if (profile.initialO2 < 0 || profile.initialO2 > 15) {
            throw new Error('Invalid profile: initialO2 must be between 0 and 15.');
        }
        if (profile.minO2 < 0 || profile.minO2 > profile.initialO2) {
            throw new Error('Invalid profile: minO2 must be at most 1 less than initialO2.');
        }
        if (profile.maxO2 < profile.minO2) {
            throw new Error('Invalid profile: maxO2 must be at least equal to minO2.');
        }
        if (profile.minPulse < 40 || profile.minPulse > 80) {
            throw new Error('Invalid profile: minPulse must be between 40 and 80.');
        }
        if (profile.maxPulse < 100 || profile.maxPulse > 200) {
            throw new Error('Invalid profile: maxPulse must be between 100 and 200.');
        }
        if (profile.flowResponse < 0 || profile.flowResponse > 200) {
            throw new Error('Invalid profile: flowResponse must be between 0 and 200');
        }
        this.profile = profile;
        this.oxygenOutput = this.profile.initialO2;
        this.markTreatmentCustom()
        this.data.push({ o2: this.oxygenOutput, spo2: this.oximeter.spo2, pulse: this.oximeter.pulse, profileChange: true, patientChange: false, turnOnOff: false, timestamp: new Date() })
    }

    setSpo2(spo2) {
        this.oximeter.spo2 = spo2;
    }
    setPulse(pulse) {
        this.oximeter.pulse = pulse;
    }
    switchMode() {
        this.setMode(this.mode == PRO_MODE.AUTO ? PRO_MODE.MAN : PRO_MODE.AUTO)
    }

    setMode(mode) {
        this.mode = mode;
        if (mode === PRO_MODE.MAN) {
            this.oxygenOutput = this.roundOxygenOutput(this.oxygenOutput)
        }
        else if (mode === PRO_MODE.AUTO) {
            if (this.oxygenOutput > this.profile.maxO2) {
                this.oxygenOutput = this.profile.maxO2
            }
            else if (this.oxygenOutput < this.profile.minO2) {
                this.oxygenOutput = this.profile.minO2
            }
        }
        this._fireChangeEvent()
    }
    switchIncrementMode() {
        this.setIncrementStepMode(this.incrementMode == INCREMENT_MODE.LARGE ? INCREMENT_MODE.SMALL : INCREMENT_MODE.LARGE)
        this._fireChangeEvent()
    }

    setIncrementStepMode(mode) {
        this.incrementMode = mode;
    }

    adjustOxygenOutput() {
        if (this.oximeter.spo2 === null) {
            // do nothing if spo2 value is not set yet
            return;
        }
        if (this.oximeter.spo2 < this.profile.minSpO2) {
            this.increaseOxygenOutput();
        } else if (this.oximeter.spo2 > this.profile.minSpO2 + 1) {
            this.decreaseOxygenOutput();
        }
    }

    roundOxygenOutput(value) {

        let increment = 0
        for (let i = 0; i < ManualModeLevels.length; i++) {
            const levelStart = ManualModeLevels[i];
            const levelEnd = ManualModeLevels[i + 1];
            if (levelEnd === undefined || (this.oxygenOutput >= levelStart && this.oxygenOutput < levelEnd)) {
                increment = ManualModeIncrements[i];
                break;
            }
        }


        let roundedValue;
        const remainder = value % increment;

        if (remainder < increment / 2) {
            roundedValue = value - remainder;
        } else {
            roundedValue = value + (increment - remainder);
        }

        // round to nearest increment of `ManualModeIncrement`
        const incrementMultiple = Math.floor(roundedValue / increment);
        roundedValue = incrementMultiple * increment;

        // handle values above 15
        if (roundedValue > 15) {
            roundedValue = 15;
        }

        return roundedValue;
    }

    increaseOxygen() {
        if (this.incrementMode === INCREMENT_MODE.SMALL) {
            for (let i = 0; i < PrecisionManualModeLevels.length; i++) {
                const levelStart = PrecisionManualModeLevels[i];
                const levelEnd = PrecisionManualModeLevels[i + 1];
                if (levelEnd === undefined || (this.oxygenOutput >= levelStart && this.oxygenOutput < levelEnd)) {
                    const newOutput = this.oxygenOutput + PrecisionManualModeIncrements[i];
                    this.oxygenOutput = newOutput <= 20 ? newOutput : 20;
                    break;
                }
            }
        } else if (this.incrementMode === INCREMENT_MODE.LARGE) {
            for (let i = 0; i < ManualModeLevels.length; i++) {
                const levelStart = ManualModeLevels[i];
                const levelEnd = ManualModeLevels[i + 1];
                if (levelEnd === undefined || (this.oxygenOutput >= levelStart && this.oxygenOutput < levelEnd)) {
                    const newOutput = this.oxygenOutput + ManualModeIncrements[i];
                    this.oxygenOutput = newOutput <= 20 ? newOutput : 20;
                    break;
                }
            }
        }
        this._fireChangeEvent()
    }

    decreaseOxygen() {
        if (this.incrementMode === INCREMENT_MODE.SMALL) {
            for (let i = 0; i < ManualModeLevels.length; i++) {
                const currentValue = PrecisionManualModeLevels[i];
                const nextValue = PrecisionManualModeLevels[i + 1];
                if (nextValue !== undefined && currentValue < this.oxygenOutput && this.oxygenOutput <= nextValue) {
                    // value is between currentValue and nextValue
                    const newOutput = this.oxygenOutput - PrecisionManualModeIncrements[i];
                    this.oxygenOutput = newOutput < 0 ? 0 : newOutput;
                    break;
                } else if (nextValue === undefined && currentValue < this.oxygenOutput) {
                    // value is greater than or equal to currentValue
                    const newOutput = this.oxygenOutput - PrecisionManualModeIncrements[i];
                    this.oxygenOutput = newOutput < 0 ? 0 : newOutput;
                    break;
                }
            }
        }
        else if (this.incrementMode === INCREMENT_MODE.LARGE) {
            const ManualModeLevels = [0, 10, 15, 20];
            const ManualModeIncrements = [0.5, 1, 5];
            for (let i = 0; i < ManualModeLevels.length; i++) {
                const currentValue = ManualModeLevels[i];
                const nextValue = ManualModeLevels[i + 1];
                if (nextValue !== undefined && currentValue < this.oxygenOutput && this.oxygenOutput <= nextValue) {
                    // value is between currentValue and nextValue
                    const newOutput = this.oxygenOutput - ManualModeIncrements[i];
                    this.oxygenOutput = newOutput < 0 ? 0 : newOutput;
                    break;
                } else if (nextValue === undefined && currentValue < this.oxygenOutput) {
                    // value is greater than or equal to currentValue
                    const newOutput = this.oxygenOutput - ManualModeIncrements[i];
                    this.oxygenOutput = newOutput < 0 ? 0 : newOutput;
                    break;
                }
            }
        }
        this._fireChangeEvent()
    }
    increaseOxygenOutput() {
        const newOxygenOutput = this.oxygenOutput + this.getOxygenAdjustmentPower();
        if (newOxygenOutput <= this.profile.maxO2) {
            this.oxygenOutput = parseFloat(newOxygenOutput.toFixed(2))
        }
    }

    decreaseOxygenOutput() {
        const newOxygenOutput = this.oxygenOutput - this.getOxygenAdjustmentPower();
        if (newOxygenOutput >= this.profile.minO2) {
            this.oxygenOutput = parseFloat(newOxygenOutput.toFixed(2));
        }
        else if (newOxygenOutput <= 0) {
            this.oxygenOutput = 0.00;
        }
    }

    getOxygenAdjustmentPower() {
        let difference = this.oximeter.spo2 - this.profile.minSpO2
        difference = difference < 0 ? difference * -1 : difference

        if (difference > 5) {
            return 0.5
        }
        if (difference > 2) {
            return 0.2
        }
        return 0.1
    }

    currentStatus() {
        console.log({
            proStatus: {
                listeners: this.listeners,
                mode: this.mode,
                oxygen: this.oxygenOutput,
                spo2: this.spo2,
                pulse: this.oximeter.pulse,
                alarms: this.alarms,
                battery: JSON.stringify(this.battery),
                oximeterCounter: this.oximeterCounter
            }
        })
    }
}
export default ProSimulator
