import { MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { OximeterState } from "../ProSimulator/OximeterSimulator";
import { useEffect, useState } from "react";
import { colors } from "../Style";
import { Translate } from "react-i18nify";
import { SideBarTitle } from "./UI/SideBarTitle";

export const Oximeter = (props) => {
  const [boxStatus, setBoxStatus] = useState(false);
  // const [oximeterState, setOximeterState] = useState(props?.oximeter?.state);

  let oximeterState = props?.oximeter?.state;
  // useEffect(() => {
  //   setOximeterState(props?.oximeter?.state);
  // }, []);

  // useEffect(() => {
  //   props?.oximeter?.setState(oximeterState);
  // }, [oximeterState]);

  const setOximeterState = (value) => {
    props?.oximeter?.setState(value);
  };

  const renderControls = () => {
    return (
      <Box>
        <SideBarTitle onClick={() => toggleBox()}>
          <Typography sx={{ color: colors.TEXT_COLOR }}>
            <Translate value="OximeterState" />
          </Typography>
        </SideBarTitle>

        <Select
          onChange={(event) => {
            setOximeterState(event.target.value);
          }}
          value={props?.oximeter?.state}
          sx={{mt:1,width: "75%", height: "20px", background: "white" }}
        >
          <MenuItem value={OximeterState.NOT_CONNECTED}>
            {OximeterState.NOT_CONNECTED}
          </MenuItem>
          <MenuItem value={OximeterState.NO_SIGNAL}>
            {OximeterState.NO_SIGNAL}
          </MenuItem>
          <MenuItem value={OximeterState.SIGNAL_DATA}>
            {OximeterState.SIGNAL_DATA}
          </MenuItem>
        </Select>
        <Box
          sx={{
            visibility:
              oximeterState == OximeterState.SIGNAL_DATA ? "visible" : "hidden",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Typography sx={{ color: colors.TEXT_COLOR }}>
            <Translate value="SpO2" />:{props.oximeter.spo2}%
          </Typography>
          <Box
            sx={{
              ml: 1,
              display: "flex",
              width: "100%",
              gap: 1,
            }}
          >
            <Box
              onClick={props.oximeter?.decrementSpO2}
              sx={{
                cursor: "pointer",
                width: "25px",
                height: "25px",
                background: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ userSelect: "none", color: "black" }}>
                -
              </Typography>
            </Box>
            <Box
              onClick={props.oximeter?.incrementSpO2}
              sx={{
                cursor: "pointer",
                width: "25px",
                height: "25px",
                background: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ userSelect: "none", color: "black" }}>
                +
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            visibility:
              oximeterState == OximeterState.SIGNAL_DATA ? "visible" : "hidden",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Typography sx={{ color: colors.TEXT_COLOR }}>
            <Translate value="Pulse" />:{props.oximeter.pulse}
            <Translate value="b/min" />
          </Typography>
          <Box
            sx={{
              ml: 1,
              display: "flex",
              width: "100%",
              gap: 1,
            }}
          >
            <Box
              onClick={props.oximeter?.decrementPulse}
              sx={{
                cursor: "pointer",
                width: "25px",
                height: "25px",
                background: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ userSelect: "none", color: "black" }}>
                -
              </Typography>
            </Box>
            <Box
              onClick={props.oximeter?.incrementPulse}
              sx={{
                cursor: "pointer",
                width: "25px",
                height: "25px",
                background: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ userSelect: "none", color: "black" }}>
                +
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };
  const toggleBox = () => {
    setBoxStatus((boxStatus) => !boxStatus);
  };
  return (
    <Box sx={{}}>
      <Box
        onClick={toggleBox}
        sx={{ width: "50px", height: "100%", position: "absolute", zIndex: 10 }}
      ></Box>
      <Box
        sx={{
          left: `calc(0% - ${!boxStatus ? "0" : "200"}px)`,
          width: `${!boxStatus ? "50" : "250"}px`,
          height: "160px",
          background: "black",
          p: 1,
          border: "1px solid white",
          position: "relative",
        }}
      >
        {boxStatus ? renderControls() : null}
      </Box>
      <Typography
        variant="h4"
        sx={{
          userSelect: "none",
          position: "absolute",
          transform: "rotate(90deg)",
          top: "10px",
          left: "50px",
          transformOrigin: "0 0",
          color: colors.TEXT_COLOR,
        }}
      >
        <Translate value="Oximeter" />
      </Typography>
    </Box>
  );
};
