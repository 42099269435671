import { colors } from "../Style";

export const BatteryState = {
  UNKNOWN: "UNKNOWN",
  OK: "OK",
  WARNING: "WARNING",
  CRITICAL: "CRITICAL",
  FAIL: "FAIL"
};

export const BatteryChargeState = {
  IDLE: "IDLE",
  CHARGING: "CHARGING",
  FAIL: "FAIL",
  NO_POWER: "NO_POWER",
  NO_IDEA: "NO_IDEA"
};

export const getBatteryColor = (state) => {
  switch (state) {
    case BatteryState.OK:
      return colors.O2
    case BatteryState.WARNING:
      return colors.COLORS_ALARM_MEDIUM
    case BatteryState.CRITICAL:
      return colors.COLORS_ALARM_HIGH
    case BatteryState.FAIL:
      return colors.COLORS_ALARM_HIGH
    case BatteryState.UNKNOWN:
      return colors.COLORS_ALARM_HIGH
  }
}
export class ProBattery {
  constructor(initialCharge = 100, decayRate = 0.1) {
    this.charge = initialCharge;
    this.decayRate = decayRate;
    this.listeners = new Set();
    this.state = BatteryState.OK;
    this.chargeState = BatteryChargeState.NO_IDEA;
    this.decrease = this.decrease.bind(this)
    this.increase = this.increase.bind(this)
    this.setChargeState = this.setChargeState.bind(this)
    this.timeoutChanger = null
    this.timeoutChanger = setTimeout(() => {
      this.setChargeState(BatteryChargeState.IDLE);

    }, 10000)
  }
  decrease() {
    this.charge -= 1;
    if (this.charge < 0) {
      this.charge = 0;
    }
    this.updateState();
  }

  increase() {
    this.charge += 1;
    if (this.charge > 100) {
      this.charge = 100;
    }
    this.updateState();
  }
  autoDecayCharge() {
    if (this.chargeState == BatteryChargeState.NO_IDEA || this.chargeState == BatteryChargeState.FAIL && this.chargeState != BatteryChargeState.NO_POWER) {
      this.updateState();
      return;
    }
    if (this.chargeState == BatteryChargeState.IDLE && this.charge < 97 && this.chargeState != BatteryChargeState.NO_POWER) {
      this.setChargeState(BatteryChargeState.CHARGING)
    }
    if (this.chargeState == BatteryChargeState.CHARGING && this.charge > 99) {
      this.setChargeState(BatteryChargeState.IDLE)
    }

    if (this.chargeState == BatteryChargeState.CHARGING) {
      this.charge += this.decayRate * 5
    }
    else {
      this.charge -= this.decayRate;
    }

    if (this.charge < 0) {
      this.charge = 0;
    }
    if (this.charge > 100) {
      this.charge = 100
    }
    this.updateState();
  }

  updateState() {
    if (this.charge <= 10) {
      this.state = BatteryState.FAIL;
    } else if (this.charge <= 40) {
      this.state = BatteryState.CRITICAL;
    } else if (this.charge <= 60) {
      this.state = BatteryState.WARNING;
    } else {
      this.state = BatteryState.OK;
    }
    this._fireChangeEvent()
  }

  setChargeState(chargeState) {
    clearTimeout(this.timeoutChanger)
    if (this.chargeState == BatteryChargeState.FAIL && chargeState == BatteryChargeState.NO_POWER) {
      this.chargeState = BatteryChargeState.NO_IDEA
      this.timeoutChanger = setTimeout(() => {
        this.chargeState = BatteryChargeState.NO_POWER;
        this.timeoutChanger = null;
        this._fireChangeEvent()
      }, 1000)
      return;
    }
    this.chargeState = chargeState;
    this._fireChangeEvent()
  }


  subscribe(listener) {
    this.listeners.add(listener);
  }

  unsubscribe(listener) {
    this.listeners.delete(listener);
  }

  _fireChangeEvent() {
    this.listeners.forEach(listener => listener("test"));
  }


}



