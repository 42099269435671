import { Typography, Box, Popper } from "@mui/material";
import { useEffect } from "react";
import { ItemMenu } from "../../components/UI/ItemMenu";
import { IonRouterOutlet } from "@ionic/react";
import { PopUpWrapper } from "../../components/PopUpWrapper";
import { useState } from "react";
import { useHistory, createSearchParams, useLocation } from "react-router-dom";
import { usePageState, useQuery } from "../../Utils";
import { StringInputMODE } from "../../components/UI/StringInputMenu";
import { StringInputMenu } from "../../components/UI/StringInputMenu";
import { GetWindowState } from "../../Utils";
import { defaultReducer } from "../../Utils";
import { SelectInputMenu } from "../../components/UI/SelectInputMenu";
import { OXIMETER_ALARM_DELAY } from "../../ProSimulator/ProSimulator";
import { SelectProfileMenu } from "../../components/UI/SelectProfileMenu";
import { Translate, translate } from "react-i18nify";
import { ConfirmRiskyProfile } from "../../components/UI/ConfirmRiskyProfile";
import { CustomizeProfile } from "../../components/UI/CustomizeProfile";
import { colors } from "../../Style";
export const Patient = (props) => {
  const history = useHistory();
  const location = useLocation();
  const state = GetWindowState();

  const defaultMenu = {
    LEFT: { label: "Back", route: "home" },
    UP: null,
    RIGHT: { label: "Select", route: "select", local: true },
    MIDDLE: null,
    DOWN: { label: "DOWN", route: "down", local: true },
  };

  const [popup, setPopUp] = useState(null);
  const [pageState, setPageState] = usePageState(
    defaultReducer,
    state
      ? state
      : {
          menu: { ...defaultMenu },
          window: null,
          oximeterSignalAlarmDelay: props.pro.oximeterSignalAlarmDelay,
          patient: { ...props.pro.patient },
          location: props.pro.location,
          activeItemIndex: 0,
          menuItems: generateMenuItems(props),
        }
  );

  const handleDown = (navigator, action, menu) => {
    if (pageState.window != null) {
      return;
    }
    setPageState((prev) => {
      let prevIndex = prev.activeItemIndex;
      let menuItems = prev.menuItems;
      let newActiveIndex =
        prevIndex < menuItems.length - 1 ? prevIndex + 1 : prevIndex;
      return { ...prev, activeItemIndex: newActiveIndex };
    });
  };

  const handleLeft = (navigator, action, menu) => {
    if (pageState.window != null) {
      // if (menu[action.button].route == "NAVIGATE_EDIT")
      //   setPageState((prevState) => {
      //     return {
      //       ...prevState,
      //       window: { ...prevState.window, mode: StringInputMODE.MODE_EDIT },
      //     };
      //   });

      return;
    }
    setPageState((prevState) => {
      return {
        ...prevState,
        window: {
          name: prevState.menuItems[prevState.activeItemIndex].window,
        },
      };
    });
  };

  const handleRight = (navigator, action, menu) => {
    if (
      pageState.window != null ||
      pageState.window?.name != null ||
      pageState.window?.name != undefined
    ) {
      // if (menu[action.button].route == "NAVIGATE_EDIT")
      //   setPageState((prevState) => {
      //     return {
      //       ...prevState,
      //       window: { ...prevState.window, mode: StringInputMODE.MODE_EDIT },
      //     };
      //   });

      return;
    }
    console.log("setting handle right");
    setPageState((prevState) => {
      return {
        ...prevState,
        windowName: prevState.menuItems[prevState.activeItemIndex].window,
        window: {},
      };
    });
  };

  const handleUp = (navigator, action, menu) => {
    if (pageState.window != null) {
      return;
    }
    setPageState((prev) => {
      let prevIndex = prev.activeItemIndex;
      let newActiveIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
      return { ...prev, activeItemIndex: newActiveIndex };
    });
  };

  const updateNavigation = () => {
    setPageState((old) => {
      if (old.menuItems == null || old.menu == null) {
        return old;
      }
      const showUpButton = old.activeItemIndex > 0;
      const showDownButton = old.activeItemIndex < old.menuItems.length - 1;
      old.menu.UP = showUpButton
        ? { label: "UP", route: "UP", local: true }
        : null;
      old.menu.DOWN = showDownButton
        ? { label: "DOWN", route: "DOWN", local: true }
        : null;
      return old;
    });
  };
  const setWindowState = (state, windowState) => {
    setPageState((prev) => {
      return {
        ...prev,
        window: { ...prev.window, state: windowState, menu: state },
      };
    });
    props.navigation.setMenu(state);
  };

  const renderPopUp = (state) => {
    console.log("RENDERIND POP UP");
    let popUp = null;

    switch (state.windowName) {
      case "newPatient":
        popUp = (
          <StringInputMenu
            characters={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", " "]}
            name={"newPatient"}
            label={translate("NewPatientID")}
            target={"patient"}
            valueName={"patientId"}
            nextItem={"newName"}
            value={
              state.updated?.patient?.patientId
                ? state.updated?.patient?.patientId
                : parseInt(state.patient?.patientId) + 1
            }
            setState={setWindowState}
            menu={state?.window?.menu}
            onComplete={newPatientComplete}
            onCancel={() => {
              console.log("canceled");
              setPageState((prevState) => {
                return {
                  ...prevState,
                  menu: { ...defaultMenu },
                  window: null,
                  updated: null,
                  windowName: null,
                };
              });
            }}
            state={state?.window?.state}
          />
        );
        break;
      case "newName":
        popUp = (
          <StringInputMenu
            characters={[
              "A",
              "B",
              "C",
              "D",
              "E",
              "F",
              "G",
              "H",
              "I",
              "J",
              "K",
              "L",
              "M",
              "N",
              "O",
              "P",
              "Q",
              "R",
              "S",
              "T",
              "U",
              "V",
              "W",
              "X",
              "Y",
              "Z",
              " ",
            ]}
            name={"newName"}
            label={translate("SetPatientName")}
            onCancel={() => {
              console.log("canceled");
              setPageState((prev) => {
                let updated = prev.updated;
                delete updated["patient"]["patientName"];
                return {
                  ...prev,
                  windowName: "newPatient",
                  window: null,
                  updated: updated,
                };
              });
            }}
            value={
              state.updated?.patient?.patientName
                ? state.updated?.patient?.patientName
                : state.patient?.patientName
            }
            setState={setWindowState}
            menu={state?.window?.menu}
            onComplete={newNameComplete}
            state={state?.window?.state}
          />
        );
        break;
      case "location":
        popUp = (
          <StringInputMenu
            characters={[
              "A",
              "B",
              "C",
              "D",
              "E",
              "F",
              "G",
              "H",
              "I",
              "J",
              "K",
              "L",
              "M",
              "N",
              "O",
              "P",
              "Q",
              "R",
              "S",
              "T",
              "U",
              "V",
              "W",
              "X",
              "Y",
              "Z",
              "0",
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "#",
              "-",
              "_",
              "&",
              " ",
            ]}
            name={"newLocation"}
            label={translate("Location")}
            onComplete={(state) => {
              setPageState((prev) => {
                return {
                  ...prev,
                  updated: null,
                  windowName: null,
                  window: null,
                  location: state.newValue,
                };
              });
              props.pro.setLocation(state.newValue);
            }}
            onCancel={() => {
              setPageState((prev) => {
                return {
                  ...prev,
                  windowName: null,
                  window: null,
                  updated: null,
                };
              });
            }}
            value={
              state.updated?.location
                ? state.updated?.location
                : state?.location
            }
            setState={setWindowState}
            menu={state?.window?.menu}
            state={state?.window?.state}
          />
        );
        break;
      case "newLocation":
        popUp = (
          <StringInputMenu
            characters={[
              "A",
              "B",
              "C",
              "D",
              "E",
              "F",
              "G",
              "H",
              "I",
              "J",
              "K",
              "L",
              "M",
              "N",
              "O",
              "P",
              "Q",
              "R",
              "S",
              "T",
              "U",
              "V",
              "W",
              "X",
              "Y",
              "Z",
              "0",
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "#",
              "-",
              "_",
              "&",
              " ",
            ]}
            name={"newLocation"}
            label={translate("Location")}
            onComplete={(state) => {
              setPageState((prev) => {
                return {
                  ...prev,
                  updated: { ...prev.updated, location: state.newValue },
                  windowName: "newNoSignalDelay",
                  window: null,
                };
              });
            }}
            onCancel={() => {
              setPageState((prev) => {
                let updated = prev.updated;
                delete updated["location"];
                return {
                  ...prev,
                  windowName: "newName",
                  window: null,
                  updated: updated,
                };
              });
            }}
            value={
              state.updated?.location
                ? state.updated?.location
                : state?.location
            }
            setState={setWindowState}
            menu={state?.window?.menu}
            state={state?.window?.state}
          />
        );
        break;
      case "noSignalDelay":
        popUp = (
          <SelectInputMenu
            values={[
              OXIMETER_ALARM_DELAY.OXIMETER_DELAY_0,
              OXIMETER_ALARM_DELAY.OXIMETER_DELAY_1,
              OXIMETER_ALARM_DELAY.OXIMETER_DELAY_5,
              OXIMETER_ALARM_DELAY.OXIMETER_DELAY_15,
              OXIMETER_ALARM_DELAY.OXIMETER_DELAY_30,
            ]}
            name={"noSignalDelay"}
            label={translate("NoSignalDelay")}
            onComplete={(state) => {
              props.pro.setOximeterSignalAlarmDelay(state.newValue);
              setPageState((prev) => {
                return {
                  ...prev,
                  oximeterSignalAlarmDelay: state.newValue,
                  windowName: null,
                  window: null,
                  updated: null,
                };
              });
            }}
            onCancel={() => {
              setPageState((prev) => {
                return {
                  ...prev,
                  windowName: null,
                  window: null,
                  updated: null,
                };
              });
            }}
            value={
              state.updated?.oximeterSignalAlarmDelay
                ? state.updated?.oximeterSignalAlarmDelay
                : state?.oximeterSignalAlarmDelay
            }
            format={(data) => data / 60 + " min"}
            setState={setWindowState}
            menu={state?.window?.menu}
            state={state?.window?.state}
          />
        );
        break;
      case "newNoSignalDelay":
        popUp = (
          <SelectInputMenu
            values={[
              OXIMETER_ALARM_DELAY.OXIMETER_DELAY_0,
              OXIMETER_ALARM_DELAY.OXIMETER_DELAY_1,
              OXIMETER_ALARM_DELAY.OXIMETER_DELAY_5,
              OXIMETER_ALARM_DELAY.OXIMETER_DELAY_15,
              OXIMETER_ALARM_DELAY.OXIMETER_DELAY_30,
            ]}
            name={"noSignalDelay"}
            label={translate("NoSignalDelay")}
            onComplete={(state) => {
              setPageState((prev) => {
                return {
                  ...prev,
                  updated: {
                    ...prev.updated,
                    oximeterSignalAlarmDelay: state.newValue,
                  },
                  windowName: "selectNewProfile",
                  window: null,
                };
              });
            }}
            onCancel={() => {
              setPageState((prev) => {
                let updated = prev.updated;
                delete updated["oximeterSignalAlarmDelay"];
                return {
                  ...prev,
                  windowName: "newLocation",
                  window: null,
                  updated: updated,
                };
              });
            }}
            value={
              state.updated?.oximeterSignalAlarmDelay
                ? state.updated?.oximeterSignalAlarmDelay
                : state?.oximeterSignalAlarmDelay
            }
            format={(data) => data / 60 + " min"}
            setState={setWindowState}
            menu={state?.window?.menu}
            state={state?.window?.state}
          />
        );
        break;
      case "selectProfile":
        popUp = (
          <SelectProfileMenu
            profiles={[...props.pro.defaultProfiles]}
            name={"selectProfile"}
            label={translate("Profile")}
            onComplete={(profile) => {
              if (isRiskyProfile(profile)) {
                profile.isRisky = true;
                setPageState((prev) => {
                  return {
                    ...prev,
                    windowName: "confirmRiskyProfile",
                    window: null,
                    riskyProfile: profile,
                  };
                });
              } else {
                props.pro.setProfile(profile);
                setPageState((prev) => {
                  return {
                    ...prev,
                    windowName: null,
                    window: null,
                    updated: null,
                  };
                });
              }
            }}
            onCancel={() => {
              setPageState((prev) => {
                return {
                  ...prev,
                  windowName: null,
                  window: null,
                  updated: null,
                };
              });
            }}
            value={
              state.updated?.oximeterSignalAlarmDelay
                ? state.updated?.oximeterSignalAlarmDelay
                : state?.oximeterSignalAlarmDelay
            }
            format={(data) => data}
            setState={setWindowState}
            menu={state?.window?.menu}
            state={state?.window?.state}
          />
        );
        break;
      case "selectNewProfile":
        popUp = (
          <SelectProfileMenu
            profiles={[...props.pro.defaultProfiles]}
            name={"selectProfile"}
            label={translate("Profile")}
            onComplete={(profile) => {
              if (isRiskyProfile(profile)) {
                profile.isRisky = true;
                setPageState((prev) => {
                  return {
                    ...prev,
                    windowName: "confirmRiskyProfileNew",
                    window: null,
                    riskyProfile: profile,
                  };
                });
              } else {
                props.pro.setPatient(pageState.updated.patient);
                props.pro.setLocation(pageState.updated.location);
                props.pro.setOximeterSignalAlarmDelay(
                  pageState.updated.oximeterSignalAlarmDelay
                );
                props.pro.setProfile(profile);
                setPageState((prev) => {
                  return {
                    ...prev,
                    windowName: null,
                    window: null,
                    updated: null,
                  };
                });
                history.push({
                  pathname: "/home",
                });
              }
            }}
            onCancel={() => {
              setPageState((prev) => {
                let updated = prev.updated;
                delete updated["profile"];
                return {
                  ...prev,
                  windowName: "newNoSignalDelay",
                  window: null,
                  updated: updated,
                };
              });
            }}
            value={
              state.updated?.oximeterSignalAlarmDelay
                ? state.updated?.oximeterSignalAlarmDelay
                : state?.oximeterSignalAlarmDelay
            }
            format={(data) => data}
            setState={setWindowState}
            menu={state?.window?.menu}
            state={state?.window?.state}
          />
        );
        break;
      case "customizeProfile":
        popUp = (
          <CustomizeProfile
            name={"customizeProfile"}
            label={translate("CustomizeProfile")}
            onComplete={(profile) => {
              if (isRiskyProfile(profile)) {
                profile.isRisky = true;
                setPageState((prev) => {
                  return {
                    ...prev,
                    windowName: "confirmRiskyProfile",
                    window: null,
                    riskyProfile: profile,
                  };
                });
              } else {
                profile.isRisky = false;
                props.pro.setProfile(profile);
                setPageState((prev) => {
                  return {
                    ...prev,
                    windowName: null,
                    window: null,
                    updated: null,
                  };
                });
              }
            }}
            onCancel={() => {
              setPageState((prev) => {
                return {
                  ...prev,
                  windowName: null,
                  window: null,
                };
              });
            }}
            value={{ ...props.pro.profile }}
            format={(data) => data}
            setState={setWindowState}
            menu={state?.window?.menu}
            state={state?.window?.state}
          />
        );
        break;
      case "confirmRiskyProfile":
        popUp = (
          <ConfirmRiskyProfile
            message={
              <Typography
                variant="h6"
                sx={{ color: colors.COLORS_ALARM_MEDIUM, fontWeight: "bold" }}
              >
                <Translate value={"ConfirmRisky"} />
              </Typography>
            }
            name={"confirmRiskyProfile"}
            onComplete={(state) => {
              console.log("confirmRiskyProfile accept");
              console.log({ state: state });
              props.pro.setProfile(pageState.riskyProfile);
              setPageState((prev) => {
                return {
                  ...prev,
                  windowName: null,
                  window: null,
                  updated: null,
                };
              });
            }}
            onCancel={() => {
              setPageState((prev) => {
                return {
                  ...prev,
                  windowName: null,
                  window: null,
                };
              });
            }}
            setState={setWindowState}
            menu={state?.window?.menu}
            state={state?.window?.state}
          />
        );
        break;
        case "confirmRiskyProfileNew":
          popUp = (
            <ConfirmRiskyProfile
              message={
                <Typography
                  variant="h6"
                  sx={{ color: colors.COLORS_ALARM_MEDIUM, fontWeight: "bold" }}
                >
                  <Translate value={"ConfirmRisky"} />
                </Typography>
              }
              name={"confirmRiskyProfileNew"}
              onComplete={(state) => {
                props.pro.setPatient(pageState.updated.patient);
                props.pro.setLocation(pageState.updated.location);
                props.pro.setOximeterSignalAlarmDelay(
                  pageState.updated.oximeterSignalAlarmDelay
                );
                props.pro.setProfile(pageState.riskyProfile);
                setPageState((prev) => {
                  return {
                    ...prev,
                    windowName: null,
                    window: null,
                    updated: null,
                  };
                });
                history.push({
                  pathname: "/home",
                });
              }}
              onCancel={() => {
                setPageState((prev) => {
                  return {
                    ...prev,
                    windowName: null,
                    window: null,
                  };
                });
              }}
              setState={setWindowState}
              menu={state?.window?.menu}
              state={state?.window?.state}
            />
          );
          break;
    }
    return popUp;
  };

  const newPatientComplete = (state) => {
    console.log("new patient complete");
    console.log({ state: state });
    console.log({ pageState: pageState });

    setPageState((prev) => {
      return {
        ...prev,
        updated: { patient: { patientId: state.newValue } },
        windowName: "newName",
        window: null,
      };
    });
  };
  const newNameComplete = (state) => {
    console.log("new patient complete");
    console.log({ state: state });
    console.log({ pageState: pageState });
    setPageState((prev) => {
      let patient = prev.updated.patient;
      patient.patientName = state.newValue;
      return {
        ...prev,
        updated: { ...prev.updated, patient: patient },
        windowName: "newLocation",
        window: null,
      };
    });
  };

  const localNavigation = (navigator, action, menu) => {
    console.log({ pageState: pageState });
    if (action.type == "CLICK") {
    }
    if (pageState.window?.state?.windowNavigation != null) {
      console.log("executing window naw");
      pageState.window?.state?.windowNavigation(
        navigator,
        action,
        menu,
        pageState?.window?.state
      );
      return;
    }

    if (action.type == "CLICK") {
      switch (action.button) {
        case "DOWN":
          handleDown(navigator, action, menu);
          break;
        case "LEFT":
          handleLeft(navigator, action, menu);
          break;
        case "UP":
          handleUp(navigator, action, menu);
          break;
        case "RIGHT":
          handleRight(navigator, action, menu);
          break;
      }
    }
  };
  useEffect(() => {
    let menuItems = generateMenuItems(props);
    setPageState((prev) => {
      return { ...prev, menuItems: menuItems };
    });
  }, [pageState.oximeterSignalAlarmDelay, pageState.location]);
  useEffect(() => {
    updateNavigation();
  }, [pageState.activeItemIndex]);

  useEffect(() => {
    const state = pageState;
    // if(state.window){
    //   state.window.menu=null;
    // }

    const searchParams = new URLSearchParams();
    searchParams.append("windowState", JSON.stringify(state));
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });

    if (
      pageState?.window?.menu == null ||
      pageState?.window?.menu == undefined
    ) {
      props.navigation.setMenu(pageState.menu);
    }
    props.navigation.addLocalNavigation(localNavigation);
    return () => {
      props.navigation.addLocalNavigation(null);
    };
  }, [pageState]);
  useEffect(() => {
    let pop = renderPopUp(pageState);
    setPopUp(pop);
  }, [pageState.windowName, pageState.window]);

  return (
    <PopUpWrapper popup={popup}>
      <ItemMenu
        margin={5}
        activeItemIndex={pageState.activeItemIndex}
        width="60%"
        menuItems={pageState.menuItems}
      />
    </PopUpWrapper>
  );
};

const generateMenuItems = (props) => {
  return [
    { label: "New patient", window: "newPatient" },
    { label: "Select profile", window: "selectProfile" },
    { label: "Customize profile", window: "customizeProfile" },
    {
      label: "Location",
      value: props.pro.location,
      window: "location",
    },
    {
      label: "No Signal Delay",
      value: props.pro.oximeterSignalAlarmDelay / 60 + " min",
      window: "noSignalDelay",
    },
  ];
};

const isRiskyProfile = (profile) => {
  if (profile.minSpO2 < 85 || profile.maxPulse > 150) {
    return true;
  }
  return false;
};
