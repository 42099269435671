import { Box, Typography } from "@mui/material";
import { colors } from "../../Style";
import { useEffect, useState } from "react";
import { useHistory, createSearchParams, useLocation } from "react-router-dom";
import {  useQuery } from "../../Utils";
import { ItemMenu } from "./ItemMenu";

const findActiveIndex = (value, values) => {
  return values.indexOf(value);
};

export const EDIT_PROFILE_MODE = {
  MODE_DEFAULT: "MODE_DEFAULT",
  MODE_EDIT_VALUE: "EDIT_VALUE",
};

export const ItemMenuChangeValue = (props) => {
  console.log({ SelectProfileMenuProps: props });
  const navigation = (navigator, action, menu, state) => {
    console.log({ state: state });
    if (action.type === "CLICK") {
      switch (menu[action.button].route) {
        case "NAVIGATE_CANCEL":
          cancelClicked(state);
          break;
        case "NAVIGATE_SAVE":
          saveClicked(state);
          break;
        case "NAVIGATE_BACK":
          backClicked(state);
          break;
        case "NAVIGATE_UNDO":
          undoClicked(state);
          break;
        case "NAVIGATE_ACCEPT":
          break;
        case "NAVIGATE_SELECT":
          selectClicked(state);
          break;
        case "NAVIGATE_EDIT":
          editClicked(state);
          break;
        case "VALUE_UP":
          valueUp(state);
          break;
        case "VALUE_DOWN":
          valueDown(state);
          break;
      }
    }
  };

  const shouldInit = props.menu == undefined ? true : false;
  const menuItems = props.menuItems;
  const defaultState = {
    name: props.name,
    menuItems:props.menuItems,
    label: props.label,
    activeItemIndex:0,
    editActiveIndex: 0,
    editProfileValueMode: EDIT_PROFILE_MODE.MODE_DEFAULT,
    value: props.value,
    newValue: props.value,
    values: props.values,
    windowNavigation: navigation,
  };

  const menu = props.menu
    ? props.menu
    : {
        LEFT: { label: "Back", route: "settings"},
        UP: null,
        RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
        DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
      };
  defaultState.menu = menu;
  const windowState = props?.state
    ? {
        ...props.state,
        menu: menu,
      }
    : defaultState;

  console.log({ windowState: windowState });

  const setWindowState = (windowState) => {
    console.log({ setWindowState: windowState });
    props.setState(windowState);
  };

  const cancelClicked = (state) => {
    props.onCancel(state);
  };

  const updateNavigation = (state) => {
    let menu={
      LEFT: { label: "Back", route: "settings" },
      RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
    }
    const showUpButton = state.activeItemIndex > 0;
    const showDownButton = state.activeItemIndex < state.menuItems.length - 1;
    menu.UP = showUpButton
      ? { label: "UP", route: "VALUE_UP", local: true }
      : null;
    menu.DOWN = showDownButton
      ? { label: "DOWN", route: "VALUE_DOWN", local: true }
      : null;
    return menu;
  };

  const updateNavigationForValue = (state) => {
    let menu={
      LEFT: { label: "Undo", route: "NAVIGATE_UNDO",local: true  },
      RIGHT: { label: "Save", route: "NAVIGATE_SAVE", local: true },
      MIDDLE: { label: "Value"},
      UP:{ label: "UP", route: "VALUE_UP", local: true },
      DOWN:{ label: "DOWN", route: "VALUE_DOWN", local: true }
    }
    // const showUpButton = state.activeItemIndex > 0;
    // const showDownButton = state.activeItemIndex < state.menuItems.length - 1;
    // menu.UP = showUpButton
    //   ? { label: "UP", route: "VALUE_UP", local: true }
    //   : null;
    // menu.DOWN = showDownButton
    //   ? { label: "DOWN", route: "VALUE_DOWN", local: true }
    //   : null;
    return menu;
  };


  const valueUp = (state) => {
    if (state.editProfileValueMode === EDIT_PROFILE_MODE.MODE_DEFAULT) {
      let prevIndex = state.activeItemIndex;
      let newActiveIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
      let newState={...state,activeItemIndex:newActiveIndex}
      setWindowState({...state,activeItemIndex:newActiveIndex,menu:updateNavigation(newState)});
    } else if (
      state.editProfileValueMode === EDIT_PROFILE_MODE.MODE_EDIT_VALUE
    ) {
      let savedState=JSON.parse(JSON.stringify(state.menuItems));
      let newValue=cycleValues("up",state.menuItems,state.menuItems[state.activeItemIndex].value,state.activeItemIndex)
      let menuItems=[...state.menuItems];
      menuItems[state.activeItemIndex].value=menuItems[state.activeItemIndex].possibleValues[newValue]
      setWindowState({
        ...state,
        updatedMenuItems:menuItems
      });
    }
  };

  const valueDown = (state) => {
    if (state.editProfileValueMode === EDIT_PROFILE_MODE.MODE_DEFAULT) {
      let prevIndex = state.activeItemIndex;
      let menuItems = state.menuItems;
      let newActiveIndex =
      prevIndex < menuItems.length - 1 ? prevIndex + 1 : prevIndex;
      let newState={...state,activeItemIndex:newActiveIndex}
      setWindowState({...state,activeItemIndex:newActiveIndex,menu:updateNavigation(newState)});
    } else if (
      state.editProfileValueMode === EDIT_PROFILE_MODE.MODE_EDIT_VALUE
    ) {
      let savedState=JSON.parse(JSON.stringify(state.menuItems));
      let newValue=cycleValues("down",state.menuItems,state.menuItems[state.activeItemIndex].value,state.activeItemIndex)
      let menuItems=[...state.menuItems];
      menuItems[state.activeItemIndex].value=menuItems[state.activeItemIndex].possibleValues[newValue]
      setWindowState({
        ...state,
        updatedMenuItems:menuItems
      });
    }
  };

  const cycleValues=(direction,values,currentValue,menuIndex)=>{
    let newIndex;
    if(direction=="down"){
      let index=findActiveIndex(currentValue,values[menuIndex].possibleValues)
      newIndex = index - 1;
      if (newIndex < 0) {
        newIndex = values[menuIndex].possibleValues.length - 1;
      }
    }
    else if(direction=="up"){
      let index=findActiveIndex(currentValue,values[menuIndex].possibleValues)
      newIndex = index + 1;
      if (newIndex === values[menuIndex].possibleValues.length) {
        newIndex = 0;
      }
    }
    return newIndex;
  }

  const backClicked = (state) => {
    if (state.editProfileValueMode === EDIT_PROFILE_MODE.MODE_DEFAULT) {
      props.onComplete(state.updatedProfile?state.updatedProfile:state.newValue);
      // setWindowState({
      //   ...state,
      //   editProfileValueMode: EDIT_PROFILE_MODE.MODE_DEFAULT,
      //   menu: updateNavigationProfiles(
      //     EDIT_PROFILE_MODE.MODE_DEFAULT,
      //     state.activeIndex,
      //     state.profiles
      //   ),
      // });
    }
  };
  const editClicked = (state) => {
    let updatedProfile = { ...state.profiles[state.activeIndex] };
    setWindowState({
      ...state,
      editActiveIndex: 0,
      updatedProfile: updatedProfile
    });
  };

  const undoClicked = (state) => {
    if (state.editProfileValueMode == EDIT_PROFILE_MODE.MODE_EDIT_VALUE) {
      //props.onCancel()
      let newState={...state}
      newState.editProfileValueMode=EDIT_PROFILE_MODE.MODE_DEFAULT
      newState.menuItems=state.savedState
      newState.savedState=null;
      newState.updatedMenuItems=null
    setWindowState({
      ...newState,
      menu:updateNavigation(newState),
    });
    }
  };

  const selectClicked = (state) => {
    if (state.editProfileValueMode == EDIT_PROFILE_MODE.MODE_DEFAULT) {
      
      
      setWindowState({
        ...state,
        savedState:JSON.parse(JSON.stringify(state.menuItems)),
        editProfileValueMode: EDIT_PROFILE_MODE.MODE_EDIT_VALUE,
        menu:updateNavigationForValue(state)
      });
    }
  };
  const saveClicked = (state) => {
    if (state.editProfileValueMode === EDIT_PROFILE_MODE.MODE_EDIT_VALUE) {

      let newState={...state}
      newState.editProfileValueMode=EDIT_PROFILE_MODE.MODE_DEFAULT
      newState.menuItems=state.updatedMenuItems
      newState.savedState=null;
      newState.updatedMenuItems=null
    setWindowState({
      ...newState,
      menu:updateNavigation(newState),
    });
      props.onComplete(newState.menuItems)
    }
  };

  useEffect(() => {
    console.log("should init:" + shouldInit);
    if (true) {
      windowState.windowNavigation = navigation;
      props.setState(menu, windowState);
    }
  }, [windowState.name]);

  return (

        <Box sx={{ width: props.width?props.width:"60%", height: "100%" }}>
          <ItemMenu
            margin={5}
            showErrors={
              windowState.editProfileValueMode ===
              EDIT_PROFILE_MODE.MODE_EDIT_VALUE
            }
            selected={
              windowState.editProfileValueMode ===
              EDIT_PROFILE_MODE.MODE_EDIT_VALUE
            }
            activeItemIndex={windowState.activeItemIndex}
            width="100%"
            menuItems={
              windowState.updatedMenuItems?windowState.updatedMenuItems:windowState.menuItems
            }
          />
        </Box>
      
  );
};

const isProfileCustom = (profile, oldProfile) => {
  if (
    profile.minSpO2 != oldProfile.minSpO2 ||
    profile.maxSpO2 != oldProfile.maxSpO2 ||
    profile.minO2 != oldProfile.minO2 ||
    profile.maxO2 != oldProfile.maxO2 ||
    profile.initialO2 != oldProfile.initialO2 ||
    profile.minPulse != oldProfile.minPulse ||
    profile.maxPulse != oldProfile.maxPulse ||
    profile.flowResponse != oldProfile.flowResponse ||
    profile.minSpO2 != oldProfile.minSpO2
  ) {
    return true;
  }
  return false;
};

