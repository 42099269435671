import { Box, Typography } from "@mui/material";
import { ItemMenu } from "./ItemMenu";
import { colors } from "../../Style";
import { translate } from "react-i18nify";
import { useEffect } from "react";
import {
  getDateObject,
  getDateFromObject,
  getNumberOfDaysInMonth,
} from "../../TimeUtils";

export const EDIT_MODE = {
  MODE_DEFAULT: "MODE_DEFAULT",
  MODE_EDIT_VALUE: "EDIT_VALUE",
};
export const SetDateTime = (props) => {
  const navigation = (navigator, action, menu, state) => {
    if (action.type === "CLICK") {
      switch (menu[action.button].route) {
        case "NAVIGATE_CANCEL":
          cancelClicked(state);
          break;
        //   case "NAVIGATE_SAVE":
        //     saveClicked(state);
        //     break;
        case "NAVIGATE_BACK":
          backClicked(state);
          break;
        //   case "NAVIGATE_UNDO":
        //     undoClicked(state);
        //     break;
        case "NAVIGATE_COMMIT":
          commitClicked(state);
          break;
        case "NAVIGATE_SELECT":
          selectClicked(state);
          break;
        //   case "NAVIGATE_EDIT":
        //     editClicked(state);
        //     break;
        case "VALUE_UP":
          valueUp(state);
          break;
        case "VALUE_DOWN":
          valueDown(state);
          break;
      }
    }
  };

  const menuItems = generateItemMenu(getDateObject(props.value));
  const defaultState = {
    name: props.name,
    value: props.value,
    time: getDateObject(props.value),
    updatedTime: null,
    savedTime: getDateObject(props.value),
    activeItemIndex: 0,
    menuItems: menuItems,
    editMode: EDIT_MODE.MODE_DEFAULT,
    label: props.label,
    windowNavigation: navigation,
  };
  const setWindowState = (windowState) => {
    console.log({ setWindowState: windowState });
    props.setState(windowState.menu, windowState);
  };

  const selectClicked = (state) => {
    if (state.editMode == EDIT_MODE.MODE_DEFAULT) {
      if (menuItems[state.activeItemIndex].editName == "Save") {
        props.onComplete(getDateFromObject(state.time));
      } else {
        setWindowState({
          ...state,
          updatedTime: JSON.parse(JSON.stringify(state.time)),
          editMode: EDIT_MODE.MODE_EDIT_VALUE,
          menu: updateEditNavigation(state),
        });
      }
    }
  };
  const commitClicked = (state) => {
    if (state.editMode == EDIT_MODE.MODE_EDIT_VALUE) {
      setWindowState({
        ...state,
        time:JSON.parse(JSON.stringify(state.updatedTime)),
        updatedTime: null,
        editMode: EDIT_MODE.MODE_DEFAULT,
        menu: updateNavigation(state),
      });
    }
  };

  const cancelClicked = (state) => {
    if (state.editMode == EDIT_MODE.MODE_DEFAULT) {
      props.onCancel();
    }
    if (state.editMode == EDIT_MODE.MODE_EDIT_VALUE) {
      setWindowState({
        ...state,
        updatedTime: null,
        editMode: EDIT_MODE.MODE_DEFAULT,
        menu: updateNavigation(state),
      });
    }
  };
  const backClicked = (state) => {
    if (state.editMode == EDIT_MODE.MODE_DEFAULT) {
      props.onCancel();
    }
  };

  const valueUp = (state) => {
    if (state.editMode == EDIT_MODE.MODE_DEFAULT) {
      let prevIndex = state.activeItemIndex;
      let newActiveIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
      let newState = { ...state, activeItemIndex: newActiveIndex };
      setWindowState({
        ...state,
        activeItemIndex: newActiveIndex,
        menu: updateNavigation(newState),
      });
    }
    if (state.editMode == EDIT_MODE.MODE_EDIT_VALUE) {
      let editName = state.menuItems[state.activeItemIndex].editName;
      let currentValue = getCurrentValue(editName, state.updatedTime);
      let bottomValue = getBottomValue(editName);
      let topValue = getTopValue(editName, state);
      let newValue = cycleValue(currentValue, bottomValue, topValue, "up");
      let newTime = state.updatedTime;
      newTime[editName] = newValue;
      setWindowState({
        ...state,
        updatedTime: newTime,
      });
    }
  };
  const valueDown = (state) => {
    if (state.editMode == EDIT_MODE.MODE_DEFAULT) {
      let prevIndex = state.activeItemIndex;
      let menuItems = state.menuItems;
      let newActiveIndex =
        prevIndex < menuItems.length - 1 ? prevIndex + 1 : prevIndex;
      let newState = { ...state, activeItemIndex: newActiveIndex };
      setWindowState({
        ...state,
        activeItemIndex: newActiveIndex,
        menu: updateNavigation(newState),
      });
    }
    if (state.editMode == EDIT_MODE.MODE_EDIT_VALUE) {
      let editName = state.menuItems[state.activeItemIndex].editName;
      let currentValue = getCurrentValue(editName, state.updatedTime);
      let bottomValue = getBottomValue(editName);
      let topValue = getTopValue(editName, state);
      let newValue = cycleValue(currentValue, bottomValue, topValue, "down");
      let newTime = state.updatedTime;
      newTime[editName] = newValue;
      setWindowState({
        ...state,
        updatedTime: newTime,
      });
    }
  };

  const getCurrentValue = (name, values) => {
    return values[name];
  };

  const getTopValue = (name, state) => {
    switch (name) {
      case "year":
        return 2255;
      case "month":
        return 12;
      case "day":
        return getNumberOfDaysInMonth(state.time);
      case "hours":
        return 23;
      case "minutes":
        return 59;
    }
  };

  const getBottomValue = (name) => {
    switch (name) {
      case "year":
        return 2000;
      case "month":
        return 1;
      case "day":
        return 1;
      case "hours":
        return 0;
      case "minutes":
        return 0;
    }
  };

  const cycleValue = (currentValue, bottomValue, topValue, direction) => {
    if (direction === "up") {
      if (currentValue === topValue) {
        return bottomValue;
      } else {
        return currentValue + 1;
      }
    }
    if (direction === "down") {
      if (currentValue === bottomValue) {
        return topValue;
      } else {
        return currentValue - 1;
      }
    }
  };

  const updateEditNavigation = (state) => {
    let menu = {
      LEFT: { label: "Cancel", route: "NAVIGATE_CANCEL", local: true },
      RIGHT: { label: "Commit", route: "NAVIGATE_COMMIT", local: true },
    };
    menu.UP = { label: "UP", route: "VALUE_UP", local: true };
    menu.DOWN = { label: "DOWN", route: "VALUE_DOWN", local: true };
    return menu;
  };

  const updateNavigation = (state) => {
    let menu = {
      LEFT: { label: "Back", route: "NAVIGATE_CANCEL", local: true },
      RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
    };
    const showUpButton = state.activeItemIndex > 0;
    const showDownButton = state.activeItemIndex < state.menuItems.length - 1;
    menu.UP = showUpButton
      ? { label: "UP", route: "VALUE_UP", local: true }
      : null;
    menu.DOWN = showDownButton
      ? { label: "DOWN", route: "VALUE_DOWN", local: true }
      : null;
    return menu;
  };
  const menu = props.menu
    ? props.menu
    : {
        LEFT: { label: "Back", route: "NAVIGATE_CANCEL", local: true },
        UP: null,
        RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
        MIDDLE: null,
        DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
      };
  defaultState.menu = menu;
  const windowState = props?.state
    ? {
        ...props.state,
        menu: menu,
      }
    : defaultState;
  useEffect(() => {
    if (true) {
      windowState.windowNavigation = navigation;
      props.setState(menu, windowState);
    }
  }, [windowState.name]);
  return (
    <Box
      sx={{
        width: "475px",
        height: "250px",
        background: "black",
        border: "1px solid white",
        p: 1,
        pl: 2,
        pr: 2,
      }}
    >
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Typography
          variant="h6"
          sx={{ color: colors.TEXT_COLOR, fontWeight: "bold" }}
        >
          {windowState?.label}
        </Typography>
      </Box>
      <ItemMenu
        activeItemIndex={windowState.activeItemIndex}
        selected={windowState.editMode === EDIT_MODE.MODE_EDIT_VALUE}
        menuItems={generateItemMenu(
          windowState.updatedTime ? windowState.updatedTime : windowState.time
        )}
      />
    </Box>
  );
};

const generateItemMenu = (date) => {
  return [
    {
      label: translate("Year"),
      editName: "year",
      value: date.year,
    },
    { label: translate("Month"), editName: "month", value: date.month },
    { label: translate("Day"), editName: "day", value: date.day },
    { label: translate("Hour"), editName: "hours", value: date.hours },
    { label: translate("Minute"), editName: "minutes", value: date.minutes },
    { label: translate("SaveAndClose"), editName: "Save" },
  ];
};
