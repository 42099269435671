import { Typography, Box } from "@mui/material";
import { useEffect } from "react";
import { ItemMenu } from "../../components/UI/ItemMenu";
import { IonRouterOutlet } from "@ionic/react";
import { PopUpWrapper } from "../../components/PopUpWrapper";
import { useState } from "react";
import { useHistory, createSearchParams, useLocation } from "react-router-dom";
import { usePageState, useQuery } from "../../Utils";
import { StringInputMODE } from "../../components/UI/StringInputMenu";
import { StringInputMenu } from "../../components/UI/StringInputMenu";
import { GetWindowState } from "../../Utils";
import { defaultReducer } from "../../Utils";
import { colors } from "../../Style";

const isRiskyProfile = (profile) => {
  if (profile.minSpO2 < 85 || profile.maxPulse > 150) {
    return true;
  }
  return false;
};
const generateProfileMenu = (profiles) => {
  let result = profiles?.map((item) => {
    return {
      labelColor:isRiskyProfile(item)?colors.COLORS_ALARM_MEDIUM:null,
      label: item.name,
      value: "EDIT",
      route: "newProfile",
      routeValue:item.id
    };
  });
  result?.push({ label: "New profile", value: "Create", route: "newProfile",routeValue:"new",valueColor:colors.COLORS_ALARM_LOW });
  return result;
};

export const Profiles = (props) => {
  const history = useHistory();
  const location = useLocation();
  const state = GetWindowState();

  const [popup, setPopUp] = useState(null);
  const [pageState, setPageState] = usePageState(
    defaultReducer,
    state
      ? state
      : {
          menu: {
            LEFT: { label: "Back", route: "admin" },
            UP: null,
            RIGHT: { label: "Select", route: "select", local: true },
            MIDDLE: null,
            DOWN: { label: "Down", route: "down", local: true },
          },
          window: null,
          activeItemIndex: 0,
          menuItems: generateProfileMenu(props.pro.defaultProfiles),
        }
  );

  const handleDown = (navigator, action, menu) => {
    if (pageState.window != null) {
      return;
    }
    setPageState((prev) => {
      let prevIndex = prev.activeItemIndex;
      let menuItems = prev.menuItems;
      let newActiveIndex =
        prevIndex < menuItems.length - 1 ? prevIndex + 1 : prevIndex;
      return { ...prev, activeItemIndex: newActiveIndex };
    });
  };
  const handleUp = (navigator, action, menu) => {
    console.log({ navigator: navigator });
    console.log({ action: action });
    console.log({ menu: menu });
    if (pageState.window != null) {
      if (menu[action.button].route == "NAVIGATE_EDIT")
        setPageState((prevState) => {
          return {
            ...prevState,
            window: { ...prevState.window, mode: StringInputMODE.MODE_EDIT },
          };
        });

      return;
    }
    setPageState((prev) => {
      let prevIndex = prev.activeItemIndex;
      let newActiveIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
      return { ...prev, activeItemIndex: newActiveIndex };
    });
  };

  const updateNavigation = () => {
    setPageState((old) => {
      const showUpButton = old.activeItemIndex > 0;
      const showDownButton = old.activeItemIndex < old.menuItems?.length - 1;
      old.menu.UP = showUpButton
        ? { label: "UP", route: "UP", local: true }
        : null;
      old.menu.DOWN = showDownButton
        ? { label: "DOWN", route: "DOWN", local: true }
        : null;
      return old;
    });
  };
  const setWindowState = (state, windowState) => {
    setPageState((prev) => {
      return { ...prev, menu: state, window: windowState };
    });
  };
  const renderPopUp = (state) => {
    let popUp = null;
    switch (state.window?.name) {
      case "newPatient":
        popUp = (
          <StringInputMenu
            name={"newPatient"}
            setState={setWindowState}
            state={state}
          />
        );
      case "location":
        popUp = (
          <StringInputMenu
            name={"location"}
            setState={setWindowState}
            state={state}
          />
        );
    }
    return popUp;
  };
  const localNavigation = (navigator, action, menu) => {
    console.log("itemMenu NAv admin");
    console.log({ pageState: pageState });

    if (action.type == "CLICK") {
      switch (action.button) {
        case "DOWN":
          handleDown(navigator, action, menu);
          break;
        case "LEFT":
          // handleLeft(navigator,action,menu);
          break;
        case "UP":
          handleUp(navigator, action, menu);
          break;
        case "RIGHT":
          const searchParams = new URLSearchParams();
          
          searchParams.append("profile", pageState.menuItems[pageState.activeItemIndex].routeValue);
          history.push({
            pathname:pageState.menuItems[pageState.activeItemIndex].route,
            search: searchParams.toString(),
          });
          //   handleRight(activeItemIndex)
          break;
      }
    }
  };
  useEffect(() => {
    let menu = generateProfileMenu(props.pro.defaultProfiles);
    props.navigation.setMenu(pageState.menu);
    props.navigation.addLocalNavigation(localNavigation);
    return () => {
      props.navigation.addLocalNavigation(null);
    };
  }, [pageState]);

  useEffect(() => {
    updateNavigation();
  }, [pageState.activeItemIndex]);

  useEffect(() => {
    const state = pageState;
    const searchParams = new URLSearchParams();
    searchParams.append("windowState", JSON.stringify(state));
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [pageState]);

  useEffect(() => {
    let pop = renderPopUp(pageState);
    if (pop != null) {
    }
    setPopUp(pop);
  }, [pageState.window, pageState.window?.mode]);

  return (
    <PopUpWrapper popup={popup}>
      <ItemMenu
        margin={5}
        activeItemIndex={pageState.activeItemIndex}
        width="60%"
        menuItems={pageState.menuItems}
      />
    </PopUpWrapper>
  );
};
