import { useEffect } from "react";
import HubspotForm from 'react-hubspot-form'
export const HubSpotForm=(props)=> {  
    return <HubspotForm
    portalId='7036298'
    formId='6653978e-ace1-4b32-b95f-c84bc9072520'
    onSubmit={(data) => console.log("form submited")}
    onReady={(form) => console.log('Form ready!')}
    loading={<div>Loading...</div>}
    />
  }