import { Box } from "@mui/system";
import React, { useState,useEffect } from "react";
import { NavigationButton } from "./NavigationButton";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import Arrow from "../../assets/White_Arrow.svg";
import NoPower from "../../assets/No_Power.svg";

import { colors } from "../../Style";
import { useHistory } from "react-router";
import { Typography } from "@mui/material";
import { NoPowerIcon } from "../../assets/NoPower";
import { ProBatteryIcon } from "../../assets/ProBatteryIcon";
import { ALARM_CODE, getHighestSeverityAlarm } from "../../ProSimulator/Alarm";


export const NAVIGATION_BUTTONS={
  HOME:"HOME",
  POWER:"POWER",
  LEFT:"LEFT",
  RIGHT:"RIGHT",
  DOWN:"DOWN",
  UP:"UP",
  MUTE:"MUTE",
  MAN_AUTO:"MAN_AUTO"

}
export const ControlPanel = (props) => {
  const history = useHistory();

  const [buttonsStatus,setButtonsStatus]= useState({})

  useEffect(()=>{
    //console.log("control panel")
    props.navigation.evaluteLed()
    setButtonsStatus(props.navigation.ledControl)
  },[props.navigation])


  const [batteryCricitcal,setBatteryCritical]= useState(false)

  useEffect(() => {

    let batteryAlarm = getHighestSeverityAlarm(props.pro?.alarms, [
      ALARM_CODE.BATTERY_CRITICAL,ALARM_CODE.BATTERY_FAIL
    ]);
    if(batteryAlarm!=0){
      setBatteryCritical(true)
      return;
    }
    setBatteryCritical(false)
  
  }, [props.pro?.alarms]);

  const onButtonCallback = (e) => {
    props.buttonCallback(history, e);
  };

  const getTint = (buttonValue) => {
    return { fill: buttonValue ? colors.BODY_COLOR_ON : colors.BODY_COLOR_OFF };
  };
  const getColor = (buttonValue) => {
    return {
      color: buttonValue ? colors.BODY_COLOR_ON : colors.BODY_COLOR_OFF,
    };
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        background: colors.BODY_COLOR,
      }}
    >
      <Box
        sx={{
          width: "20%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <NavigationButton
          active={buttonsStatus.HOME}
          value={NAVIGATION_BUTTONS.HOME}
          onLongPress={onButtonCallback}
          onStartTouch={onButtonCallback}
          onClick={onButtonCallback}
          onPressDown={onButtonCallback}
          onRelease={onButtonCallback}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              textShadow: "0 0 2px " + colors.BODY_COLOR_OFF,
              ...getColor(buttonsStatus.HOME),
            }}
          >
            HOME
          </Typography>
        </NavigationButton>
        <NavigationButton
          active={buttonsStatus.POWER}
          value={NAVIGATION_BUTTONS.POWER}
          onLongPress={onButtonCallback}
          onClick={onButtonCallback}
          onPressDown={onButtonCallback}
          onRelease={onButtonCallback}
        >
          <PowerSettingsNewIcon sx={{...getColor(buttonsStatus.POWER),stroke: colors.BODY_COLOR_OFF, strokeWidth: 1 }} />
        </NavigationButton>
      </Box>
      <Box
        sx={{
          width: "60%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NavigationButton
              active={buttonsStatus.LEFT}
              value={NAVIGATION_BUTTONS.LEFT}
              onLongPress={onButtonCallback}
              onClick={onButtonCallback}
              onPressDown={onButtonCallback}
              onRelease={onButtonCallback}
            >
              <ChangeHistoryIcon sx={{transform:"rotate(-90deg)",...getColor(buttonsStatus.LEFT),stroke: colors.BODY_COLOR_OFF, strokeWidth: 1 }}/>
            </NavigationButton>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NavigationButton
              active={buttonsStatus.UP}
              value={NAVIGATION_BUTTONS.UP}
              onLongPress={onButtonCallback}
              onClick={onButtonCallback}
              onPressDown={onButtonCallback}
              onRelease={onButtonCallback}
            >
              <ChangeHistoryIcon sx={{...getColor(buttonsStatus.UP),stroke: colors.BODY_COLOR_OFF, strokeWidth: 1 }}/>
            </NavigationButton>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NavigationButton
              active={buttonsStatus.RIGHT}
              value={NAVIGATION_BUTTONS.RIGHT}
              onLongPress={onButtonCallback}
              onClick={onButtonCallback}
              onPressDown={onButtonCallback}
              onRelease={onButtonCallback}
            >
              <ChangeHistoryIcon sx={{transform:"rotate(90deg)",...getColor(buttonsStatus.RIGHT),stroke: colors.BODY_COLOR_OFF, strokeWidth: 1 }}/>
            </NavigationButton>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "75px",
                height: "75px",
                p: { sm: 1, sx: 1 },
              }}
            >
              <NoPowerIcon fill={buttonsStatus.NO_POWER?colors.COLORS_ALARM_HIGH:colors.BODY_COLOR} />
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NavigationButton
              active={buttonsStatus.DOWN}
              value={NAVIGATION_BUTTONS.DOWN}
              onLongPress={onButtonCallback}
              onClick={onButtonCallback}
              onPressDown={onButtonCallback}
              onRelease={onButtonCallback}
            >
              <ChangeHistoryIcon  sx={{transform:"rotate(180deg)",...getColor(buttonsStatus.DOWN),stroke: colors.BODY_COLOR_OFF, strokeWidth: 1 }}/>
            </NavigationButton>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "75px",
                height: "75px",
                p: { sm: 1, sx: 1 },
              }}>
              <ProBatteryIcon fill={batteryCricitcal?colors.COLORS_ALARM_HIGH:colors.BODY_COLOR}/>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "20%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <NavigationButton
          active={buttonsStatus.MUTE}
          value={NAVIGATION_BUTTONS.MUTE}
          onLongPress={onButtonCallback}
          onClick={onButtonCallback}
          onPressDown={onButtonCallback}
          onRelease={onButtonCallback}
        >
          <NotificationsOffIcon sx={{...getColor(buttonsStatus.MUTE),stroke: colors.BODY_COLOR_OFF, strokeWidth: 1 }} />
        </NavigationButton>

        <NavigationButton
        active={buttonsStatus.MAN_AUTO}
          value={NAVIGATION_BUTTONS.MAN_AUTO}
          onLongPress={onButtonCallback}
          onClick={onButtonCallback}
          onRelease={onButtonCallback}
          onPressDown={onButtonCallback}>
            
          <Typography
            sx={{
              fontWeight: "bold",
              textShadow: "0 0 2px " + colors.BODY_COLOR_OFF,
              ...getColor(buttonsStatus.MODE),
            }}
          >
            MAN AUTO
          </Typography>
        </NavigationButton>
      </Box>
    </Box>
  );
};
