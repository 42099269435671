import { useEffect, useState } from "react";
import { ItemMenu } from "../../components/UI/ItemMenu";
import { useHistory, createSearchParams, useLocation } from "react-router-dom";
import { GetWindowState, defaultReducer, usePageState, useQuery } from "../../Utils";
import { PopUpWrapper } from "../../components/PopUpWrapper";
import { StringInputMODE, StringInputMenu } from "../../components/UI/StringInputMenu";
import { SetDateTime } from "../../components/UI/SetDateTime";




export const Settings = (props) => {
  const history = useHistory();
  const location = useLocation();
  const state = GetWindowState();

  const [popup, setPopUp] = useState(null);
  const [pageState, setPageState] = usePageState(
    defaultReducer,
    state
      ? state
      : {
          menu: {
            LEFT: { label: "Back", route: "admin" },
            UP: null,
            RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
            MIDDLE: null,
            DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
          },
          window: null,
          activeItemIndex: 0,
          menuItems: generateMenuItems(),
        }
  );

  const setWindowState = (state, windowState) => {
    setPageState((prev) => {
      return {
        ...prev,
        menu: state,
        window: { ...prev.window, state: windowState, menu: state },
      };
    });
    props.navigation.setMenu(state);
  };




  const handleLeft = (navigator, action, menu) => {
    if (pageState.window != null) {
      if (menu[action.button].route == "NAVIGATE_Cancel")
        setPageState((prev) => {
          return { ...prev, window: null };
        });
      if (menu[action.button].route == "NAVIGATE_Clear")
        setPageState((prev) => {
          return { ...prev, window: null };
        });
      return;
    }
    else{
      setPageState((prevState) => {
        return {
          ...prevState,
          window: {
            name: prevState.menuItems[prevState.activeItemIndex].window,
          },
        };
      });
    }
  };

  const handleRight = (navigator, action, menu) => {
    setPageState((prevState) => {
      if (prevState.menuItems[prevState.activeItemIndex].window != null) {
        return {
          ...prevState,
          windowName:prevState.menuItems[prevState.activeItemIndex].window
        };
      } else if (
        prevState.menuItems[prevState.activeItemIndex].route != null
      ) {
        history.push(
          prevState.menuItems[prevState.activeItemIndex].route
        );
        return prevState;
      }
      else if(prevState.menuItems[prevState.activeItemIndex]?.selectInput){

      }
      else{
        return prevState
      }
    });
    // console.log("setting handle right");
    // setPageState((prevState) => {
    //   return {
    //     ...prevState,
    //     windowName: prevState.menuItems[prevState.activeItemIndex].window,
    //     window: {},
    //   };
    // });
  };

  const handleDown = (navigator, action, menu) => {
    if (pageState.window != null) {
      return;
    }
    setPageState((prev) => {
      let prevIndex = prev.activeItemIndex;
      let menuItems = prev.menuItems;
      let newActiveIndex =
        prevIndex < menuItems.length - 1 ? prevIndex + 1 : prevIndex;
      return { ...prev, activeItemIndex: newActiveIndex };
    });
  };
  const handleUp = (navigator, action, menu) => {
    console.log({ navigator: navigator });
    console.log({ action: action });
    console.log({ menu: menu });
    if (pageState.window != null) {
      if (menu[action.button].route == "NAVIGATE_EDIT")
        setPageState((prevState) => {
          return {
            ...prevState,
            window: { ...prevState.window, mode: StringInputMODE.MODE_EDIT },
          };
        });

      return;
    }
    setPageState((prev) => {
      let prevIndex = prev.activeItemIndex;
      let newActiveIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
      return { ...prev, activeItemIndex: newActiveIndex };
    });
  };
  const renderPopUp = (state) => {
    let popUp = null;
    switch (state.windowName) {
      case "setDateTime":
        popUp = <SetDateTime
        name={"setDateTime"}
        label={"Date Time"}
        value={props.pro.deviceTime}
        setState={setWindowState}
        menu={state?.window?.menu}
        state={state?.window?.state}
        onComplete={(newTime) => {
          props.pro.setDeviceTime(newTime)
          setPageState((prev) => {
            return {
              ...prev,
              windowName: null,
              window: null
            };
          });
          updateNavigation()
        }}
        onCancel={() => {
          setPageState((prev) => {
            return {
              ...prev,
              windowName: null,
              window: null
            };
          });
        }}
        />;
    }
    return popUp;
  };

  

  const localNavigation = (navigator, action, menu) => {
    if (action.type == "CLICK") {
    
    if (pageState.window?.state?.windowNavigation != null) {
      pageState.window?.state?.windowNavigation(
        navigator,
        action,
        menu,
        pageState?.window?.state
      );
      return;
    }}

    if (action.type == "CLICK") {
      switch (action.button) {
        case "DOWN":
          handleDown(navigator, action, menu);
          break;
        case "LEFT":
          handleLeft(navigator, action, menu);
          break;
        case "UP":
          handleUp(navigator, action, menu);
          break;
        case "RIGHT":
          handleRight(navigator, action, menu)
          break;
      }
    }
  };

  useEffect(() => {
    props.navigation.setMenu(pageState.menu);
    props.navigation.addLocalNavigation(localNavigation);
    return () => {
      props.navigation.addLocalNavigation(null);
    };
  }, [pageState]);

  const updateNavigation = () => {
    setPageState((old) => {
      old.menu.LEFT= { label: "Back", route: "admin" };
      old.menu.RIGHT= { label: "Select", route: "NAVIGATE_SELECT", local: true }
      const showUpButton = old.activeItemIndex > 0;
      const showDownButton = old.activeItemIndex < old.menuItems?.length - 1;
      old.menu.UP = showUpButton
        ? { label: "UP", route: "VALUE_UP", local: true }
        : null;
      old.menu.DOWN = showDownButton
        ? { label: "DOWN", route: "VALUE_DOWN", local: true }
        : null;
      return old;
    });
  };


  useEffect(() => {
    const state = pageState;
    const searchParams = new URLSearchParams();
    searchParams.append("windowState", JSON.stringify(state));
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [pageState]);


  useEffect(() => {
    let pop = renderPopUp(pageState);
    setPopUp(pop);
  }, [pageState.windowName, pageState.window]);
  useEffect(() => {
    updateNavigation()
  }, [pageState.activeItemIndex]);

  return (
    <PopUpWrapper popup={popup}>
      <ItemMenu
        margin={5}
        activeItemIndex={pageState?.activeItemIndex}
        width="60%"
        menuItems={pageState?.menuItems}
      />
    </PopUpWrapper>
  );
};
const generateMenuItems = (props) => {
  return [
    { label: "Set Date / Time", window: "setDateTime",local:true },
    { label: "Security",route:"security" },
    { label: "Pause screen", route:"pauseScreen" }
  ];
};