import { Redirect, Route, Switch } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Home } from "./pages/Home/Home";
import { SetStateAction, useEffect, useState, useMemo } from "react";
import { OxygenInput } from "./ProSimulator/OxygenInput";
import { createContext } from "react";
import {
  OximeterSimulator,
  OximeterState,
} from "./ProSimulator/OximeterSimulator";
import { PRO_MODE, defaultSetup } from "./ProSimulator/ProSimulator";
import {
  ControlPanel,
  NAVIGATION_BUTTONS,
} from "./components/Navigation/ControlPanel";
import { Layout } from "./components/Navigation/Layout";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

import ProSimulator from "./ProSimulator/ProSimulator";
import { Box, Typography } from "@mui/material";
import { Initializing } from "./pages/Home/Initializing";
import { BUTTON_EVENT } from "./components/Navigation/NavigationButton";
import { BatteryChargeState, ProBattery } from "./ProSimulator/ProBattery";
import { NavigationController } from "./components/Navigation/NavigationController";
import { Patient } from "./pages/Patient/Patient";
import { StringInputMenu } from "./components/UI/StringInputMenu";
import { Admin } from "./pages/Admin/Admin";
import { PowerDown } from "./components/UI/PowerDown";
import { Profiles } from "./pages/Admin/Profiles";
import { Settings } from "./pages/Admin/Settings";
import { Security } from "./pages/Admin/Security";
import { PauseScreen } from "./pages/Admin/PauseScreen";
import TranslationConfig from "./translation/translation-config";
import { Reset } from "./pages/Admin/Reset";
import { NewProfile } from "./pages/Admin/NewProfile";
import { Trends } from "./pages/Trends/Trends";
import { TrendsInfo } from "./pages/Trends/TrendsInfo";
import { useParams } from "react-router-dom";
import { readKeyValuePair } from "./AzureBlobUtils";
TranslationConfig();
setupIonicReact();

const App = () => {

  const ExtractProData = () => {
    const { id } = useParams();
  
    useEffect(()=>{
      const getData=async()=>{
        const data=await readKeyValuePair(id)
        const parsedData=JSON.parse(data)
        proData.stop()
        localStorage.removeItem("proSimulator")
        const proState=loadProSimulatorFromBlob(parsedData.proState)
        localStorage.setItem("proSimulator", JSON.stringify(proState));
        setProData(null);
        setProData(proState);
        window.location.replace(window.location.origin+parsedData.path+parsedData.windowState);
        console.log("data")
      }
      getData()
  
    },[])
  
    return (
      <div>
        <h1>{id}</h1>
      </div>
    );
  };

  const [navigation, setNavigation] = useState(new NavigationController());
  const [popUp, setPopUp] = useState(null);

  const [proData, setProData] = useState(
    new ProSimulator(
      defaultSetup.patient,
      defaultSetup.location,
      defaultSetup.oximeterSignalAlarmDelay,
      defaultSetup.profile,
      defaultSetup.defaultProfiles
    )
  );

  useEffect(() => {
    navigation.subscribe((navigations, action) => {
      navigation.evaluteLed();

      setNavigation({ ...navigation });
    });
    return () => {
      navigation.unsubscribeAll();
    };
  }, []);

  useEffect(() => {
    setProData(loadProSimulatorFromLocalStorage());
  }, []);

  useEffect(() => {
    let loadedData = loadProSimulatorFromLocalStorage();
    if (loadedData == null) {
      loadedData = proData;
    } else {
    }
    loadedData?.start();
    loadedData?.pauseScreen();
    loadedData.subscribe((data) => {
      if (data?.type == "navigate") {
        navigation.globalNavigate(data);
      }
      setPopUp(
        <PowerDown
          visible={proData.turnOffTimer?.isOn}
          count={proData.turnOffTimer?.time}
        />
      );
      setProData({ ...loadedData });
    });

    return () => {
      loadedData.stop();
      loadedData.unsubscribeAll();
    };
  }, []);

  useEffect(() => {
    setNavigation((nav) => {
      if (!proData?.isRunning) {
        nav.disableLEDs();
        return nav;
      }
      nav.ledControl.HOME = proData.isRunning;
      nav.ledControl.POWER = proData.isRunning;
      nav.ledControl.MODE = proData.isRunning;
      nav.ledControl.MUTE = proData.mute.isMuted;
      nav.ledControl.NO_POWER =
        proData.battery.chargeState == BatteryChargeState.NO_POWER;
      return nav;
    });
  }, [proData]);

  const controlPanelCallback = (history, instruction) => {
    if (proData.isPauseScreen()) {
      if (instruction.type == BUTTON_EVENT.RELEASE) {
        proData?.pauseScreen();
      }
      return;
    }

    navigation.onAction(history, instruction);
    // console.log({ instruction: instruction });

    if (instruction.type == BUTTON_EVENT.LONG_PRESS) {
      switch (instruction.button) {
        case NAVIGATION_BUTTONS.POWER:
          if (!proData.isRunning) {
            history.push("/Initializing");
            proData.turnOn();
            setTimeout(() => {
              proData.isStarting=false
              history.push("/home");
            }, 2000);
          }
      }
    } else if (instruction.type == BUTTON_EVENT.CLICK) {
      proData?.pauseScreen();
      switch (instruction.button) {
        case NAVIGATION_BUTTONS.HOME:
          history.push("/home");
          break;
        case NAVIGATION_BUTTONS.MUTE:
          proData?.muteAlarms();
          break;
        case NAVIGATION_BUTTONS.MAN_AUTO:
          history.push("/home");
          proData.switchMode();
          break;
      }
    } else if (instruction.type == BUTTON_EVENT.PRESS_DOWN) {
      switch (instruction.button) {
        case NAVIGATION_BUTTONS.POWER:
          if (proData.isRunning&&!proData.isStarting) {
            proData.startTurnOff();
            break;
          }
      }
    } else if (instruction.type == BUTTON_EVENT.RELEASE) {
      switch (instruction.button) {
        case NAVIGATION_BUTTONS.POWER:
          if (proData.isRunning) {
            proData.stopTurnOff();
            break;
          }
      }
    }
  };

  return (
    <IonApp>
      <IonReactRouter>
        {/* <Redirect from="/" to="/home" /> */}
        <div className="App">
          <Layout
            pro={proData}
            navigation={navigation}
            buttonCallback={controlPanelCallback}
            oximeter={proData?.oximeter}
            oxygenInput={proData?.oxygenInput}
            batteryInput={proData?.battery}
          >
            <Route exact path="/Initializing">
              <Initializing navigation={navigation} pro={proData} />
            </Route>
            <Route exact path="/">
              <Home navigation={navigation} pro={proData} />
            </Route>
            <Route exact path="/home">
              <Home navigation={navigation} pro={proData} />
            </Route>
            <Route exact path="/id/:id">
              <ExtractProData/>
            </Route>
            <Route
              exact
              path="/admin"
              render={() => (
                <Admin navigation={navigation} pro={proData}></Admin>
              )}
            />
            <Route
              exact
              path="/trends"
              render={() => (
                <Trends navigation={navigation} pro={proData}></Trends>
              )}
            />
            <Route
              exact
              path="/trendInfo"
              render={() => (
                <TrendsInfo navigation={navigation} pro={proData}></TrendsInfo>
              )}
            />
            <Route
              exact
              path="/profiles"
              render={() => (
                <Profiles navigation={navigation} pro={proData}></Profiles>
              )}
            />
            <Route
              exact
              path="/newProfile"
              render={() => (
                <NewProfile navigation={navigation} pro={proData}></NewProfile>
              )}
            />
            <Route
              exact
              path="/settings"
              render={() => (
                <Settings navigation={navigation} pro={proData}></Settings>
              )}
            />
            <Route
              exact
              path="/reset"
              render={() => <Reset navigation={navigation} pro={proData} />}
            />
            <Route
              exact
              path="/security"
              render={() => (
                <Security navigation={navigation} pro={proData}></Security>
              )}
            />
            <Route
              exact
              path="/pauseScreen"
              render={() => (
                <PauseScreen
                  navigation={navigation}
                  pro={proData}
                ></PauseScreen>
              )}
            />

            <Route
              path="/patient"
              render={() => (
                <Patient navigation={navigation} pro={proData}>
                  <Switch>
                    <Route
                      path="/patient/newPatient"
                      render={() => <Settings name="John" />}
                    />
                    <Route
                      path="/patient/location"
                      render={() => (
                        <StringInputMenu
                          navigation={navigation}
                          name="Johns location"
                        />
                      )}
                    />
                  </Switch>
                </Patient>
              )}
            />
          </Layout>

        </div>
      </IonReactRouter>
    </IonApp>
  );
  
};

export default App;


function loadProSimulatorFromLocalStorage() {
  const serializedProSimulator = localStorage.getItem("proSimulator");
  if (serializedProSimulator) {
    const proSimulatorData = JSON.parse(serializedProSimulator);
    const proSimulator = new ProSimulator(
      proSimulatorData.patient,
      proSimulatorData.location,
      proSimulatorData.oximeterSignalAlarmDelay,
      proSimulatorData.profile
    );
    proSimulator.defaultProfiles = proSimulatorData.defaultProfiles;
    proSimulator.isRunning = proSimulatorData.isRunning;
    proSimulator.pin = proSimulatorData.pin;
    proSimulator.pauseScreenValues = proSimulatorData.pauseScreenValues;

    proSimulator.deviceTime = proSimulatorData.deviceTime;
    proSimulator.adminPIN = proSimulatorData.adminPIN;
    proSimulator.oximeterCounter = proSimulatorData.oximeterCounter;
    proSimulator.listeners = new Set();
    proSimulator.oxygenOutput = proSimulatorData.oxygenOutput;
    proSimulator.alarms = proSimulatorData.alarms;
    proSimulator.mode = proSimulatorData.mode;
    proSimulator.data = proSimulatorData.data;
    proSimulator.testSetup = {...proSimulatorData.testSetup};
    proSimulator.testSetup.timer=null

    proSimulator.battery = new ProBattery();
    proSimulator.battery.setChargeState(proSimulatorData.battery.chargeState);
    proSimulator.battery.decayRate = proSimulatorData.battery.decayRate;
    proSimulator.battery.state = proSimulatorData.battery.state;
    proSimulator.battery.charge = proSimulatorData.battery.charge;
    proSimulator.battery.charge = proSimulatorData.battery.charge;

    proSimulator.oximeter = new OximeterSimulator();
    proSimulator.oximeter.spo2 = proSimulatorData.oximeter.spo2;
    proSimulator.oximeter.pulse = proSimulatorData.oximeter.pulse;
    proSimulator.oximeter.state = proSimulatorData.oximeter.state;

    proSimulator.oxygenInput = new OxygenInput();
    proSimulator.oxygenInput.value = proSimulatorData.oxygenInput.value;

    return proSimulator;
  } else {
    return null;
  }
}

function loadProSimulatorFromBlob(serializedProSimulator) {
  if (serializedProSimulator) {
    const proSimulatorData = JSON.parse(serializedProSimulator);
    const proSimulator = new ProSimulator(
      proSimulatorData.patient,
      proSimulatorData.location,
      proSimulatorData.oximeterSignalAlarmDelay,
      proSimulatorData.profile
    );
    proSimulator.defaultProfiles = proSimulatorData.defaultProfiles;
    proSimulator.isRunning = proSimulatorData.isRunning;
    proSimulator.pin = proSimulatorData.pin;
    proSimulator.pauseScreenValues = proSimulatorData.pauseScreenValues;

    proSimulator.deviceTime = proSimulatorData.deviceTime;
    proSimulator.adminPIN = proSimulatorData.adminPIN;
    proSimulator.oximeterCounter = proSimulatorData.oximeterCounter;
    proSimulator.listeners = new Set();
    proSimulator.oxygenOutput = proSimulatorData.oxygenOutput;
    proSimulator.alarms = proSimulatorData.alarms;
    proSimulator.mode = proSimulatorData.mode;
    proSimulator.data = proSimulatorData.data;
    proSimulator.testSetup = {...proSimulatorData.testSetup};
    proSimulator.testSetup.timer=null

    proSimulator.battery = new ProBattery();
    proSimulator.battery.setChargeState(proSimulatorData.battery.chargeState);
    proSimulator.battery.decayRate = proSimulatorData.battery.decayRate;
    proSimulator.battery.state = proSimulatorData.battery.state;
    proSimulator.battery.charge = proSimulatorData.battery.charge;

    proSimulator.oximeter = new OximeterSimulator();
    proSimulator.oximeter.spo2 = proSimulatorData.oximeter.spo2;
    proSimulator.oximeter.pulse = proSimulatorData.oximeter.pulse;
    proSimulator.oximeter.state = proSimulatorData.oximeter.state;

    proSimulator.oxygenInput = new OxygenInput();
    proSimulator.oxygenInput.value = proSimulatorData.oxygenInput.value;

    return proSimulator;
  } else {
    return null;
  }
}
