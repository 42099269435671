import { Box, Typography } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DownArrow, UpArrow } from "../UI/Arrows";
import { colors } from "../../Style";
import { translate } from "react-i18nify";
const getLabel=(input)=>{
  if(input==undefined){
    return;
  }
  let label;
    {
    switch(input){
      case "UP": return <UpArrow/> 
      break
      case "DOWN": return <DownArrow/> 
      break
      default:
        label=input

    }
  }
  return(
    translate(label)
  )
}
export const NavigationMenu = (props) => {

  let menu=props.navigation.menu
  return (
    <Box sx={{width: "100%", height: "100%" }}>
      <Box sx={{ width: "100%", height: "33.3%",display:"flex",justifyContent:"center" }}>
        <Box sx={{ width: "33.3%", height: "100%",display:"flex",justifyContent:"center" }}><Typography sx={{color:colors.TEXT_COLOR}}>{getLabel(menu?.UP?.label)}</Typography></Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "33.3%",
          display: "flex",
        }}
      >
        <Box sx={{ width: "33.3%", height: "100%" ,display:"flex",justifyContent:"center" }}><Typography sx={{color:colors.TEXT_COLOR}}>{getLabel(menu?.LEFT?.label)}</Typography></Box>
        <Box
          sx={{ width: "33.3%", height: "100%" ,display:"flex",justifyContent:"center"}}
        >
          <Typography sx={{color:colors.TEXT_COLOR}}>{getLabel(menu?.MIDDLE?.label)}</Typography>

        </Box>
        <Box sx={{ width: "33.3%", height: "100%" ,display:"flex",justifyContent:"center"}}><Typography sx={{color:colors.TEXT_COLOR}}>{getLabel(menu?.RIGHT?.label)}</Typography></Box>
      </Box>
      <Box sx={{ width: "100%", height: "33.3%",display:"flex",justifyContent:"center" }}>
        <Box sx={{ width: "33.3%", height: "100%" ,display:"flex",justifyContent:"center"}}><Typography sx={{color:colors.TEXT_COLOR}}>{getLabel(menu?.DOWN?.label)}</Typography></Box>
      </Box>
    </Box>
  );
};
