import { MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { Translate } from "react-i18nify";
import { colors } from "../Style";
import { SideBarTitle } from "./UI/SideBarTitle";

export const OximeterInputState = {
  NO_OXYGEN: "No oxygen",
  OXYGEN_CONNECTED: "Oxygen connected",
};

export const OxygenInput = (props) => {
  const [boxStatus, setBoxStatus] = useState(false);
  const toggleBox = () => {
    setBoxStatus((boxStatus) => !boxStatus);
  };

  const oxygenInput = props.oxygenInput;
  const setOximeterState = (value) => {
    oxygenInput.setConnectedState(value);
  };

  const renderControls = () => {
    return (
      <Box>

        <SideBarTitle onClick={() => toggleBox()}>
          <Typography sx={{ ml: 2, color: colors.TEXT_COLOR }}>
            <Translate value="O2Input" />
          </Typography>
        </SideBarTitle>

        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center"
          }}
        >
          {/* <Typography sx={{ color: colors.TEXT_COLOR }}>
            <Translate value="O2" />:{oxygenInput?.value}
            <Translate value="l/min" />
          </Typography> */}
          <Box
            sx={{
              display: "flex",
              width: "100%",
              mt: 1,
            }}
          >
            <Select
              onChange={(event) => {
                setOximeterState(event.target.value);
              }}
              value={oxygenInput.state}
              sx={{ width: "75%", height: "20px", background: "white" }}
            >
              <MenuItem value={OximeterInputState.NO_OXYGEN}>
                {OximeterInputState.NO_OXYGEN}
              </MenuItem>
              <MenuItem value={OximeterInputState.OXYGEN_CONNECTED}>
                {OximeterInputState.OXYGEN_CONNECTED}
              </MenuItem>
            </Select>
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <Box sx={{ position: "relative", top: 0 }}>
      <Box
        onClick={toggleBox}
        sx={{ width: "50px", height: "100%", position: "absolute", zIndex: 10 }}
      ></Box>
      <Box
        sx={{
          left: `calc(0% - ${!boxStatus ? "0" : "200"}px)`,
          width: `${!boxStatus ? "50" : "250"}px`,
          height: "170px",
          background: "black",
          p: 1,
          border: "1px solid white",
          position: "relative",
        }}
      >
        {boxStatus ? renderControls() : null}
      </Box>
      <Typography
        variant="h4"
        sx={{
          whiteSpace: "nowrap",
          userSelect: "none",
          position: "absolute",
          transform: "rotate(90deg)",
          top: "10px",
          left: "50px",
          transformOrigin: "0 0",
          color: colors.TEXT_COLOR,
        }}
      >
        <Translate value="O2Input" />
      </Typography>
    </Box>
  );
};
