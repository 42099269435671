import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import hearth from "../../assets/ic_hearth.svg";
import { OximeterState } from "../../ProSimulator/OximeterSimulator";
import { colors } from "../../Style";
import { keyframes } from "@mui/system";
import { getHighestSeverityAlarm } from "../../ProSimulator/Alarm";
import { ALARM_CODE } from "../../ProSimulator/Alarm";
import { useState, useEffect } from "react";
import { blink } from "../../Style";
import { Translate, translate } from "react-i18nify";
export const MiddleComponent = (props) => {
  let oximeter = props.pro?.oximeter;

  const [oximeterAlarm, setOximeterAlarm] = useState("");
  const [oximeterAlarmCritical, setOximeterAlarmCritical] = useState(false);
  const [noSignalColor, setNoSignalColor] = useState("white");
  const [pulseColor, setPulseColor] = useState("white");
  const [batteryFail, setBatteryFail] = useState(false);
  const getTextColorFromAlarm = (alarm) => {
    switch (alarm?.alarmCode) {
      case ALARM_CODE.CRITICALLY_HIGH_PULSE:
        return colors.COLORS_ALARM_HIGH;
      case ALARM_CODE.CRITICALLY_LOW_PULSE:
        return colors.COLORS_ALARM_HIGH;
      default:
        return "white";
    }
  };

  useEffect(() => {
    let pulseAlarm = getHighestSeverityAlarm(props.pro?.alarms, [
      ALARM_CODE.CRITICALLY_HIGH_PULSE,
      ALARM_CODE.CRITICALLY_LOW_PULSE,
    ]);
    setPulseColor(getTextColorFromAlarm(pulseAlarm));

    let batteryFail = getHighestSeverityAlarm(props.pro?.alarms, [
      ALARM_CODE.BATTERY_FAIL,
    ]);
    setBatteryFail(batteryFail != 0);

    let oximeterAlarm = getHighestSeverityAlarm(props.pro?.alarms, [
      ALARM_CODE.OXIMETER_NOT_CONNECTED,
    ]);
    if (oximeterAlarm != 0) {
      setOximeterAlarmCritical(true);
      setOximeterAlarm(translate("NotConnected"));
      return;
    }
    oximeterAlarm = getHighestSeverityAlarm(props.pro?.alarms, [
      ALARM_CODE.OXIMETER_NO_SIGNAL,
    ]);
    if (oximeterAlarm != 0) {
      setOximeterAlarmCritical(true);
      setOximeterAlarm(translate("NoSignal"));
      return;
    }
    oximeterAlarm = getHighestSeverityAlarm(props.pro?.alarms, [
      ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_0,
      ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_1,
      ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_5,
      ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_15,
      ALARM_CODE.OXIMETER_NO_SIGNAL_DELAY_30,
    ]);
    if (oximeterAlarm != 0) {
      setOximeterAlarmCritical(false);
      setOximeterAlarm(translate("NoSignal"));
      return;
    }
    setOximeterAlarmCritical(false);
    setOximeterAlarm("");
  }, [props.pro?.alarms]);

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box sx={{ width: "100%", height: "20%" }}>
        <Box
          sx={{
            width: "100%",
            height: "50%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: oximeterAlarmCritical
                ? colors.COLORS_ALARM_HIGH
                : colors.COLORS_ALARM_MEDIUM,
              animation: oximeterAlarmCritical
                ? `${blink} 0.5s infinite`
                : null,
              fontWeight: "bold",
            }}
          >
            {oximeterAlarm == "" ? null : oximeterAlarm}
          </Typography>
        </Box>
        {props.pro?.formating?.isFormating ? (
          <Box
            sx={{

              width: "100%",
              height: "50%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{ fontWeight: "bold", color: colors.COLORS_ALARM_LOW }}
            >
              Formating...
            </Typography>
          </Box>
        ) : (
          <Box
            sx={{
              visibility: batteryFail ? "visible" : "hidden",
              width: "100%",
              height: "50%",
              display: "flex",
              justifyContent: "center",
              animation: `${blink} 0.5s infinite`,
            }}
          >
            <Typography
              sx={{ fontWeight: "bold", color: colors.COLORS_ALARM_HIGH }}
            >
              Battery fail
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "26%",
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src={hearth}
          alt="Pulse"
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
          }}
        />
        <Typography
          variant="h5"
          sx={{
            color: pulseColor,
            fontWeight: "bold",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
          }}
        >
          {oximeter?.state != OximeterState.SIGNAL_DATA
            ? "---"
            : oximeter?.pulse}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 1,
          width: "100%",
          height: "10%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: pulseColor,
            visibility: pulseColor == "white" ? "hidden" : "vissible",
            fontWeight: "bold",
            color: colors.COLORS_ALARM_HIGH,
            animation: `${blink} 0.5s infinite`,
          }}
        >
          Pulse
        </Typography>
      </Box>
      <Box
        sx={{
          display: props.isManual ? "flex" : "none",
          alignItems: "end",
          height: "34%",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            alignSelf: "end",
            color: pulseColor,
            fontWeight: "bold",
            maxLines: 1,
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            color: colors.COLORS_ALARM_LOW,
          }}
        >
          <Translate value="NoPatientAlarms" />
        </Typography>
      </Box>
    </Box>
  );
};
