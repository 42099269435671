import { Box, Typography } from "@mui/material";
import { useQuery } from "../../Utils";
import { ItemMenu } from "../../components/UI/ItemMenu";
import { useHistory, useLocation } from "react-router";
import { useEffect, useState } from "react";
import { usePageState, defaultReducer } from "../../Utils";
import { GetWindowState } from "../../Utils";
import {
  CustomizeProfile,
  isProfileCustom,
} from "../../components/UI/CustomizeProfile";
import { Translate, translate } from "react-i18nify";
import { EDIT_PROFILE_MODE } from "../../components/UI/SelectProfileMenu";
import { ConfirmRiskyProfile } from "../../components/UI/ConfirmRiskyProfile";
import { PopUpWrapper } from "../../components/PopUpWrapper";
import { colors } from "../../Style";
import { FLOW_RESPONSE } from "../../ProSimulator/ProSimulator";
const findActiveIndex = (value, values) => {
  return values.indexOf(value);
};
const findHighestId = (values) => {
  return values.reduce((highest, obj) => {
    return obj.id > highest ? obj.id : highest;
  }, 0);
};

const updateEditNavigation = (newValue, activeLetter) => {
  let menu = {};
  if (activeLetter == 0) {
    menu = {
      LEFT: { label: "Undo", route: "NAVIGATE_UNDO", local: true },
      UP: { label: "UP", route: "VALUE_UP", local: true },
      RIGHT: { label: "Next", route: "NAVIGATE_NEXT", local: true },
      MIDDLE: { label: "Value" },
      DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
    };
  } else if (
    activeLetter == newValue.toString().length - 1 &&
    newValue.toString()[activeLetter] === " "
  ) {
    menu = {
      LEFT: { label: "Previous", route: "NAVIGATE_PREVIOUS", local: true },
      UP: { label: "UP", route: "VALUE_UP", local: true },
      RIGHT: { label: "Save", route: "NAVIGATE_SAVE", local: true },
      MIDDLE: { label: "Value" },
      DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
    };
  } else if (activeLetter >= newValue.toString().length) {
    menu = {
      LEFT: { label: "Previous", route: "NAVIGATE_PREVIOUS", local: true },
      UP: { label: "UP", route: "VALUE_UP", local: true },
      RIGHT: { label: "Save", route: "NAVIGATE_SAVE", local: true },
      MIDDLE: { label: "Value" },
      DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
    };
  } else {
    menu = {
      LEFT: { label: "Previous", route: "NAVIGATE_PREVIOUS", local: true },
      UP: { label: "UP", route: "VALUE_UP", local: true },
      RIGHT: { label: "Next", route: "NAVIGATE_NEXT", local: true },
      MIDDLE: { label: "Value" },
      DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
    };
  }

  return menu;
};
const SELECTED_MENU = {
  MAIN_MENU: "MAIN_MENU",
  PROFILE_MENU: "PROFILE_MENU",
};

const defaultNewProfile = {
  name: "Set Name",
  minSpO2: 88,
  maxSpO2: 92,
  minO2: 2.0,
  maxO2: 7.0,
  initialO2: 2.0,
  minPulse: 40,
  maxPulse: 130,
  flowResponse: 20,
};

export const NewProfile = (props) => {
  let query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const state = GetWindowState();
  let profileId = query.get("profile");

  const defaultMenu = {
    LEFT: { label: "Back", route: "profiles", local: true },
    UP: null,
    RIGHT: { label: "Select", route: "select", local: true },
    MIDDLE: null,
    DOWN: { label: "DOWN", route: "down", local: true },
  };

  const getProfileData = (isNew, id, profiles) => {
    if (isNewProfile) {
      return defaultNewProfile;
    } else {
      return profiles.find((profile) => profile.id == id);
    }
  };
  const generateTopMenuItems = (profile) => {
    return [
      { label: "Name", value: profile.name, editable: true },
      { label: "Save" },
      {
        label: "Delete",
        window: "confirmDelete",
        labelColor: colors.COLORS_ALARM_LOW,
      },
    ];
  };
  let isNewProfile = profileId == "new" ? true : false;
  let profile = getProfileData(
    isNewProfile,
    profileId,
    props?.pro?.defaultProfiles
  );

  const [popup, setPopUp] = useState(null);
  const [pageState, setPageState] = usePageState(
    defaultReducer,
    state
      ? state
      : {
          menu: { ...defaultMenu },
          window: null,
          windowName: null,
          activeItemIndex: 0,
          valueEditing: null,
          updatedProfile: JSON.parse(JSON.stringify(profile)),
          originalProfile: JSON.parse(JSON.stringify(profile)),
          selectedMenu: SELECTED_MENU.MAIN_MENU,
          menuItems: generateTopMenuItems(profile),
          profileMenuItems: generateProfileValues(
            getProfileData(isNewProfile, profileId, props?.pro?.defaultProfiles)
          ),
        }
  );
  const setWindowState = (state, windowState) => {
    setPageState((prev) => {
      return {
        ...prev,
        window: { ...prev.window, state: windowState, menu: state },
      };
    });
    props.navigation.setMenu(state);
  };
  const updateNavigation = () => {
    setPageState((old) => {
      if (old.menuItems == null || old.menu == null) {
        return old;
      }
      let showUpButton;
      let showDownButton;
      if (old.selectedMenu == SELECTED_MENU.MAIN_MENU) {
        showUpButton = old.activeItemIndex > 0;
        showDownButton =
          old.activeItemIndex <
          old.menuItems.length + old.profileMenuItems.length - 1;
      }
      if (old.selectedMenu == SELECTED_MENU.PROFILE_MENU) {
        showUpButton = true;
        showDownButton = old.activeItemIndex < old.profileMenuItems.length - 1;
        if(old.profileMenuItems[old.activeItemIndex].notSelectable){
          old.menu.RIGHT = null;
        }else{
          old.menu.RIGHT = {
            label: "Select",
            route: "NAVIGATE_SELECT",
            local: true,
          };
        }

        old.menu.UP = showUpButton
          ? { label: "UP", route: "UP", local: true }
          : null;
        old.menu.DOWN = showDownButton
          ? { label: "DOWN", route: "DOWN", local: true }
          : null;
        // if (old.customizeProfile.state.editProfileValueMode == "EDIT_VALUE") {
        //   old.menu.UP = showUpButton
        //     ? { label: "UP", route: "VALUE_UP", local: true }
        //     : null;
        //     old.MIDDLE = { label: "Value" };
        //   old.menu.DOWN = showDownButton
        //     ? { label: "DOWN", route: "VALUE_DOWN", local: true }
        //     : null;
        // }
      } else {
        old.menu.UP = showUpButton
          ? { label: "UP", route: "UP", local: true }
          : null;
        old.menu.DOWN = showDownButton
          ? { label: "DOWN", route: "DOWN", local: true }
          : null;
      }

      return old;
    });
  };
  const updateNavigationEditValue = () => {
    setPageState((old) => {
      if (old.menuItems == null || old.menu == null) {
        return old;
      }
      let showUpButton;
      let showDownButton;
      if (old.selectedMenu == SELECTED_MENU.PROFILE_MENU) {
        showUpButton = true;
        showDownButton = old.activeItemIndex < old.profileMenuItems.length - 1;
        if (old.customizeProfile.state.editProfileValueMode != "EDIT_VALUE") {
          old.menu.UP = showUpButton
            ? { label: "UP", route: "VALUE_UP", local: true }
            : null;
          old.menu.RIGHT = {
            label: "Select",
            route: "NAVIGATE_SELECT",
            local: true,
          };
          old.menu.LEFT = {
            label: "Back",
            route: "profiles",
            local: true,
          };
          old.menu.DOWN = showDownButton
            ? { label: "DOWN", route: "VALUE_DOWN", local: true }
            : null;
        }
        // if (old.customizeProfile.state.editProfileValueMode == "EDIT_VALUE") {
        //   old.menu.UP = showUpButton
        //     ? { label: "UP", route: "VALUE_UP", local: true }
        //     : null;
        //   old.menu.DOWN = showDownButton
        //     ? { label: "DOWN", route: "VALUE_DOWN", local: true }
        //     : null;
        //   old.MIDDLE = { label: "Value" };
        //   old.menu.LEFT = {
        //     label: "Undo",
        //     route: "NAVIGATE_UNDO",
        //     local: true,
        //   };
        // }
        old.menu = old.customizeProfile.menu;
      }

      return old;
    });
  };
  const setEditProfileState = (state, windowState) => {
    setPageState((prev) => {
      return {
        ...prev,
        customizeProfile: { ...prev.window, state: windowState, menu: state },
      };
    });
    props.navigation.setMenu(state);
  };
  const localNavigation = (navigator, action, menu) => {
    console.log({ pageState: pageState });

    if (
      pageState.window?.state?.windowNavigation != null &&
      pageState.window.state.editProfileValueMode !=
        EDIT_PROFILE_MODE.MODE_DEFAULT
    ) {
      console.log("executing window naw");
      pageState.window?.state?.windowNavigation(
        navigator,
        action,
        menu,
        pageState?.window?.state
      );
      return;
    }
    if (pageState.valueEditing != null) {
      console.log("value editing");
      if (action.type == "CLICK") {
        switch (menu[action.button].route) {
          // case "NAVIGATE_CANCEL":
          //   cancelClicked(state);
          //   break;
          // case "NAVIGATE_EDIT":
          //   editClicked(state);
          //   break;
          // case "NAVIGATE_CLEAR":
          //   clearClicked(state);
          //   break;
          case "NAVIGATE_NEXT":
            nextClicked(state);
            break;
          case "NAVIGATE_PREVIOUS":
            previousClicked(state);
            break;
          case "NAVIGATE_SAVE":
            saveClicked(state);
            break;
          // case "NAVIGATE_ACCEPT":
          //   acceptClicked(state);
          //   break;
          case "NAVIGATE_UNDO":
            undoClicked(state);
            break;
          case "VALUE_UP":
            valueUp(state);
            break;
          case "VALUE_DOWN":
            valueDown(state);
            break;
        }
      }
      return;
    }

    if (action.type == "CLICK") {
      if (
        pageState.selectedMenu == SELECTED_MENU.PROFILE_MENU &&
        pageState.customizeProfile.state.editProfileValueMode == "EDIT_VALUE"
      ) {
        if (pageState.selectedMenu == SELECTED_MENU.PROFILE_MENU) {
          console.log("executing window naw");
          pageState.customizeProfile?.state?.windowNavigation(
            navigator,
            action,
            menu,
            pageState?.customizeProfile?.state
          );
        }
        return;
      }

      switch (action.button) {
        case "DOWN":
          handleDown(navigator, action, menu);
          break;
        case "LEFT":
          handleLeft(navigator, action, menu);
          break;
        case "UP":
          handleUp(navigator, action, menu);
          break;
        case "RIGHT":
          handleRight(navigator, action, menu);
          break;
      }
    }
  };

  const nextClicked = (state) => {
    setPageState((prev) => {
      let menu = updateEditNavigation(
        prev.valueEditing.value,
        prev.valueEditing.activeIndex + 1
      );
      props.navigation.setMenu(menu);
      return {
        ...prev,
        valueEditing: {
          ...prev.valueEditing,
          activeIndex: prev.valueEditing.activeIndex + 1,
        },
      };
    });
  };
  const previousClicked = (state) => {
    setPageState((prev) => {
      return {
        ...prev,
        valueEditing: {
          ...prev.valueEditing,
          activeIndex: prev.valueEditing.activeIndex - 1,
        },
        menu: updateEditNavigation(
          prev.menuItems[prev.activeItemIndex].value,
          prev.valueEditing.activeIndex - 1
        ),
      };
    });
  };

  const valueUp = (state) => {
    setPageState((prev) => {
      let newValue = cycleCharacter(
        "up",
        prev.valueEditing.value,
        prev.valueEditing.possibleValues,
        prev.valueEditing.activeIndex
      );

      return {
        ...prev,
        valueEditing: { ...prev.valueEditing, value: newValue },
        menu: updateEditNavigation(newValue, prev.valueEditing.activeIndex),
      };
    });
  };

  const valueDown = (state) => {
    setPageState((prev) => {
      let newValue = cycleCharacter(
        "down",
        prev.valueEditing.value,
        prev.valueEditing.possibleValues,
        prev.valueEditing.activeIndex
      );

      return {
        ...prev,
        valueEditing: { ...prev.valueEditing, value: newValue },
        menu: updateEditNavigation(newValue, prev.valueEditing.activeIndex),
      };
    });
  };

  const saveClicked = (state) => {
    setPageState((prev) => {
      let menuItems = [...prev.menuItems];
      menuItems[pageState.activeItemIndex].isEditing = false;
      menuItems[pageState.activeItemIndex].value = prev.valueEditing.value;
      let updatedProfile = { ...prev.updatedProfile };
      updatedProfile.name = prev.valueEditing.value;
      return {
        ...prev,
        valueEditing: null,
        menuItems: menuItems,
        menu: defaultMenu,
        updatedProfile: updatedProfile,
      };
    });
  };

  const undoClicked = (state) => {
    setPageState((prev) => {
      let menuItems = [...prev.menuItems];
      menuItems[pageState.activeItemIndex].isEditing = false;
      return {
        ...prev,
        valueEditing: null,
        menuItems: menuItems,
      };
    });
  };
  const handleUp = (navigator, action, menu) => {
    if (pageState.window != null) {
      return;
    }
    setPageState((prev) => {
      let prevIndex = prev.activeItemIndex;
      let menuItems = prev.menuItems;
      let profileMenuItems = prev.profileMenuItems;
      let selectedMenu = prev.selectedMenu;
      let newActiveIndex;
      let newSelectedMenu = prev.selectedMenu;
      if (selectedMenu == SELECTED_MENU.PROFILE_MENU) {
        newActiveIndex = prevIndex - 1;
        if (newActiveIndex == -1) {
          newSelectedMenu = SELECTED_MENU.MAIN_MENU;
          newActiveIndex = menuItems.length - 1;
        }
      } else {
        newActiveIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
      }

      //   let newActiveIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
      return {
        ...prev,
        activeItemIndex: newActiveIndex,
        selectedMenu: newSelectedMenu,
        customizeProfile: {
          ...prev?.customizeProfile,
          state: {
            ...prev?.customizeProfile?.state,
            disabled: newSelectedMenu == SELECTED_MENU.MAIN_MENU ? true : false,
            editActiveIndex: newActiveIndex,
          },
        },
      };
    });
  };
  const handleDown = (navigator, action, menu) => {
    if (
      pageState.window != null &&
      pageState.window.state.editProfileValueMode !=
        EDIT_PROFILE_MODE.MODE_DEFAULT
    ) {
      return;
    }

    setPageState((prev) => {
      let prevIndex = prev.activeItemIndex;
      let menuItems = prev.menuItems;
      let profileMenuItems = prev.profileMenuItems;
      let selectedMenu = prev.selectedMenu;
      let newActiveIndex;
      let newSelectedMenu = prev.selectedMenu;
      if (selectedMenu == SELECTED_MENU.MAIN_MENU) {
        newActiveIndex = prevIndex + 1;
        if (newActiveIndex == menuItems.length) {
          newSelectedMenu = SELECTED_MENU.PROFILE_MENU;
          newActiveIndex = 0;
        }
      } else {
        newActiveIndex =
          prevIndex < profileMenuItems.length - 1 ? prevIndex + 1 : prevIndex;
      }

      return {
        ...prev,
        activeItemIndex: newActiveIndex,
        selectedMenu: newSelectedMenu,
        customizeProfile: {
          ...prev?.customizeProfile,
          state: {
            ...prev?.customizeProfile?.state,
            disabled:
              newSelectedMenu == SELECTED_MENU.PROFILE_MENU ? false : true,
            editActiveIndex: newActiveIndex,
          },
        },
      };
    });
  };

  const handleLeft = (navigator, action, menu) => {
    if (pageState.window != null) {
      // if (menu[action.button].route == "NAVIGATE_EDIT")
      //   setPageState((prevState) => {
      //     return {
      //       ...prevState,
      //       window: { ...prevState.window, mode: StringInputMODE.MODE_EDIT },
      //     };
      //   });

      return;
    }
    console.log({ pageStateBack: pageState });
    let originalProfile = profile;
    let newProfile = pageState.customizeProfile.state.updatedProfile
      ? pageState.customizeProfile.state.updatedProfile
      : pageState.updatedProfile;
    newProfile.name = pageState.updatedProfile.name;
    if (isProfileCustom(pageState.originalProfile, newProfile)) {
      // ask to discard
      setPageState((prev) => {
        return {
          ...prev,
          windowName: "confirmDiscard",
          window: null,
        };
      });
      return;
    }
    history.push("profiles");
  };

  const handleRight = (navigator, action, menu) => {
    if (
      pageState.window != null ||
      pageState.window?.name != null ||
      pageState.window?.name != undefined
    ) {
      // if (menu[action.button].route == "NAVIGATE_EDIT")
      //   setPageState((prevState) => {
      //     return {
      //       ...prevState,
      //       window: { ...prevState.window, mode: StringInputMODE.MODE_EDIT },
      //     };
      //   });

      return;
    }
    if (
      pageState.menuItems.length>pageState.activeItemIndex&&
      pageState.menuItems[pageState.activeItemIndex].editable &&
      pageState.selectedMenu == SELECTED_MENU.MAIN_MENU
    ) {
      setPageState((prevState) => {
        let menuItems = [...prevState.menuItems];
        menuItems[pageState.activeItemIndex].isEditing = true;
        let menu = updateEditNavigation(
          menuItems[pageState.activeItemIndex].value.toString(),
          0
        );
        props.navigation.setMenu(menu);
        return {
          menu: menu,
          ...prevState,
          valueEditing: {
            value:
              pageState.menuItems[pageState.activeItemIndex].value == "Set Name"
                ? ""
                : pageState.menuItems[pageState.activeItemIndex].value,
            possibleValues: [
              "A",
              "B",
              "C",
              "D",
              "E",
              "F",
              "G",
              "H",
              "I",
              "J",
              "K",
              "L",
              "M",
              "N",
              "O",
              "P",
              "Q",
              "R",
              "S",
              "T",
              "U",
              "V",
              "W",
              "X",
              "Y",
              "Z",
              "0",
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "_",
              "-",
              " ",
            ],
            activeIndex: 0,
          },
          menuItems: menuItems,
        };
      });
      return;
    } else if (pageState.selectedMenu == SELECTED_MENU.PROFILE_MENU) {
      console.log("executing window naw");
      pageState.customizeProfile?.state?.windowNavigation(
        navigator,
        action,
        menu,
        pageState?.customizeProfile?.state
      );
      return;
    } else if (
      pageState.menuItems[pageState.activeItemIndex].label == "Save" &&
      pageState.selectedMenu == SELECTED_MENU.MAIN_MENU
    ) {
      //save
      console.log({ pageStateSave: pageState });
      let newProfile = pageState.customizeProfile.state.updatedProfile
        ? pageState.customizeProfile.state.updatedProfile
        : pageState.updatedProfile;
      newProfile.name = pageState.updatedProfile.name;
      if (newProfile.id == null || newProfile.id == undefined) {
        newProfile.id = findHighestId(props?.pro?.defaultProfiles) + 1;
        props?.pro.addTreatmentProfile(newProfile);
        history.push("profiles");
        return;
      }
      props?.pro.modifyTreatmentProfile(newProfile);
      history.push("profiles");
      return;
    }

    setPageState((prevState) => {
      return {
        ...prevState,
        windowName: prevState.menuItems[prevState.activeItemIndex].window,
        window: {},
      };
    });
  };

  const renderPopUp = (state) => {
    let popUp = null;
    switch (state.windowName) {
      case "confirmDelete":
        popUp = (
          <ConfirmRiskyProfile
            message={
              <Typography variant="h6" sx={{ color: colors.COLORS_ALARM_LOW }}>
                <Translate value={"DeleteProfile?"} />
              </Typography>
            }
            name={"confirmDelete"}
            onComplete={(state) => {
              console.log("confirmRiskyProfile accept");
              console.log({ state: state });
              props.pro.removeTreatmentProfile(pageState.updatedProfile);
              history.push("profiles");
            }}
            onCancel={() => {
              setPageState((prev) => {
                return {
                  ...prev,
                  windowName: null,
                  window: null,
                };
              });
            }}
            setState={setWindowState}
            menu={state?.window?.menu}
            state={state?.window?.state}
          />
        );
        break;
      case "confirmDiscard":
        popUp = (
          <ConfirmRiskyProfile
            message={
              <Typography variant="h6" sx={{ color: colors.COLORS_ALARM_LOW }}>
                <Translate value={"DiscardChanges?"} />
              </Typography>
            }
            name={"confirmDiscard"}
            onComplete={(state) => {
              history.push("profiles");
            }}
            onCancel={() => {
              setPageState((prev) => {
                return {
                  ...prev,
                  windowName: null,
                  window: null,
                };
              });
            }}
            setState={setWindowState}
            menu={state?.window?.menu}
            state={state?.window?.state}
          />
        );
        break;
    }
    return popUp;
  };

  useEffect(() => {
    if (pageState.valueEditing != null) {
      return;
    }
    updateNavigation();
  }, [pageState.activeItemIndex]);

  useEffect(() => {
    if (pageState.valueEditing != null) {
      return;
    }
    updateNavigationEditValue();
  }, [pageState?.customizeProfile?.state?.editProfileValueMode]);
  useEffect(() => {
    props.navigation.setMenu(pageState.menu);
  }, [pageState.menu]);

  useEffect(() => {
    const state = pageState;
    // if(state.window){
    //   state.window.menu=null;
    // }

    const searchParams = new URLSearchParams();
    searchParams.append("windowState", JSON.stringify(state));
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
    if (
      pageState?.window?.menu == null &&
      pageState?.window?.menu == undefined &&
      pageState?.valueEditing == null &&
      pageState?.valueEditing == undefined
    ) {
      props.navigation.setMenu(pageState.menu);
    }
    props.navigation.addLocalNavigation(localNavigation);
    return () => {
      props.navigation.addLocalNavigation(null);
    };
  }, [pageState]);
  useEffect(() => {
    let pop = renderPopUp(pageState);
    setPopUp(pop);
  }, [pageState.windowName, pageState.window]);

  return (
    <PopUpWrapper popup={popup}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
        }}
      >
        <ItemMenu
          margin={5}
          valueEditing={pageState.valueEditing}
          activeItemIndex={pageState.activeItemIndex}
          width="60%"
          menuItems={pageState.menuItems}
          disabled={pageState.selectedMenu != SELECTED_MENU.MAIN_MENU}
        />
        <Box
          sx={{
            mt: 2,
            mb: 2,
            width: "60%",
            height: "2px",
            background: "white",
          }}
        />
        <CustomizeProfile
          width={"40%"}
          empty={true}
          modifyNavigation={true}
          disabled={pageState.selectedMenu != SELECTED_MENU.PROFILE_MENU}
          name={"customizeProfile"}
          label={translate("CustomizeProfile")}
          onComplete={(profile) => {
            if (isRiskyProfile(profile)) {
              profile.isRisky = true;
              setPageState((prev) => {
                return {
                  ...prev,
                  windowName: "confirmRiskyProfile",
                  window: null,
                  riskyProfile: profile,
                };
              });
            } else {
              profile.isRisky = false;
              props.pro.setProfile(profile);
              setPageState((prev) => {
                return {
                  ...prev,
                  windowName: null,
                  window: null,
                  updated: null,
                };
              });
            }
          }}
          onCancel={() => {
            setPageState((prev) => {
              return {
                ...prev,
                windowName: null,
                window: null,
              };
            });
          }}
          value={{ ...pageState.updatedProfile }}
          format={(data) => data}
          setState={setEditProfileState}
          menu={pageState?.menu}
          state={pageState?.customizeProfile?.state}
        />
      </Box>
    </PopUpWrapper>
  );
};

export const generateProfileValues = (profile) => {
  let profileValues = [
    {
      label: "Min SpO2",
      value: profile.minSpO2,
      editName: "minSpO2",
      labelError: profile.error?.minSpO2?.labelError ? true : false,
      valueError: profile.error?.minSpO2?.valueError ? true : false,
      risky: profile.error?.minSpO2?.risky ? true : false,
      unit: "%",
    },
    {
      label: "Max SpO2",
      value: profile.maxSpO2,
      editName: "maxSpO2",
      labelError: profile.error?.maxSpO2?.labelError ? true : false,
      valueError: profile.error?.maxSpO2?.valueError ? true : false,
      unit: "%",
    },
    {
      label: "Min O2 Flow",
      value: profile.minO2,
      editName: "minO2",
      labelError: profile.error?.minO2?.labelError ? true : false,
      valueError: profile.error?.minO2?.valueError ? true : false,
      unit: "l/min",
    },
    {
      label: "Max O2 Flow",
      value: profile.maxO2,
      editName: "maxO2",
      labelError: profile.error?.maxO2?.labelError ? true : false,
      valueError: profile.error?.maxO2?.valueError ? true : false,
      unit: "l/min",
    },
    {
      label: "Initial O2",
      value: profile.initialO2,
      editName: "initialO2",
      labelError: profile.error?.initialO2?.labelError ? true : false,
      valueError: profile.error?.initialO2?.valueError ? true : false,
      unit: "l/min",
    },
    {
      label: "Min Pulse Rate",
      value: profile.minPulse,
      editName: "minPulse",
      labelError: profile.error?.minPulse?.labelError ? true : false,
      valueError: profile.error?.minPulse?.valueError ? true : false,
      unit: "bpm",
    },
    {
      label: "Max Pulse Rate",
      value: profile.maxPulse,
      editName: "maxPulse",
      labelError: profile.error?.maxPulse?.labelError ? true : false,
      valueError: profile.error?.maxPulse?.valueError ? true : false,
      risky: profile.error?.maxPulse?.risky ? true : false,
      unit: "bpm",
    },
    {
      label: "Flow response",
      value: FLOW_RESPONSE[profile.flowResponse],
      editName: "flowResponse",
      notSelectable: true,
      labelError: profile.error?.flowResponse?.labelError ? true : false,
      valueError: profile.error?.flowResponse?.valueError ? true : false,
    }
  ];
  return profileValues;
};
export const cycleCharacter = (direction, value, characters, activeIndex) => {
  // get the current character at the active index
  value = value.toString();
  let currentChar = value[activeIndex];

  // find the index of the current character in the characters array
  let currentIndex = characters.indexOf(currentChar);

  // calculate the new index based on the direction
  let newIndex;
  if (direction === "up") {
    newIndex = currentIndex + 1;
    if (newIndex === characters.length) {
      newIndex = 0;
    }
  } else if (direction === "down") {
    newIndex = currentIndex - 1;
    if (newIndex < 0) {
      newIndex = characters.length - 1;
    }
  } else {
    return value; // invalid direction, return original value
  }

  // update the value at the active index with the new character
  let newValue =
    value.slice(0, activeIndex) +
    characters[newIndex] +
    value.slice(activeIndex + 1);

  // if the activeIndex is not the last index and the new character is a space, skip it
  if (activeIndex < value.length - 1 && characters[newIndex] === " ") {
    return cycleCharacter(direction, newValue, characters, activeIndex);
  }

  return newValue;
};
const isRiskyProfile = (profile) => {
  if (profile.minSpO2 < 85 || profile.maxPulse > 150) {
    return true;
  }
  return false;
};
