import { useEffect, useState } from "react";
import { ItemMenu } from "../../components/UI/ItemMenu";
import { useHistory, createSearchParams, useLocation } from "react-router-dom";
import { defaultReducer, usePageState, useQuery } from "../../Utils";
import { PopUpWrapper } from "../../components/PopUpWrapper";
import {
  StringInputMODE,
  StringInputMenu,
} from "../../components/UI/StringInputMenu";
import { DeviceStatusMenu } from "../../components/UI/DeviceStatusMenu";

import { GetWindowState } from "../../Utils";
import { Box, Typography } from "@mui/material";
import { colors } from "../../Style";
import { Tests } from "../../components/UI/Tests";
import { Test } from "../../components/UI/Test";

export const Admin = (props) => {
  const history = useHistory();
  const location = useLocation();
  const state = GetWindowState();

  const [popup, setPopUp] = useState(null);
  const [pageState, setPageState] = usePageState(
    defaultReducer,
    state
      ? state
      : {
          menu: {
            LEFT: { label: "Back", route: "home" },
            UP: null,
            RIGHT: { label: "Select", route: "select", local: true },
            MIDDLE: null,
            DOWN: { label: "Down", route: "down", local: true },
          },
          window: null,
          activeItemIndex: 0,
          menuItems: generateMenuItems(),
        }
  );

  const setWindowTwoState = (state, windowState) => {
    setPageState((prev) => {
      return {
        ...prev,
        windowTwo: { ...prev.windowTwo, state: windowState, menu: state },
      };
    });
    props.navigation.setMenu(state);
  };


  const setWindowState = (state, windowState) => {
    setPageState((prev) => {
      return {
        ...prev,
        window: { ...prev.window, state: windowState, menu: state },
      };
    });
    props.navigation.setMenu(state);
  };

  const renderPopUp = (state) => {
    let popUp = null;
    switch (state.window?.name) {
      case "profiles":
        popUp = (
          <StringInputMenu
            name={"profiles"}
            setState={setWindowState}
            state={state}
          />
        );
        break;
      case "deviceStatus":
        popUp = (
          <DeviceStatusMenu
            name={"deviceStatus"}
            setState={setWindowState}
            menu={state?.window?.menu}
            state={state?.window?.state}
            onCancel={() => {
              setPageState((prev) => {
                return {
                  ...prev,
                  windowName: null,
                  window: null,
                };
              });
            }}
          />
        );
        break;
      case "tests":
        popUp = (
          <Tests
            name={"deviceStatus"}
            setState={setWindowState}
            menu={state?.window?.menu}
            state={state?.window?.state}
            onComplete={(testProfile)=>{
              setPageState((prev) => {
                return {
                  ...prev,
                  window:{name:"test",testProfile:testProfile},
                };
              });  
            }}
            onCancel={() => {
              setPageState((prev) => {
                return {
                  ...prev,
                  windowName: null,
                  window: null,
                };
              });
            }}
          />
        );
        break;
        case "test":
          popUp = (
            <Test
              name={"test"}
              setState={setWindowState}
              menu={state?.window?.menu}
              pro={props.pro}
              testProfile={state?.window?.testProfile}
              state={state?.window?.state}
              onComplete={(data) => {
                props?.pro?.setTestMode(data);
                history.push("/home")
              }}
              onCancel={() => {
                setPageState((prev) => {
                  return {
                    ...prev,
                    window:{name:"tests"},
                  };
                });
              }}
            />
          );
          break;
    }
    return popUp;
  };

  const handleLeft = (navigator, action, menu) => {
    if (pageState.window != null) {
      if (menu[action.button].route == "NAVIGATE_Cancel")
        setPageState((prev) => {
          return { ...prev, window: null };
        });
      if (menu[action.button].route == "NAVIGATE_Clear")
        setPageState((prev) => {
          return { ...prev, window: null };
        });
      return;
    }
  };

  const handleRight = (navigator, action, menu) => {
    if (pageState.window != null) {
      if (menu[action.button].route == "NAVIGATE_Cancel")
        setPageState((prev) => {
          return { ...prev, window: null };
        });
      if (menu[action.button].route == "NAVIGATE_Clear")
        setPageState((prev) => {
          return { ...prev, window: null };
        });
      return;
    }
  };

  const handleDown = (navigator, action, menu) => {
    if (pageState.window != null) {
      return;
    }
    setPageState((prev) => {
      let prevIndex = prev.activeItemIndex;
      let menuItems = prev.menuItems;
      let newActiveIndex =
        prevIndex < menuItems.length - 1 ? prevIndex + 1 : prevIndex;
      return { ...prev, activeItemIndex: newActiveIndex };
    });
  };
  const handleUp = (navigator, action, menu) => {
    console.log({ navigator: navigator });
    console.log({ action: action });
    console.log({ menu: menu });
    if (pageState.window != null) {
      if (menu[action.button].route == "NAVIGATE_EDIT")
        setPageState((prevState) => {
          return {
            ...prevState,
            window: { ...prevState.window, mode: StringInputMODE.MODE_EDIT },
          };
        });

      return;
    }
    setPageState((prev) => {
      let prevIndex = prev.activeItemIndex;
      let newActiveIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
      return { ...prev, activeItemIndex: newActiveIndex };
    });
  };
  const updateNavigation = () => {
    setPageState((old) => {
      const showUpButton = old.activeItemIndex > 0;
      const showDownButton = old.activeItemIndex < old.menuItems.length - 1;
      old.menu.UP = showUpButton
        ? { label: "UP", route: "UP", local: true }
        : null;
      old.menu.DOWN = showDownButton
        ? { label: "DOWN", route: "DOWN", local: true }
        : null;
      return old;
    });
  };

  const localNavigation = (navigator, action, menu) => {
    console.log("itemMenu NAv admin");
    console.log({ pageState: pageState });

    if (action.type == "CLICK") {
    }
    if (pageState.window?.state?.windowNavigation != null) {
      console.log("executing window naw");
      pageState.window?.state?.windowNavigation(
        navigator,
        action,
        menu,
        pageState?.window?.state
      );
      return;
    }

    if (action.type == "CLICK") {
      switch (action.button) {
        case "DOWN":
          handleDown(navigator, action, menu);
          break;
        case "LEFT":
          handleLeft(navigator, action, menu);
          break;
        case "UP":
          handleUp(navigator, action, menu);
          break;
        case "RIGHT":
          setPageState((prevState) => {
            if (prevState.menuItems[prevState.activeItemIndex].window != null) {
              return {
                ...prevState,
                window: {
                  name: prevState.menuItems[prevState.activeItemIndex].window,
                },
              };
            } else if (
              prevState.menuItems[prevState.activeItemIndex].route != null
            ) {
              history.push(
                prevState.menuItems[prevState.activeItemIndex].route
              );
              return prevState;
            }
          });
          //   handleRight(activeItemIndex)
          break;
      }
    }
  };

  useEffect(() => {
    let menuItems = generateMenuItems(props);
    setPageState((prev) => {
      return { ...prev, menuItems: menuItems };
    });
  }, [pageState.oximeterSignalAlarmDelay, pageState.location]);
  useEffect(() => {
    updateNavigation();
  }, [pageState.activeItemIndex]);

  useEffect(() => {
    const state = pageState;
    // if(state.window){
    //   state.window.menu=null;
    // }

    const searchParams = new URLSearchParams();
    searchParams.append("windowState", JSON.stringify(state));
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });

    if (
      pageState?.window?.menu == null ||
      pageState?.window?.menu == undefined
    ) {
      props.navigation.setMenu(pageState.menu);
    }
    props.navigation.addLocalNavigation(localNavigation);
    return () => {
      props.navigation.addLocalNavigation(null);
    };
  }, [pageState]);
  useEffect(() => {
    let pop = renderPopUp(pageState);
    setPopUp(pop);
  }, [pageState.windowName, pageState.window]);

  return (
    <PopUpWrapper popup={popup}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <ItemMenu
          margin={5}
          activeItemIndex={pageState?.activeItemIndex}
          width="60%"
          menuItems={pageState?.menuItems}
        />

        <Box sx={{ position: "absolute", right: 0, bottom: 0 }}>
          <Box
            sx={{
              width: "250px",
              height: "100px",
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              mr: 3,
            }}
          >
            <Typography
              sx={{ fontSize: "13px", color: colors.COLORS_SUBITEM_DEFAULT }}
            >
              --: FULL
            </Typography>
            <Typography
              sx={{ fontSize: "13px", color: colors.COLORS_SUBITEM_DEFAULT }}
            >
              v1.7.0 SIM: SW
            </Typography>
            <Typography
              sx={{ fontSize: "13px", color: colors.COLORS_SUBITEM_DEFAULT }}
            >
              v1.26 SIM: FW
            </Typography>
            <Typography
              sx={{ fontSize: "13px", color: colors.COLORS_SUBITEM_DEFAULT }}
            >
              PRO100GSIMULATOR : UUID
            </Typography>
          </Box>
        </Box>
      </Box>
    </PopUpWrapper>
  );
};
const generateMenuItems = (props) => {
  return [
    { label: "Profiles", route: "profiles" },
    { label: "Settings", route: "settings" },
    { label: "Device Status", window: "deviceStatus" },
    {
      label: "Tests",
      window: "tests",
    },
    { label: "Reset", route: "reset" },
  ];
};
