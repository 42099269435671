import { Box, Typography } from "@mui/material";
import { colors } from "../../Style";
import { useEffect, useState } from "react";
import { useHistory, createSearchParams, useLocation } from "react-router-dom";
import { useQuery } from "../../Utils";
import { Translate } from "react-i18nify";

const findActiveIndex = (value, values) => {
  return values.indexOf(value);
};
export const ConfirmRiskyProfile   = (props) => {
  console.log({ stringProps: props });
  const navigation = (navigator, action, menu, state) => {
    console.log({ action: action });
    console.log({ menu: menu });
    if (action.type === "CLICK") {
      switch (menu[action.button].route) {
        case "NAVIGATE_CANCEL":
          cancelClicked(state);
          break;
        case "NAVIGATE_CONFIRM":
          confirmClicked(state);
      }
    }
  };

  const shouldInit = props.menu == undefined ? true : false;
  const menuItems = props.menuItems;
  const defaultState = {
    windowNavigation: navigation,
  };

  const menu = props.menu
    ? props.menu
    : {
        LEFT: { label: "Cancel", route: "NAVIGATE_CANCEL", local: true },
        UP: null,
        RIGHT: { label: "Confirm", route: "NAVIGATE_CONFIRM", local: true },
        MIDDLE: null,
        DOWN: null,
      };

  defaultState.menu = menu;
  const windowState = props?.state
    ? {
        ...props.state,
        menu: menu,
      }
    : defaultState;

  console.log({ windowState: windowState });



  const cancelClicked = (state) => {
    props.onCancel(state);
  };



  const confirmClicked = (state) => {
    props.onComplete(state)
  };


  useEffect(() => {
    console.log("should init:" + shouldInit);
    if (true) {
      windowState.windowNavigation = navigation;
      props.setState(menu, windowState);
    }
  }, [windowState.name]);


  return (
    <Box
      sx={{
        width: props.width?props.width:"450px",
        height: "100px",
        background: "black",
        border: "1px solid white",
        p: 1,
      }}
    >
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center",alignItems:"center",height:"100%" }}>
        {props.message}
        {/* <Typography variant="h6" sx={{color:colors.COLORS_ALARM_MEDIUM, fontWeight: "bold" }}>
          <Translate value={"ConfirmRisky"}/>
        </Typography> */}
      </Box>
    </Box>
  );
};

