import { useEffect, useState } from "react";
import { ItemMenu } from "../../components/UI/ItemMenu";
import { useHistory, createSearchParams, useLocation } from "react-router-dom";
import {
  GetWindowState,
  defaultReducer,
  usePageState,
  useQuery,
} from "../../Utils";
import { PopUpWrapper } from "../../components/PopUpWrapper";
import {
  StringInputMODE,
  StringInputMenu,
} from "../../components/UI/StringInputMenu";
import { PIN_ROLE, PinMenu } from "../../components/UI/PinMenu";
import { translate } from "react-i18nify";

export const Security = (props) => {
  const history = useHistory();
  const location = useLocation();
  const state = GetWindowState();

  const defaultMenu = {
    LEFT: { label: "Back", route: "settings" },
    UP: null,
    RIGHT: { label: "Select", route: "select", local: true },
    MIDDLE: null,
    DOWN: { label: "DOWN", route: "down", local: true },
  };

  const [popup, setPopUp] = useState(null);
  const [pageState, setPageState] = usePageState(
    defaultReducer,
    state
      ? state
      : {
          menu: { ...defaultMenu },
          window: null,
          oximeterSignalAlarmDelay: props.pro.oximeterSignalAlarmDelay,
          patient: { ...props.pro.patient },
          adminPIN: props.pro.adminPIN,
          activeItemIndex: 0,
          menuItems: generateMenuItems(props),
        }
  );

  const handleDown = (navigator, action, menu) => {
    if (pageState.window != null) {
      return;
    }
    setPageState((prev) => {
      let prevIndex = prev.activeItemIndex;
      let menuItems = prev.menuItems;
      let newActiveIndex =
        prevIndex < menuItems.length - 1 ? prevIndex + 1 : prevIndex;
      return { ...prev, activeItemIndex: newActiveIndex };
    });
  };

  const handleLeft = (navigator, action, menu) => {
    if (pageState.window != null) {
      // if (menu[action.button].route == "NAVIGATE_EDIT")
      //   setPageState((prevState) => {
      //     return {
      //       ...prevState,
      //       window: { ...prevState.window, mode: StringInputMODE.MODE_EDIT },
      //     };
      //   });

      return;
    }
    setPageState((prevState) => {
      return {
        ...prevState,
        window: {
          name: prevState.menuItems[prevState.activeItemIndex].window,
        },
      };
    });
  };

  const handleRight = (navigator, action, menu) => {
    if (
      pageState.window != null ||
      pageState.window?.name != null ||
      pageState.window?.name != undefined
    ) {
      // if (menu[action.button].route == "NAVIGATE_EDIT")
      //   setPageState((prevState) => {
      //     return {
      //       ...prevState,
      //       window: { ...prevState.window, mode: StringInputMODE.MODE_EDIT },
      //     };
      //   });

      return;
    }
    console.log("setting handle right");
    setPageState((prevState) => {
      return {
        ...prevState,
        windowName: prevState.menuItems[prevState.activeItemIndex].window,
        window: {},
      };
    });
  };

  const handleUp = (navigator, action, menu) => {
    if (pageState.window != null) {
      return;
    }
    setPageState((prev) => {
      let prevIndex = prev.activeItemIndex;
      let newActiveIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
      return { ...prev, activeItemIndex: newActiveIndex };
    });
  };

  const updateNavigation = () => {
    setPageState((old) => {
      if (old.menuItems == null || old.menu == null) {
        return old;
      }
      const showUpButton = old.activeItemIndex > 0;
      const showDownButton = old.activeItemIndex < old.menuItems.length - 1;
      old.menu.UP = showUpButton
        ? { label: "UP", route: "UP", local: true }
        : null;
      old.menu.DOWN = showDownButton
        ? { label: "DOWN", route: "DOWN", local: true }
        : null;
      return old;
    });
  };
  const setWindowState = (state, windowState) => {
    setPageState((prev) => {
      return {
        ...prev,
        window: { ...prev.window, state: windowState, menu: state },
      };
    });
    props.navigation.setMenu(state);
  };
  const newPatientComplete = (state) => {
    console.log("new patient complete");
    console.log({ state: state });
    console.log({ pageState: pageState });

    setPageState((prev) => {
      return {
        ...prev,
        updated: { patient: { patientId: state.newValue } },
        windowName: "newName",
        window: null,
      };
    });
  };
  const newNameComplete = (state) => {
    console.log("new patient complete");
    console.log({ state: state });
    console.log({ pageState: pageState });
    setPageState((prev) => {
      let patient = prev.updated.patient;
      patient.patientName = state.newValue;
      return {
        ...prev,
        updated: { ...prev.updated, patient: patient },
        windowName: "newLocation",
        window: null,
      };
    });
  };

  const localNavigation = (navigator, action, menu) => {
    console.log({ pageState: pageState });
    if (action.type == "CLICK") {
    }
    if (pageState.window?.state?.windowNavigation != null) {
      console.log("executing window naw");
      pageState.window?.state?.windowNavigation(
        navigator,
        action,
        menu,
        pageState?.window?.state
      );
      return;
    }

    if (action.type == "CLICK") {
      switch (action.button) {
        case "DOWN":
          handleDown(navigator, action, menu);
          break;
        case "LEFT":
          handleLeft(navigator, action, menu);
          break;
        case "UP":
          handleUp(navigator, action, menu);
          break;
        case "RIGHT":
          handleRight(navigator, action, menu);
          break;
      }
    }
  };
  const renderPopUp = (state) => {
    let popUp = null;
    switch (state.windowName) {
      case "setPin":
        popUp = (
          <PinMenu
            name={"setPin"}
            label={translate("InputCurrentPin")}
            setState={setWindowState}
            role={PIN_ROLE.VERIFY_PIN}
            currentPin={props.pro.pin.usb}
            menu={state?.window?.menu}
            state={state?.window?.state}
            onComplete={(eneteredPin) => {
              if (eneteredPin == props.pro.pin.usb) {
                console.log("PIN CORRECT");
                setPageState((prev) => {
                  return {
                    ...prev,
                    menu: { ...defaultMenu },
                    windowName: "setPinTwo",
                    window: null,
                  };
                });
              }
            }}
            onCancel={() => {
              setPageState((prev) => {
                return {
                  ...prev,
                  windowName: null,
                  window: null,
                };
              });
            }}
          />
        );
        break;
      case "setPinTwo":
        popUp = (
          <PinMenu
            name={"setPinTwo"}
            label={translate("PinAccepted")}
            role={PIN_ROLE.SET_PIN}
            setState={setWindowState}
            menu={state?.window?.menu}
            state={state?.window?.state}
            onComplete={(eneteredPin) => {
              props.pro.setUSBPin(eneteredPin);
              setPageState((prev) => {
                let prevState = { ...prev };
                prevState.windowName = null;
                prevState.window = null;
                return prevState;
              });
              return;
            }}
            onCancel={() => {
              setPageState((prev) => {
                return {
                  ...prev,
                  windowName: null,
                  window: null,
                };
              });
            }}
          />
        );
        break;
        case "resetPin":
          popUp = (
            <PinMenu
              name={"resetPin"}
              label={translate("InputCurrentPin")}
              setState={setWindowState}
              role={PIN_ROLE.VERIFY_PIN}
              currentPin={props.pro.pin.master}
              menu={state?.window?.menu}
              state={state?.window?.state}
              onComplete={(eneteredPin) => {
                if (eneteredPin == props.pro.pin.master) {
                  props.pro.setUSBPin("1111");
                  console.log("PIN CORRECT");
                  setPageState((prev) => {
                    return {
                      ...prev,
                      windowName: null,
                      window: null,
                    };
                  });
                }
              }}
              onCancel={() => {
                setPageState((prev) => {
                  return {
                    ...prev,
                    windowName: null,
                    window: null,
                  };
                });
              }}
            />
          );
          break;
          case "setAdminPin":
            popUp = (
              <PinMenu
                name={"setAdminPin"}
                label={translate("InputCurrentPin")}
                setState={setWindowState}
                role={PIN_ROLE.VERIFY_PIN}
                currentPin={props.pro.pin.usb}
                menu={state?.window?.menu}
                state={state?.window?.state}
                onComplete={(eneteredPin) => {
                  if (eneteredPin == props.pro.pin.usb) {

                    setPageState((prev) => {
                      return {
                        ...prev,
                        adminPIN: !props.pro.adminPIN,
                        windowName: null,
                        window: null,
                      };
                    });
                    props.pro.switchAdminPIN();
                  }
                }}
                onCancel={() => {
                  setPageState((prev) => {
                    return {
                      ...prev,
                      windowName: null,
                      window: null,
                    };
                  });
                }}
              />
            );
            break;

    }
    return popUp;
  };

  useEffect(() => {
    let menuItems = generateMenuItems(props);
    setPageState((prev) => {
      return { ...prev, menuItems: menuItems };
    });
  }, [pageState.adminPIN]);
  useEffect(() => {
    updateNavigation();
  }, [pageState.activeItemIndex]);

  useEffect(() => {
    const state = pageState;
    // if(state.window){
    //   state.window.menu=null;
    // }

    const searchParams = new URLSearchParams();
    searchParams.append("windowState", JSON.stringify(state));
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });

    if (
      pageState?.window?.menu == null ||
      pageState?.window?.menu == undefined
    ) {
      props.navigation.setMenu(pageState.menu);
    }
    props.navigation.addLocalNavigation(localNavigation);
    return () => {
      props.navigation.addLocalNavigation(null);
    };
  }, [pageState]);
  useEffect(() => {
    let pop = renderPopUp(pageState);
    setPopUp(pop);
  }, [pageState.windowName, pageState.window]);

  return (
    <PopUpWrapper popup={popup}>
      <ItemMenu
        margin={5}
        activeItemIndex={pageState.activeItemIndex}
        width="60%"
        menuItems={pageState.menuItems}
      />
    </PopUpWrapper>
  );
};
const generateMenuItemsPro = (pro) => {
  return [
    { label: "Set PIN", window: "setPin" },
    { label: "Reset PIN", window: "resetPin" },
    { label: "Admin PIN", window: "setAdminPin", value:pro.adminPIN?"Enabled":"Disabled" },
  ];
};
const generateMenuItems = (props) => {
  return [
    { label: "Set PIN", window: "setPin" },
    { label: "Reset PIN", window: "resetPin" },
    { label: "Admin PIN", window: "setAdminPin", value:props.pro.adminPIN?"Enabled":"Disabled" },
  ];
};
