import { Box, Typography } from "@mui/material";
import { colors } from "../../Style";
import { useEffect, useState } from "react";
import { useHistory, createSearchParams, useLocation } from "react-router-dom";
import { useQuery } from "../../Utils";
import { ItemMenu } from "./ItemMenu";
import { FLOW_RESPONSE } from "../../ProSimulator/ProSimulator";

const findActiveIndex = (value, values) => {
  return values.indexOf(value);
};

export const EDIT_PROFILE_MODE = {
  MODE_DEFAULT: "MODE_DEFAULT",
  MODE_EDIT_VALUE: "EDIT_VALUE",
};

export const CustomizeProfile = (props) => {
  console.log({ SelectProfileMenuProps: props });
  const navigation = (navigator, action, menu, state) => {
    console.log({ state: state });
    if (action.type === "CLICK") {
      switch (menu[action.button].route) {
        case "NAVIGATE_CANCEL":
          cancelClicked(state);
          break;
        case "NAVIGATE_SAVE":
          saveClicked(state);
          break;
        case "NAVIGATE_BACK":
          backClicked(state);
          break;
        case "NAVIGATE_UNDO":
          undoClicked(state);
          break;
        case "NAVIGATE_ACCEPT":
          acceptClicked(state);
          break;
        case "NAVIGATE_SELECT":
          selectClicked(state);
          break;
        case "NAVIGATE_EDIT":
          editClicked(state);
          break;
        case "VALUE_UP":
          valueUp(state);
          break;
        case "VALUE_DOWN":
          valueDown(state);
          break;
      }
    }
  };

  const shouldInit = props.menu == undefined ? true : false;
  const menuItems = props.menuItems;
  const defaultState = {
    name: props.name,
    disabled:props.disabled,
    label: props.label,
    editActiveIndex: 0,
    editProfileValueMode: EDIT_PROFILE_MODE.MODE_DEFAULT,
    value: props.value,
    modifyNavigation:props.modifyNavigation?props.modifyNavigation:false,
    newValue: props.value,
    values: props.values,
    windowNavigation: navigation,
  };

  const menu = props.menu
    ? props.menu
    : {
        LEFT: { label: "Back", route: "NAVIGATE_BACK", local: true },
        UP: defaultState.modifyNavigation?{ label: "UP", route: "VALUE_UP", local: true }:null,
        RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
        MIDDLE: null,
        DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
      };
  defaultState.menu = menu;
  const windowState = props?.state
    ? {
        ...props.state,
        menu: menu,
      }
    : defaultState;

  console.log({ windowState: windowState });

  const setWindowState = (windowState) => {
    console.log({ setWindowState: windowState });
    props.setState(windowState.menu, windowState);
  };

  const cancelClicked = (state) => {
    props.onCancel(state);
  };

  const valueUp = (state) => {
    if (state.editProfileValueMode === EDIT_PROFILE_MODE.MODE_DEFAULT) {
      let newValue = cycleValues(
        "down",
        null,
        generateProfileValues(state.newValue),
        state.editActiveIndex
      );
      setWindowState({
        ...state,
        editActiveIndex: newValue,
        menu: updateNavigationProfileData(
          EDIT_PROFILE_MODE.MODE_DEFAULT,
          newValue,
          generateProfileValues(state.newValue),
          state.modifyNavigation
        ),
      });
    } else if (
      state.editProfileValueMode === EDIT_PROFILE_MODE.MODE_EDIT_VALUE
    ) {
      let profileMenu = generateProfileValues(state.updatedProfile);
      let newValue = changeProfileValue(
        state.updatedProfile,
        profileMenu[state.editActiveIndex].editName,
        "up"
      );
      setWindowState({
        ...state,
        updatedProfile: newValue,
        menu: updateNavigationProfileData(
          EDIT_PROFILE_MODE.MODE_EDIT_VALUE,
          newValue,
          generateProfileValues(newValue),
          state.modifyNavigation
        ),
      });
    }
  };

  const valueDown = (state) => {
    if (state.editProfileValueMode === EDIT_PROFILE_MODE.MODE_DEFAULT) {
      let newValue = cycleValues(
        "up",
        null,
        generateProfileValues(state.newValue),
        state.editActiveIndex
      );
      setWindowState({
        ...state,
        editActiveIndex: newValue,
        menu: updateNavigationProfileData(
          EDIT_PROFILE_MODE.MODE_DEFAULT,
          newValue,
          generateProfileValues(state.newValue),
          state.modifyNavigation
        ),
      });
    } else if (
      state.editProfileValueMode === EDIT_PROFILE_MODE.MODE_EDIT_VALUE
    ) {
      let profileMenu = generateProfileValues(state.updatedProfile);
      let newValue = changeProfileValue(
        state.updatedProfile,
        profileMenu[state.editActiveIndex].editName,
        "down"
      );
      setWindowState({
        ...state,
        updatedProfile: newValue,
        menu: updateNavigationProfileData(
          EDIT_PROFILE_MODE.MODE_EDIT_VALUE,
          newValue,
          generateProfileValues(newValue),
          state.modifyNavigation
        ),
      });
    }
  };

  const backClicked = (state) => {
    if (state.editProfileValueMode === EDIT_PROFILE_MODE.MODE_DEFAULT) {
      props.onComplete(state.updatedProfile?state.updatedProfile:state.newValue);
      // setWindowState({
      //   ...state,
      //   editProfileValueMode: EDIT_PROFILE_MODE.MODE_DEFAULT,
      //   menu: updateNavigationProfiles(
      //     EDIT_PROFILE_MODE.MODE_DEFAULT,
      //     state.activeIndex,
      //     state.profiles
      //   ),
      // });
    }
  };
  const editClicked = (state) => {
    let updatedProfile = { ...state.profiles[state.activeIndex] };
    setWindowState({
      ...state,
      editActiveIndex: 0,
      updatedProfile: updatedProfile,
      menu: updateNavigationProfiles(
        EDIT_PROFILE_MODE.MODE_EDIT_VALUE,
        state.activeIndex,
        state.profiles
      ),
    });
  };

  const undoClicked = (state) => {
    if (state.editProfileValueMode == EDIT_PROFILE_MODE.MODE_EDIT_VALUE) {
      setWindowState({
        ...state,
        editProfileValueMode: EDIT_PROFILE_MODE.MODE_DEFAULT,
        updatedProfile: state.savedProfile,
        menu: updateNavigationProfileData(
          EDIT_PROFILE_MODE.MODE_DEFAULT,
          state.editActiveIndex,
          generateProfileValues(state.newValue),
          state.modifyNavigation
        ),
      });
    }
  };

  const selectClicked = (state) => {
    if (state.editProfileValueMode == EDIT_PROFILE_MODE.MODE_DEFAULT) {
      let profileMenu = generateProfileValues(
        state.updatedProfile ? state.updatedProfile : state.newValue
      );
      let newValue = changeProfileValue(
        state.updatedProfile ? state.updatedProfile : state.newValue,
        profileMenu[state.editActiveIndex].editName,
        "none"
      );
      setWindowState({
        ...state,
        editProfileValueMode: EDIT_PROFILE_MODE.MODE_EDIT_VALUE,
        savedProfile: newValue,
        updatedProfile: newValue,
        menu: {
          LEFT: { label: "Undo", route: "NAVIGATE_UNDO", local: true },
          UP: { label: "UP", route: "VALUE_UP", local: true },
          RIGHT: { label: "Save", route: "NAVIGATE_SAVE", local: true },
          MIDDLE: { label: "Value" },
          DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
        },
      });
    }
  };
  const acceptClicked = (state) => {
    console.log({ acceptClicked: state });
    if (state.updatedProfile) {
      let newProfile = { ...state.updatedProfile };
      if (
        isProfileCustom(newProfile, state.backupProfiles[state.activeIndex])
      ) {
        newProfile.isCustom = true;
      } else {
        newProfile = state.backupProfiles[state.activeIndex];
      }
      props.onComplete(newProfile);
      return;
    }
    props.onComplete(state.backupProfiles[state.activeIndex]);
  };

  const saveClicked = (state) => {
    if (state.editProfileValueMode === EDIT_PROFILE_MODE.MODE_EDIT_VALUE) {
      setWindowState({
        ...state,
        editProfileValueMode: EDIT_PROFILE_MODE.MODE_DEFAULT,
        updatedProfile: state.updatedProfile,
        savedProfile: state.updatedProfile,
        menu: updateNavigationProfileData(
          EDIT_PROFILE_MODE.MODE_DEFAULT,
          state.editActiveIndex,
          generateProfileValues(state.updatedProfile),
          state.modifyNavigation
        ),
      });
      return;
    }
  };

  useEffect(() => {
    console.log("should init:" + shouldInit);
    if (true) {
      windowState.windowNavigation = navigation;
      props.setState(menu, windowState);
    }
  }, [windowState.name]);

  if(props.empty){
    return(
      <ItemMenu
      margin={0}
      disabled={windowState.disabled}
      showErrors={
        windowState.editProfileValueMode ===
        EDIT_PROFILE_MODE.MODE_EDIT_VALUE
      }
      selected={
        windowState.editProfileValueMode ===
        EDIT_PROFILE_MODE.MODE_EDIT_VALUE
      }
      activeItemIndex={windowState.editActiveIndex}
      width={props.width}
      menuItems={
        windowState.updatedProfile
          ? generateProfileValues(windowState.updatedProfile)
          : generateProfileValues(windowState.newValue)
      }
    />
    )
  }

  return (
    <Box
      sx={{
        width: "700px",
        height: "300px",
        background: "black",
        border: "1px solid white",
        p: 1,
      }}
    >
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Typography
          variant="h6"
          sx={{ color: colors.TEXT_COLOR, fontWeight: "bold" }}
        >
          {windowState?.label}
        </Typography>
      </Box>
      <Box
        sx={{ mt: 2, width: "100%", display: "flex", justifyContent: "center" }}
      >
        <Box sx={{ width: "60%", height: "100%" }}>
          <ItemMenu
            margin={0}
            showErrors={
              windowState.editProfileValueMode ===
              EDIT_PROFILE_MODE.MODE_EDIT_VALUE
            }
            selected={
              windowState.editProfileValueMode ===
              EDIT_PROFILE_MODE.MODE_EDIT_VALUE
            }
            activeItemIndex={windowState.editActiveIndex}
            width="100%"
            menuItems={
              windowState.updatedProfile
                ? generateProfileValues(windowState.updatedProfile)
                : generateProfileValues(windowState.newValue)
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export const isProfileCustom = (profile, oldProfile) => {
  if (
    profile.minSpO2 != oldProfile.minSpO2 ||
    profile.maxSpO2 != oldProfile.maxSpO2 ||
    profile.minO2 != oldProfile.minO2 ||
    profile.maxO2 != oldProfile.maxO2 ||
    profile.initialO2 != oldProfile.initialO2 ||
    profile.minPulse != oldProfile.minPulse ||
    profile.maxPulse != oldProfile.maxPulse ||
    profile.flowResponse != oldProfile.flowResponse ||
    profile.name != oldProfile.name
  ) {
    return true;
  }
  return false;
};

export const generateProfileValues = (profile) => {
  let profileValues = [
    {
      label: "Min SpO2",
      value: profile.minSpO2,
      editName: "minSpO2",
      labelError: profile.error?.minSpO2?.labelError ? true : false,
      valueError: profile.error?.minSpO2?.valueError ? true : false,
      risky: profile.error?.minSpO2?.risky ? true : false,
      unit:"%"
    },
    {
      label: "Max SpO2",
      value: profile.maxSpO2,
      editName: "maxSpO2",
      labelError: profile.error?.maxSpO2?.labelError ? true : false,
      valueError: profile.error?.maxSpO2?.valueError ? true : false,
      unit:"%"
    },
    {
      label: "Min O2 Flow",
      value: profile.minO2,
      editName: "minO2",
      labelError: profile.error?.minO2?.labelError ? true : false,
      valueError: profile.error?.minO2?.valueError ? true : false,
      unit:"l/min"
    },
    {
      label: "Max O2 Flow",
      value: profile.maxO2,
      editName: "maxO2",
      labelError: profile.error?.maxO2?.labelError ? true : false,
      valueError: profile.error?.maxO2?.valueError ? true : false,
      unit:"l/min"
    },
    {
      label: "Initial O2",
      value: profile.initialO2,
      editName: "initialO2",
      labelError: profile.error?.initialO2?.labelError ? true : false,
      valueError: profile.error?.initialO2?.valueError ? true : false,
      unit:"l/min"
    },
    {
      label: "Min Pulse Rate",
      value: profile.minPulse,
      editName: "minPulse",
      labelError: profile.error?.minPulse?.labelError ? true : false,
      valueError: profile.error?.minPulse?.valueError ? true : false,
      unit:"bpm"
    },
    {
      label: "Max Pulse Rate",
      value: profile.maxPulse,
      editName: "maxPulse",
      labelError: profile.error?.maxPulse?.labelError ? true : false,
      valueError: profile.error?.maxPulse?.valueError ? true : false,
      risky: profile.error?.maxPulse?.risky ? true : false,
      unit:"bpm"
    },
    {
      label: "Flow response",
      value: FLOW_RESPONSE[profile.flowResponse],
      editName: "flowResponse",
      notSelectable: true,
      labelError: profile.error?.flowResponse?.labelError ? true : false,
      valueError: profile.error?.flowResponse?.valueError ? true : false,
    },
  ];
  return profileValues;
};

export const cycleValues = (direction, value, values, activeIndex) => {
  let currentIndex = activeIndex;

  let newIndex;
  if (direction === "up") {
    newIndex = currentIndex + 1;
    if (newIndex === values.length) {
      newIndex = values.length - 1;
    }
  } else if (direction === "down") {
    newIndex = currentIndex - 1;
    if (newIndex < 0) {
      newIndex = 0;
    }
  } else {
    return activeIndex;
  }

  return newIndex;
};
const updateNavigationProfiles = (mode, index, profiles) => {
  let menu = {};

  // if (mode === SELECT_PROFILE_STATE_MODE.MODE_DEFAULT) {
  //   let activeIndex = index;
  //   if (activeIndex == profiles.length - 1) {
  //     menu = {
  //       LEFT: { label: "Back", route: "NAVIGATE_CANCEL", local: true },
  //       UP: { label: "UP", route: "VALUE_UP", local: true },
  //       RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
  //       MIDDLE: { label: "Value" },
  //       DOWN: null,
  //     };
  //   } else if (activeIndex == 0) {
  //     menu = {
  //       LEFT: { label: "Back", route: "NAVIGATE_BACK", local: true },
  //       UP: null,
  //       RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
  //       MIDDLE: { label: "Value" },
  //       DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
  //     };
  //   } else {
  //     menu = {
  //       LEFT: { label: "Back", route: "NAVIGATE_CANCEL", local: true },
  //       UP: { label: "UP", route: "VALUE_UP", local: true },
  //       RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
  //       MIDDLE: { label: "Value" },
  //       DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
  //     };
  //   }
  // } else if (mode === SELECT_PROFILE_STATE_MODE.MODE_PROFILE_SELECTED) {
  //   menu = {
  //     LEFT: { label: "Back", route: "NAVIGATE_BACK", local: true },
  //     UP: { label: "Edit", route: "NAVIGATE_EDIT", local: true },
  //     RIGHT: { label: "Accept", route: "NAVIGATE_ACCEPT", local: true },
  //     MIDDLE: null,
  //     DOWN: null,
  //   };
  // } else if (mode === SELECT_PROFILE_STATE_MODE.MODE_EDIT_PROFILE) {
  //   menu = {
  //     LEFT: { label: "Back", route: "NAVIGATE_BACK", local: true },
  //     UP: null,
  //     RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
  //     MIDDLE: { label: "Value" },
  //     DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
  //   };
  // }

  return menu;
};

const updateNavigationProfileData = (mode, index, profiles,modifyNavigation) => {
  let menu = {};
  if (mode === EDIT_PROFILE_MODE.MODE_EDIT_VALUE) {
    menu = {
      LEFT: { label: "Undo", route: "NAVIGATE_UNDO", local: true },
      UP: { label: "UP", route: "VALUE_UP", local: true },
      RIGHT: { label: "Save", route: "NAVIGATE_SAVE", local: true },
      MIDDLE: { label: "Value" },
      DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
    };
  } else if (mode === EDIT_PROFILE_MODE.MODE_DEFAULT) {
    let activeIndex = index;
    if (activeIndex == profiles.length - 1) {
      if (profiles[index].notSelectable) {
        menu = {
          LEFT: { label: "Back", route: "NAVIGATE_BACK", local: true },
          UP: { label: "UP", route: "VALUE_UP", local: true },
          RIGHT: null,
          MIDDLE: null,
          DOWN: null,
        };
      } else {
        menu = {
          LEFT: { label: "Back", route: "NAVIGATE_BACK", local: true },
          UP: { label: "UP", route: "VALUE_UP", local: true },
          RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
          MIDDLE: null,
          DOWN: null,
        };
      }
    } else if (activeIndex == 0&&!modifyNavigation) {
      menu = {
        LEFT: { label: "Back", route: "NAVIGATE_BACK", local: true },
        UP: { label: "UP", route: "VALUE_UP", local: true },
        RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
        MIDDLE: null,
        DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
      };
    } else {
      menu = {
        LEFT: { label: "Back", route: "NAVIGATE_BACK", local: true },
        UP: { label: "UP", route: "VALUE_UP", local: true },
        RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
        MIDDLE:null,
        DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
      };
    }
  }
  return menu;
};

export function changeProfileValue(profile, valueEditing, direction) {
  let editedProfile = { ...profile };
  delete editedProfile["error"];
  let newValue;
  let error;
  if (direction != "none") {
    switch (valueEditing) {
      case "minSpO2":
        newValue =
          direction === "up"
            ? editedProfile.minSpO2 + 1
            : editedProfile.minSpO2 - 1;
        if (newValue < 80 || newValue > 98) {
          error = { minSpO2: { labelError: true } };
        } else if (newValue > editedProfile.maxSpO2 - 2) {
          error = { maxSpO2: { valueError: true } };
        }

        if (error != null) {
          editedProfile["error"] = error;
        } else {
          editedProfile.minSpO2 = newValue;
        }

        break;
      case "maxSpO2":
        newValue =
          direction === "up"
            ? editedProfile.maxSpO2 + 1
            : editedProfile.maxSpO2 - 1;
        if (newValue > 100) {
          error = { maxSpO2: { labelError: true } };
        } else if (newValue < editedProfile.minSpO2 + 2) {
          error = { minSpO2: { valueError: true } };
        }
        if (error != null) {
          editedProfile["error"] = error;
        } else {
          editedProfile.maxSpO2 = newValue;
        }
        break;
      case "initialO2":
        newValue =
          direction === "up"
            ? editedProfile.initialO2 + 1
            : editedProfile.initialO2 - 1;
        if (newValue < 0 || newValue > 15) {
          error = { initialO2: { labelError: true } };
          // throw new Error("Invalid value: initialO2 must be between 0 and 15.");
        } else if (editedProfile.minO2 > newValue - 1) {
          error = { minO2: { valueError: true } };
          // throw new Error(
          //   "Invalid value: minO2 must be at most 1 less than initialO2."
          // );
        } else if (editedProfile.maxO2 < newValue) {
          error = { maxO2: { valueError: true } };
        }
        if (error != null) {
          editedProfile["error"] = error;
        } else {
          editedProfile.initialO2 = newValue;
        }

        break;
      case "minO2":
        newValue =
          direction === "up"
            ? editedProfile.minO2 + 1
            : editedProfile.minO2 - 1;
        if (newValue < 0) {
          error = { minO2: { labelError: true } };
        } else if (newValue > editedProfile.initialO2) {
          error = { initialO2: { valueError: true } };
        } else if (editedProfile.maxO2 < newValue) {
          error = { maxO2: { valueError: true } };
          // throw new Error(
          //   "Invalid value: maxO2 must be at least equal to minO2."
          // );
        }
        if (error != null) {
          editedProfile["error"] = error;
        } else {
          editedProfile.minO2 = newValue;
        }

        break;
      case "maxO2":
        newValue =
          direction === "up"
            ? editedProfile.maxO2 + 1
            : editedProfile.maxO2 - 1;
        if (newValue > 15) {
          error = { maxO2: { labelError: true } };
        } else if (newValue < editedProfile.minO2) {
          error = { minO2: { valueError: true } };
        } else if (newValue < editedProfile.initialO2) {
          error = { initialO2: { valueError: true } };
        }
        if (error != null) {
          editedProfile["error"] = error;
        } else {
          editedProfile.maxO2 = newValue;
        }
        break;
      case "minPulse":
        newValue =
          direction === "up"
            ? editedProfile.minPulse + 5
            : editedProfile.minPulse - 5;
        if (newValue < 40 || newValue > 80) {
          error = { minPulse: { labelError: true } };
          // throw new Error("Invalid value: minPulse must be between 40 and 80.");
        }
        if (error != null) {
          editedProfile["error"] = error;
        } else {
          editedProfile.minPulse = newValue;
        }

        break;
      case "maxPulse":
        newValue =
          direction === "up"
            ? editedProfile.maxPulse + 5
            : editedProfile.maxPulse - 5;
        if (newValue < 100 || newValue > 200) {
          error = { maxPulse: { labelError: true } };
          // throw new Error("Invalid value: maxPulse must be between 100 and 200.");
        }
        if (error != null) {
          editedProfile["error"] = error;
        } else {
          editedProfile.maxPulse = newValue;
        }

        break;
    }
  }

  if (editedProfile.minSpO2 < 85) {
    if (error == undefined) {
      error = { minSpO2: { risky: true } };
    }
    if (error.minSpO2 == undefined) {
      error.minSpO2 = {};
    }
    error["minSpO2"].risky = true;
  }
  if (editedProfile.maxPulse > 150) {
    if (error == undefined) {
      error = { maxPulse: { risky: true } };
    }
    if (error.maxPulse == undefined) {
      error.maxPulse = {};
    }

    error["maxPulse"].risky = true;
  }
  if (error != null) {
    editedProfile["error"] = error;
  }

  return editedProfile;
}
