import { Box, Typography } from "@mui/material";
import { colors } from "../../Style";
import { useEffect, useState } from "react";
import { useHistory, createSearchParams, useLocation } from "react-router-dom";
import { useQuery } from "../../Utils";
import { Translate } from "react-i18nify";


export const DeviceStatusMenu = (props) => {

  const navigation = (navigator, action, menu, state) => {

    if (action.type === "CLICK") {
      switch (menu[action.button].route) {
        case "NAVIGATE_CANCEL":
          cancelClicked(state);
          break;
      }
    }
  };

  const shouldInit = props.menu == undefined ? true : false;
  const menuItems = props.menuItems;
  const defaultState = {
    name: props.name,
    windowNavigation: navigation,
  };

  const menu = props.menu
    ? props.menu
    : {
        LEFT: { label: "Back", route: "NAVIGATE_CANCEL", local: true },
        UP: null,
        RIGHT: null,
        MIDDLE: null,
        DOWN: null,
      };

  defaultState.menu = menu;
  const windowState = props?.state
    ? {
        ...props.state,
        menu: menu,
      }
    : defaultState;



  const cancelClicked = (state) => {
    props.onCancel(state);
  };

  useEffect(() => {
    console.log("should init:" + shouldInit);
    if (true) {
      windowState.windowNavigation = navigation;
      props.setState(menu, windowState);
    }
  }, [windowState.name]);


  return (
    <Box
      sx={{
        width: "400px",
        height: "250px",
        background: "black",
        border: "1px solid white",
        p: 1,
        pl:2,
        pr:2
      }}
    >
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Typography variant="h6" sx={{color:colors.TEXT_COLOR, fontWeight: "bold" }}>
          <Translate value="DeviceStatus"/>
        </Typography>
      </Box>
      <Box
        sx={{ mt: 2, width: "100%", display: "flex", justifyContent: "center",flexDirection:"column" }}
      >
          {generateDeviceStatus(
            [
              {name:"AcusticAlarm",status:"OK"},
              {name:"Oximeter Sensor",status:"OK"},
              {name:"BackupCircuit",status:"OK"},
              {name:"Valve",status:"OK"},
              {name:"FlowMeter",status:"OK"},
              {name:"Memory",status:"OK"},
              {name:"Battery",status:"OK"},
          ]
          )}

      </Box>
    </Box>
  );
};

const generateDeviceStatus=(items)=>{
  return items.map(item=>{
    return(
    <Box sx={{display:"flex", justifyContent:"space-between",width:"100%"}}>
    <Typography variant="h6" sx={{color:colors.COLORS_SUBITEM_DEFAULT, fontWeight: "bold",lineHeight:"25px"}}>
      <Translate value={item.name}/>
    </Typography>
    <Typography variant="h6" sx={{color:colors.O2, fontWeight: "bold",lineHeight:"25px" }}>
      <Translate value={item.status}/> 
    </Typography>
    </Box>
    )
  })
}