export const generateTestChartData=()=>{
    const startTime = new Date();
    const time=[]
    const spo2=[]
    const o2=[]
    const pulse=[]
    for (let i = 0; i < 500; i++) {
        const unixTime = Math.floor(startTime.getTime() / 1000);
        time.push(801-i)
        startTime.setSeconds(startTime.getSeconds() - 1);
        spo2.push(100)
        pulse.push(42)
        o2.push(0.2)
    }
    return [time,spo2,pulse,o2]
}


export const createAverageArray=(data, length) =>{
    var finalArrayTime = [];
    var finalArraySpo2 = [];
    var finalArrayPulse = [];
    var finalArrayO2 = [];
    var dataLength = data.length;
    var numIterations = length;  // Number of complete iterations to reach the desired length
    var remainingPoints = length % 15;  // Remaining points to add after the complete iterations

    let toInsert=0
    if(data.length/15<length){
      toInsert=length-data.length
    }
    else if(data.length>length){
      data=data.splice(0,data.length-length)
    }

    for(var x=0;x<toInsert;x++){
      finalArrayTime.push(x);
      finalArraySpo2.push(null);
      finalArrayPulse.push(null);
      finalArrayO2.push(null);
    }

    // Iterate for the complete iterations
    for(var i = toInsert; i>0;i--){
      var time = numIterations-i;
      var sumPulse = 0;
      var sumSpO2 = 0;
      var sumO2 = 0;
      //spo2.push(data[length - i]?.spo2);
      for (var j = 0; j < 15; j++) {
        sumPulse += data[j]?.pulse;
        sumSpO2 += data[j]?.spo2;
        sumO2 += data[j]?.o2;
      }
      
      finalArrayTime.push(time);
      finalArraySpo2.push(sumSpO2/15);
      finalArrayPulse.push(sumPulse/15);
      finalArrayO2.push(sumO2/15);
      data = data.slice(15);
    }

    // for (var i = 0; i < numIterations; i++) {
    //   var time = i;
    //   var sumPulse = 0;
    //   var sumSpO2 = 0;
    //   var sumO2 = 0;
  
    //   // Calculate the sum of 15 points
    //   for (var j = 0; j < 15; j++) {
    //     sumPulse += data[j]?.pulse;
    //     sumSpO2 += data[j]?.spo2;
    //     sumO2 += data[j]?.o2;
    //   }

    //   finalArrayTime.push(time);
    //   finalArraySpo2.push(sumSpO2/15);
    //   finalArrayPulse.push(sumPulse/15);
    //   finalArrayO2.push(sumO2/15);
    //   data = data.slice(15);  // Remove the processed 15 points from the data
    // }
  
    // // Process the remaining points
    // if (remainingPoints > 0) {
    //   var sum = 0;
  
    //   // Calculate the sum of remaining points
    //   for (var i = 0; i < remainingPoints; i++) {
    //     sum += data[i];
    //   }
  
    //   var average = sum / remainingPoints;
    //   finalArray.push(average);
    // }time, spo2, pulse, o2];
  
    return [finalArrayTime,finalArraySpo2,finalArrayPulse,finalArrayO2];
  }
  export const calculateTrendInfoTestData = () => {
    const dataPoints = [];
    const totalSeconds = 24 * 60 * 60;
  
    const pulseStart = 40;
    const spo2Start = 80;
    const o2Start = 0;
  
    const pulseEnd = 200;
    const spo2End = 100;
    const o2End = 15;
  
    const pulseRange = pulseEnd - pulseStart;
    const spo2Range = spo2End - spo2Start;
    const o2Range = o2End - o2Start;
  
    for (let i = 0; i < totalSeconds; i++) {
      const currentTime = new Date();
      const timestamp = new Date(currentTime.getTime() - i * 1000); // Decrease timestamp by 1 second for each iteration
  
      const pulse = Math.round((pulseRange * i) / totalSeconds) + pulseStart;
      const spo2 = Math.round((spo2Range * i) / totalSeconds) + spo2Start;
      const o2 = parseFloat(((o2Range * i) / totalSeconds + o2Start).toFixed(1));
  
      dataPoints.push({ timestamp, o2, spo2, pulse });
    }
  
    const filterLength = totalSeconds; // Assuming the filter length is the entire duration of the data
  
    //const trendInfoData = calculateTrendInfoData(dataPoints, filterLength);
  
    return dataPoints;
  };

  export const calculateTrendInfoData=(dataPoints, filterLength) => {
    let currentTime = new Date();
    let filterTime = new Date(currentTime.getTime() - filterLength * 60000);
  
    let filteredData = dataPoints.filter(point => new Date(point.timestamp) >= filterTime);
  
    let o2Values = filteredData.map(point => point.o2);
    let spo2Values = filteredData.map(point => point.spo2);
    let pulseValues = filteredData.map(point => point.pulse);


    o2Values=o2Values.filter(point=>point!=-1)
    spo2Values=spo2Values.filter(point=>point!=-1)
    pulseValues=pulseValues.filter(point=>point!=-1)
  
    let o2Average = o2Values.length!=0?o2Values.reduce((sum, value) => sum + value, 0) / o2Values.length:0;
    let spo2Average = spo2Values.length!=0?spo2Values.reduce((sum, value) => sum + value, 0) / spo2Values.length:0;
    let pulseAverage = pulseValues.length!=0?pulseValues.reduce((sum, value) => sum + value, 0) / pulseValues.length:0;
  
    let o2Min =  o2Values.length!=0?Math.min(...o2Values):"--";
    let spo2Min = spo2Values.length!=0?Math.min(...spo2Values):"--";
    let pulseMin = pulseValues.length!=0? Math.min(...pulseValues):"--";
  
    let o2Max = o2Values.length!=0?Math.max(...o2Values):"--";
    let spo2Max = spo2Values.length!=0?Math.max(...spo2Values):"--";
    let pulseMax = pulseValues.length!=0?Math.max(...pulseValues):"--";

    let o2MinTimestampDiff = isNaN(Math.round((currentTime - new Date(filteredData.find(point => point.o2 === o2Min)?.timestamp)) / 60000))==false?Math.round((currentTime - new Date(filteredData.find(point => point.o2 === o2Min)?.timestamp)) / 60000):"--";
    let spo2MinTimestampDiff = isNaN(Math.round((currentTime - new Date(filteredData.find(point => point.spo2 === spo2Min)?.timestamp)) / 60000))==false?Math.round((currentTime - new Date(filteredData.find(point => point.spo2 === spo2Min)?.timestamp)) / 60000):"--";
    let pulseMinTimestampDiff = isNaN(Math.round((currentTime - new Date(filteredData.find(point => point.pulse === pulseMin)?.timestamp)) / 60000))==false?Math.round((currentTime - new Date(filteredData.find(point => point.pulse === pulseMin)?.timestamp)) / 60000):"--";
  
    let o2MaxTimestampDiff = isNaN(Math.round((currentTime - new Date(filteredData.find(point => point.o2 === o2Max)?.timestamp)) / 60000))==false?Math.round((currentTime - new Date(filteredData.find(point => point.o2 === o2Max)?.timestamp)) / 60000):"--";
    let spo2MaxTimestampDiff = isNaN(Math.round((currentTime - new Date(filteredData.find(point => point.spo2 === spo2Max)?.timestamp)) / 60000))==false?Math.round((currentTime - new Date(filteredData.find(point => point.spo2 === spo2Max)?.timestamp)) / 60000):"--";
    let pulseMaxTimestampDiff = isNaN(Math.round((currentTime - new Date(filteredData.find(point => point.pulse === pulseMax)?.timestamp)) / 60000))==false?Math.round((currentTime - new Date(filteredData.find(point => point.pulse === pulseMax)?.timestamp)) / 60000):"--";
  
    return {
      o2: {
        average: o2Average,
        min: o2Min,
        max: o2Max,
        minTimestampDiff: o2MinTimestampDiff,
        maxTimestampDiff: o2MaxTimestampDiff
      },
      spo2: {
        average: spo2Average,
        min: spo2Min,
        max: spo2Max,
        minTimestampDiff: spo2MinTimestampDiff,
        maxTimestampDiff: spo2MaxTimestampDiff
      },
      pulse: {
        average: pulseAverage,
        min: pulseMin,
        max: pulseMax,
        minTimestampDiff: pulseMinTimestampDiff,
        maxTimestampDiff: pulseMaxTimestampDiff
      }
    };
  }
  