import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
export const SideBarTitle = (props) => {
    return (
      <Box
        sx={{
          height: "30px",
          textAlign: "start",
          width: "80%",
          borderBottom: "1px solid white",
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
          lineHeight: "10px",
        }}
      >
        <CloseIcon sx={{cursor:"pointer"}} onClick={props.onClick} />
        {props.children}
      </Box>
    );
  };