import { keyframes } from "@emotion/react";

export const colors={
    BODY_COLOR_OFF:"#0a091a",
    BODY_COLOR_ON:"#0cb7eb",
    SPO2:"#009fe0",
    O2:"#00ff00",
    PULSE:"#ff6600",
    COLORS_BACKGROUND_DEFAULT:"#000000",
    COLORS_LAYOUT_BACKGROUND_NO_POWER:"#440000",
    COLORS_CUSTOMPROFILE: "#ffff00",
    COLORS_UNITS: "#666666",
    COLORS_SUBITEM_DEFAULT: "#aaaaaa",
    COLORS_DEFAULT_FALLBACK: "#ff00ff",
    COLORS_ALARM_LOW: "#ffff00",
    COLORS_ALARM_MEDIUM: "#ee9110",
    COLORS_ALARM_HIGH: "#ff0000",
    BODY_COLOR:"#494d4f",
    BODY_BUTTON_COLOR:"#494D4F",
    LAYOUT_PROFILE_TEXT_COLOR:"#aaaaaa",
    MENU_SELECTED_COLOR:"#39d6d6",
    TEXT_COLOR:"#FFFFFF"
    
}



export const blink = keyframes`
0% {
  opacity: 1;
}
50% {
  opacity: 1;
}
51% {
  opacity: 0;
}
100% {
  opacity: 0;
}
`;

export const loading = 
keyframes `
  to {
    clip-path: inset(0 -1ch 0 0)
  }
`;
