import { Box } from "@mui/system";
import { useState } from "react";
import { useLongPress } from "../../Utils";
import { colors } from "../../Style";

export const BUTTON_EVENT = {
  CLICK: "CLICK",
  LONG_PRESS: "LONG_PRESS",
  PRESS_DOWN: "PRESS_DOWN",
  RELEASE: "RELEASE",
};
export const NavigationButton = (props) => {
  const onLongPress = () => {
    props?.onLongPress({ type: BUTTON_EVENT.LONG_PRESS, button: props.value });
  };

  const onPressDown = () => {
     props?.onPressDown({ type: BUTTON_EVENT.PRESS_DOWN, button: props.value });
  };

  const onClick = () => {
    props?.onClick({ type: BUTTON_EVENT.CLICK, button: props.value });
  };

  const onRelease = () => {
    props?.onRelease({ type: BUTTON_EVENT.RELEASE, button: props.value });
  };
  // const onStartTouch = () => {
  //   props?.onStartTouch({ type: BUTTON_EVENT.RELEASE, button: props.value });
  // };



  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 1000,
  };
  const longPressEvent = useLongPress(
    onLongPress,
    onPressDown,
    onClick,
    onRelease,
    defaultOptions
  );

  return (
    <Box
      {...longPressEvent}
      component="button"
      sx={{
        position: "relative",
        width: "70%",
        height: "50px",
        background: colors.BODY_COLOR,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "3px 6px 0 black, -3px -6px 0 gray",
        borderRadius: "10px",
        "&:active": {
          boxShadow: "none",
          background:"gray"
        },
      }}
    >


      {/* <Box
        sx={{
            visibility:props.active?"visible":"hidden",
          color:"none",
          position: "absolute",
          background: "none",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          boxShadow: " 0 0 20px 15px " + colors.BODY_COLOR_ON,
        }}
      ></Box> */}
            <Box sx={{position:"absolute"}}>
      {props.children}
      </Box>
    </Box>
  );
};
NavigationButton.defaultProps = {
  onClick: () => {},
  value: "",
  onLongPress: () => {},
  onPressDown: () => {},
  onRelease: () => {},
};
