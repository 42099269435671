import { OximeterInputState } from "../components/OxygenInput";

export class OxygenInput {
    constructor(initialValue = 20) { 
      this.value = initialValue;
      this.listeners = new Set();
      this.increment=this.increment.bind(this)
      this.decrement=this.decrement.bind(this)
      this.setConnectedState=this.setConnectedState.bind(this)
      this.state=OximeterInputState.OXYGEN_CONNECTED

    }
    
    setConnectedState(state) {
      if (state == OximeterInputState.NO_OXYGEN) {
        this.value=0
      }
      else if(state == OximeterInputState.OXYGEN_CONNECTED){
        this.value=20
      }
      this.state=state
      this._fireChangeEvent();
    }
  
    increment() {
      if(this.value+1>20){
        return;
      }
      this.value+=1;
      this._fireChangeEvent()
    }
    decrement() {
      if(this.value-1<0){
        return;
      }
      this.value-=1;
      this._fireChangeEvent() 
    }

    subscribe(listener) {
      this.listeners.add(listener);
    }
  
    unsubscribe(listener) {
      this.listeners.delete(listener);
    }
  
    _fireChangeEvent() {
      this.listeners.forEach(listener => listener("test"));
    }
  }