export const English = {
    en: {
        "O2Input":"O₂ Input",
        "OximeterState":"Oximeter state",
        "Oximeter":"Oximeter",
        "SpO2":"SpO₂",
        "O2":"O₂",
        "Pulse":"Pulse",
        "b/min":"b/min",
        "l/min":"l/min",
        "Trends":"Trends",
        "Patient":"Patient",
        "Admin":"Admin",
        "Back":"Back",
        "Edit":"Edit",
        "Cancel":"Cancel",
        "Clear":"Clear",
        "Accept":"Accept",
        "Select":"Select",
        "Undo":"Undo",
        "Value":"Value",
        "Previous":"Previous",
        "Save":"Save",
        "Next":"Next",
        "Trend info":"Trend Info",
        "BatteryState":"Battery state",
        "Battery":"Battery",
        "Location":"Location",
        "FlowError":"Flow error",
        "NotConnected":"Not connected",
        "NoSignal":"No signal",
        "ManualMode":"Manual Mode",
        "NoPatientAlarms":"No patient Alarms",
        "PowerDown":"Power Down",
        "NewPatientID":"New Patient ID",
        "SetPatientName":"Set patient name",
        "NoSignalDelay":"No Signal Delay",
        "Profile":"Profile",
        "ConfirmRisky":"Confirm Risky Profile?",
        "ActiveProfile":"Active Profile: ",
        "CustomizeProfile":"Customize profile",
        "DoNotTurnOfDevice":"WARNING, DO NOT TURN OFF DEVICE WHILE FORMATTING.",
        "InputUsbPin":"Input USB PIN",
        "InputCurrentPin":"Input current PIN",
        "PinAccepted":"Pin accepted, choose a new PIN",
        "Number":"Number",
        "DeviceStatus":"Device Status",
        "OK":"OK",
        "AcusticAlarm":"Acustic Alarm",
        "Oximeter Sensor":"Oximeter Sensor",
        "BackupCircuit":"Backup Circuit",
        "Valve":"Valve",
        "FlowMeter":"Flow Meter",
        "Memory":"Memory",
        "Battery":"Battery",
        "Submit":"Submit",
        "Year":"Year",
        "Month":"Month",
        "Day":"Day",
        "Hour":"Hour",
        "Minute":"Minute",
        "SaveAndClose":"Save and close",
        "Commit":"Commit",
        "DeleteProfile?":"Delete Profile?",
        "DiscardChanges?":"Discard changes?",
        "Main":"Main",
        "Trend":"Trend",
        "TrendInfo":"Trend Info",
        "12M":"12M",
        "1H":"1H",
        "8H":"8H",
        "24H":"24H",
        "Contact":"Contact",
        "ConfirmReset":"Confirm reset",
        "Confirm":"Confirm",
        "EndTest":"End Test",
        "EndTest?":"End Test?",
    }
}