export class NavigationController {
    constructor() {
        this.menu = {
            LEFT: null,
            UP: null,
            RIGHT: null,
            MIDDLE: null,
            DOWN: null
        }
        this.ledControl = {
            HOME: false,
            POWER: false,
            NO_POWER: false,
            BATTERY: false,
            LEFT: false,
            RIGHT: false,
            UP: false,
            DOWN: false,
            MUTE: false,
            MODE: false,
        }
        this.navigation=null
        this.listeners = new Set()
        this.localNavigation = () => {
        }

        this.globalNavigate=(navigate)=>{
            if(this.navigation!=null){
                this.navigation.push(navigate.route)
            }
            this._fireChangeEvent(this,"SKIP")
        }
        this.disableLEDs = () => {
            Object.keys(this.ledControl).forEach(key => {
                this.ledControl[key] = false
            });
            this._fireChangeEvent()
        }
        this.setMenu = (menu) => {
            this.menu = menu;
            this.evaluteLed();
            this._fireChangeEvent()
        }
        this.evaluteLed = () => {
            if (this.menu == null || this.menu == undefined) {
                return;
            }
            Object.keys(this.menu).forEach(key => {
                if (this.menu[key] != null) {
                    this.ledControl[key] = true
                    return
                }
                this.ledControl[key] = false
            });
        }
        this.setLEDs = (leds) => {
            this.ledControl = leds
            this._fireChangeEvent()
        }
        this.onAction = (navigation, action) => {
            this.navigation=navigation;
            if (!this.menu[action.button] != null && this.menu[action.button]?.local == true) {
                try {
                    this?.localNavigation(navigation, action, this.menu)
                    this._fireChangeEvent()
                }
                catch (e) {
                    console.log(e)
                }
                return
            }

            if (action.type == "CLICK" && this.menu[action.button] != null) {

                navigation.push(this.menu[action.button].route)

            }
        }



        this.subscribe = (listener) => {
            this.listeners.add(listener);
        }

        this.unsubscribeAll = (listener) => {
            this.listeners = new Set()
        }
        this.unsubscribe = (listener) => {
            this.listeners.delete(listener);
        }

        this._fireChangeEvent = (navigation, action) => {
            for (let listener of this.listeners) {
                listener(navigation, action);
            }
        }
        this.addLocalNavigation = this.addLocalNavigation.bind(this)

    }
    addLocalNavigation(listener) {
        if (listener == null || listener == undefined) {
            this.localNavigation = () => { console.log("local navigation is null") }
            return;
        }
        this.localNavigation = listener
    }



}