import {
  Button,
  Input,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { Translate } from "react-i18nify";
import { colors } from "../Style";
import { createKeyValuePair, readKeyValuePair } from "../AzureBlobUtils";
import CustomLink from "./UI/CustomLink";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { HubSpotForm } from "./HubSpotForm";
import CloseIcon from "@mui/icons-material/Close";
import { SideBarTitle } from "./UI/SideBarTitle";
const { v4: uuidv4 } = require("uuid");

export const Contact = (props) => {
  const [boxStatus, setBoxStatus] = useState(false);
  const toggleBox = () => {
    setBoxStatus((boxStatus) => !boxStatus);
  };
  const [shareLink, setShareLink] = useState(null);
  const [shareName, setShareName] = useState("");
  const [linkCoppiedVisibility, setLinkCoppiedVisibility] = useState(false);
  const [importVisibility, setImportVisibility] = useState(false);
  const [feedbackVisibility, setFeedbackVisibility] = useState(false);
  const [popupType, setPopUpType] = useState("");
  const [importLink, setImportLink] = useState("");
  let path = window.location.origin;
  const shareButtonClicked = async () => {
    let proState = localStorage.getItem("proSimulator");
    let path = window.location.pathname;
    let windowState = window.location.search;
    let simulatorState = {
      proState: proState,
      windowState: windowState,
      path: path,
    };
    let result = await createKeyValuePair(
      shareName,
      JSON.stringify(simulatorState)
    );
    if (result.result) {
      //show link
      toggleBox();
      setShareLink(result.value);
      setPopUpType("share");
      console.log("show link" + result);
    } else {
      // show error
    }
  };

  const renderPopUp = (type) => {
    let result = null;
    switch (type) {
      case "share":
        result = (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "right",
              position: "relative",
            }}
          >
            <Typography sx={{color:"white"}} variant="h4">Share</Typography>
            {!shareLink ? (
              <>
                <Typography sx={{color:"white"}}>Write a custom name for the link</Typography>
                <TextField
                  sx={{ mt: 1, input: { color: "white" } }}
                  onChange={(event) => {
                    setShareName(event.target.value);
                  }}
                  focused
                  label="Custom name"
                  value={shareName}
                />
                <Box sx={{ display: "flex" }}>
                  <Button
                    onClick={() => {
                      let newShareName = uuidv4();
                      setShareName(newShareName);
                    }}
                  >
                    Random name
                  </Button>
                  <Button onClick={shareButtonClicked}>Share</Button>
                </Box>
              </>
            ) : null}
            {shareLink && !linkCoppiedVisibility ? (
              <>
                <Typography sx={{color:"white"}}>
                  Copy the link and share it with other people
                </Typography>
                <Box
                  onClick={() => {
                    navigator.clipboard.writeText(path + "/id/" + shareLink);
                    setLinkCoppiedVisibility(true);
                    setTimeout(() => {
                      setLinkCoppiedVisibility(false);
                    }, 1000);
                  }}
                  sx={{
                    width: "auto",
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={{color:"white"}}>{path + "/id/" + shareLink}</Typography>
                  <ContentCopyIcon sx={{ ml: 1, mr: 1 }} />
                </Box>
              </>
            ) : null}
            {linkCoppiedVisibility ? (
              <Typography sx={{ background: "black", m: 1,color:"white" }}>
                Link coppied!
              </Typography>
            ) : null}
          </Box>
        );
        break;
      case "import":
        result = (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{color:"white"}} variant="h4">Import</Typography>
            <Typography sx={{color:"white"}}>Paste the link provided to import data</Typography>
            <TextField
              sx={{ mt: 1, input: { color: "white" } }}
              onChange={(event) => {
                setImportLink(event.target.value);
              }}
              focused
              label="Link"
              defaultValue={importLink}
            />
            <Button
              onClick={() => {
                window.location.replace(importLink);
              }}
            >
              Import
            </Button>
          </Box>
        );
        break;
      // case "feedback":
      //   result = <HubSpotForm onSubmit={()=>{}}/>;
      //   break;
    }
    if (result == null) {
      return null;
    }

    return (
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          width: "400px",
          height: "200px",
          background: "black",
          border: "1px solid white",
          position: "absolute",
          left: -650,
          bottom: 300,
          zIndex: 50,
        }}
      >
        <CloseIcon
          onClick={() => {
            setPopUpType("");
            setShareName("");
            setImportLink("");
            setShareLink(null);
            setLinkCoppiedVisibility(false);
            setImportVisibility(false);
            setFeedbackVisibility(false);
          }}
          sx={{
            cursor: "pointer",
            width: "30px",
            height: "30px",
            alignSelf: "flex-end",
            position: "absolute",
            zIndex: 50,
            color:"white"
          }}
        />
        {result}
      </Box>
    );
  };

  const renderControls = () => {
    return (
      <Box
        sx={{
          width: "80%",
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          zIndex: 50,
        }}
      >
        <SideBarTitle onClick={() => toggleBox()}>
          <Typography sx={{ ml: 2, color: colors.TEXT_COLOR }}>
            <Translate value="Contact" />
          </Typography>
        </SideBarTitle>
        <Box
          sx={{
            width: "60px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "right",
            position: "relative",
          }}
        >
          <CustomLink
            href="https://www.o2matic.com/#kontakt"
            text={"Contact"}
          ></CustomLink>
        </Box>

        <CustomLink
            href="https://share.hsforms.com/1ZlOXjqzhSzK5X8hLyQclIA46t8q"
            text={"Feedback"}
          ></CustomLink>

        <Box
          sx={{
            width: "60px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "right",
            position: "relative",
          }}
        >
          <Button
            sx={{ width: "60px", alignSelf: "flex-start", height: "25px" }}
            onClick={() => {
              setPopUpType("share");
            }}
          >
            Share
          </Button>
        </Box>

        <Box
          sx={{
            width: "60px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "right",
            position: "relative",
          }}
        >
          <Button
            sx={{ alignSelf: "flex-start", height: "25px" }}
            onClick={() => {
              toggleBox();
              setPopUpType("import");
            }}
          >
            Import
          </Button>
        </Box>
      </Box>
    );
  };
  return (
    <Box sx={{ position: "relative", top: 0, zIndex: 50 }}>
      {renderPopUp(popupType)}

      <Box
        onClick={toggleBox}
        sx={{ width: "50px", height: "100%", position: "absolute", zIndex: 10 }}
      ></Box>
      <Box
        sx={{
          left: `calc(0% - ${!boxStatus ? "0" : "200"}px)`,
          width: `${!boxStatus ? "50" : "250"}px`,
          height: "150px",
          background: "black",
          p: 1,
          border: "1px solid white",
          position: "relative",
        }}
      >
        {boxStatus ? renderControls() : null}
      </Box>
      <Typography
        variant="h4"
        sx={{
          whiteSpace: "nowrap",
          userSelect: "none",
          position: "absolute",
          transform: "rotate(90deg)",
          top: "10px",
          left: "50px",
          transformOrigin: "0 0",
          color: colors.TEXT_COLOR,
        }}
      >
        <Translate value="Contact" />
      </Typography>
    </Box>
  );
};
