import { Typography } from "@mui/material"
import { loading,blink } from "../../Style"
import { Box } from "@mui/system"
import { useEffect } from "react"
import { useHistory } from "react-router"

export const Initializing=(props)=>{
    const navigate=useHistory();
    let navigation=props.navigation;

    useEffect(()=>{
      let menu = {
        LEFT: null,
        TOP: null,
        RIGHT: null,
        MIDDLE: null,
        BOTTOM: null
    }
      navigation.setMenu(menu)
    },[])

    return(
    <Box sx={{width:"100%",height:"100%", display:"flex", justifyContent:"center",alignItems:"center"}}>
        <Typography  variant="h5" sx={{
            display:"inline-block",

  clipPath:"inset(0 1ch 0 0)",
  animation: `${loading} 1s steps(4) infinite`}}>
            Initializing...
        </Typography>
    </Box>
)
}