import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory, createSearchParams, useLocation } from "react-router-dom";
import { useQuery } from "../../Utils";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { colors } from "../../Style";

export const ItemMenu = (props) => {
  const history = useHistory();
  const location = useLocation();

  const activeItemIndex = props.activeItemIndex;
  const selected = props.selected;
  const disabled = props.disabled;
  const menuItems = props.menuItems;
  const valueEditing = props.valueEditing;

  const RenderInputArea = (props) => {
    const amountOfLetters = props.length;
    const activeIndex = props.activeIndex;
    const value = props.value.toString();

    return [...Array(amountOfLetters)].map((_, i) => {
      return (
        <Box
          key={i}
          sx={{
            width: "25px",
            height: "25px",
            background: activeIndex == i ? "gray" : "black",
            border: "1px solid white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", color: colors.TEXT_COLOR }}
          >
            {value[i]}
          </Typography>
        </Box>
      );
    });
  };

  const RenderValue = (props) => {
    return (
      <Box
        sx={{
          width: "75px",
          justifyContent: "end",
          display: "flex",
          border:
            props.selectStyle == "border" && props.isActive && selected
              ? "1px solid white"
              : "none",
        }}
      >
        {props.selectStyle == "border" && props.isActive && selected ? (
          <Typography
            sx={{
              fontWeight: "bold",
              color: colors.COLORS_SUBITEM_DEFAULT
            }}
          >
            {props.menuItem.value}
          </Typography>
        ) : (
          <Typography
            sx={{
              fontWeight: "bold",
              color: props.isValueError
                ? colors.COLORS_ALARM_HIGH
                : props.isActive
                ? props.activeColor
                : props.valueColor
                ? props.valueColor
                : props.isSelectable
                ? props.isRisky
                  ? colors.COLORS_ALARM_MEDIUM
                  : colors.COLORS_SUBITEM_DEFAULT
                : colors.COLORS_UNITS,
            }}
          >
            {props.menuItem.value}
          </Typography>
        )}
        <Typography
          sx={{
            ml: "5px",
            width: "50px",
            display: props.menuItem.unit ? "block" : "none",
            fontWeight: "bold",
            color: colors.COLORS_UNITS,
          }}
        >
          {props.menuItem.unit}
        </Typography>
      </Box>
    );
  };
  const handleRight = async (currentIndex) => {
    const currentPath = location.pathname;
    const updatedPath = `${currentPath}${menuItems[currentIndex].location}`;
    history.push(updatedPath);
  };

  return (
    <Box
      sx={{
        mt: props.margin ? props.margin : 0,
        display: "flex",
        flexDirection: "column",
        width: props.width ? props.width : "100%",
      }}
    >
      {menuItems?.map((menuItem, index) => {
        let isLabelError = props.showErrors ? menuItem.labelError : false;
        let isValueError = props.showErrors ? menuItem.valueError : false;
        let valueColor = menuItem.valueColor;
        let labelColor = menuItem.labelColor;
        let isRisky = menuItem.risky;
        let isActive = index === activeItemIndex && !disabled;
        let isSelectable = !menuItem.notSelectable;
        let selectStyle = menuItem.style;
        let activeColor = selected ? colors.SPO2 : colors.MENU_SELECTED_COLOR;
        let isEditing = menuItem?.isEditing ? true : false;
        return (
          <Box
            sx={{ display: "flex", justifyContent: "space-between" }}
            key={index}
          >
            <Box sx={{ display: "flex" }}>
              <PlayArrowIcon
                sx={{
                  color: activeColor,
                  visibility: isActive ? "vissible" : "hidden",
                }}
              />

              <Typography
                sx={{
                  color: isLabelError
                    ? colors.COLORS_ALARM_HIGH
                    : isActive
                    ? activeColor
                    : labelColor
                    ? labelColor
                    : colors.COLORS_SUBITEM_DEFAULT,
                  fontWeight: "bold",
                }}
              >
                {menuItem.label}
              </Typography>
            </Box>

            {isEditing ? (
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <RenderInputArea
                  length={10}
                  activeIndex={valueEditing?.activeIndex}
                  value={valueEditing?.value}
                />
              </Box>
            ) : (
              <RenderValue
                selectStyle={selectStyle}
                menuItem={menuItem}
                activeColor={activeColor}
                isValueError={isValueError}
                isActive={isActive}
                isSelectable={isSelectable}
                isRisky={isRisky}
                valueColor={valueColor}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
};
