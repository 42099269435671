import { Box } from "@mui/system";
import { colors } from "../Style";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import BoltIcon from '@mui/icons-material/Bolt';
import CloseIcon from '@mui/icons-material/Close';
import { BatteryChargeState, BatteryState, getBatteryColor } from "../ProSimulator/ProBattery";
import { useEffect, useState } from "react";

export const BatteryView = (props) => {

  const battery=props.pro?.battery

  const [chargeState,setChargeState] = useState(battery?.chargeState)
  const [batteryColor,setBatteryColor] = useState(battery?.chargeState)

  useEffect(()=>{
    setBatteryColor(getBatteryColor(battery?.state))
  },[battery?.state])

  useEffect(()=>{
    setChargeState(battery?.chargeState)
  },[battery?.chargeState])
  return (
    <Box sx={{display:"flex",justifyContent:"center", flexDirection:"column",alignItems:"center"}}>
      <Box
      
        sx={{ width: "15px", height: "5px", border:batteryColor==colors.O2?"1px solid white":"1px solid "+batteryColor, borderBottom:"none",background:colors.COLORS_BACKGROUND_DEFAULT}}
      ></Box>
      <Box
        sx={{p:"1px",pt:"2px",width: "25px", height: "35px",border:batteryColor==colors.O2?"1px solid white":"1px solid "+batteryColor }}
      >
        <Box sx={{position:"relative",width:"100%",height:"100%", display:"flex",alignItems:"end",justifyContent:"center",background:colors.COLORS_BACKGROUND_DEFAULT}}>
          
        <Box sx={{m:"1px",width:"100%",height:battery?.charge+"%",background:chargeState==BatteryChargeState.NO_IDEA||chargeState==BatteryChargeState.FAIL?"none":batteryColor,position:"absolute"}}/>
          <BoltIcon sx={{color:colors.COLORS_ALARM_LOW,position:"relative", width:"100%",height:"100%",background:"transparent", display:chargeState==BatteryChargeState.CHARGING?"inline-block":"none"}}/>
          <QuestionMarkIcon sx={{color:colors.TEXT_COLOR,position:"relative", width:"100%",height:"100%",background:"transparent", display:chargeState==BatteryChargeState.NO_IDEA?"inline-block":"none"}}/> 
          <CloseIcon sx={{position:"relative", width:"100%",height:"100%",background:"transparent",color:colors.COLORS_ALARM_HIGH, display:chargeState==BatteryChargeState.FAIL?"inline-block":"none"}}/> 

        </Box>

      </Box>
    </Box>
  );
};
