export const isProfileCustom = (profile, oldProfile) => {
    if (profile.minSpO2 != oldProfile.minSpO2 ||
        profile.maxSpO2 != oldProfile.maxSpO2 ||
        profile.minO2 != oldProfile.minO2 ||
        profile.maxO2 != oldProfile.maxO2 ||
        profile.initialO2 != oldProfile.initialO2 ||
        profile.minPulse != oldProfile.minPulse ||
        profile.maxPulse != oldProfile.maxPulse ||
        profile.flowResponse != oldProfile.flowResponse ||
        profile.name != oldProfile.name
    ) {
        return true
    }
    return false
};


export const isRiskyProfile = (profile) => {
    if (profile.minSpO2 < 85 || profile.maxPulse > 150) {
        return true;
    }
    return false;
};
