import { Box } from "@mui/material";
export const PopUpWrapper = (props) => {
  return (
    <Box sx={{ position: "relative", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
    <Box sx={{zIndex:20, position: "absolute" }}>{props.popup}</Box>
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "start", width: "100%", height: "100%" }}>
    {props.children}
    </Box>
  </Box>
  );
};
