import { Box, Typography } from "@mui/material"
import { colors } from "../../Style"
import { Translate } from "react-i18nify"
export const PowerDown=(props)=>{
    if(!props.visible){
        return null
    }
    return(
        <Box sx={{width:"275px",height:"100px",background:"black",border:"1px solid white", display:"flex",flexDirection:"column",justifyContent:"space-around",alignItems:"center"}}>
            <Typography sx={{color:colors.TEXT_COLOR}} variant="h5"><Translate value="PowerDown"/></Typography>
            <Typography sx={{color:colors.COLORS_ALARM_LOW}} variant="h6">{props.count}</Typography>
        </Box>
    )
}