import { MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { OximeterState } from "../ProSimulator/OximeterSimulator";
import { useEffect, useState } from "react";
import { BatteryChargeState } from "../ProSimulator/ProBattery";
import { colors } from "../Style";
import { Translate } from "react-i18nify";
import { SideBarTitle } from "./UI/SideBarTitle";



export const BatteryInput = (props) => {
  const [boxStatus, setBoxStatus] = useState(false);
  const [batteryState, setBatteryState] = useState(props?.battery?.chargeState);

  useEffect(() => {
    props?.battery?.setChargeState(batteryState);
  }, [batteryState]);

  const renderControls = () => {
    return (
      <Box>
        <SideBarTitle onClick={()=>toggleBox()}>
          <Typography sx={{ ml: 2, color: colors.TEXT_COLOR }}>
            <Translate value="BatteryState" />
          </Typography>
        </SideBarTitle>

        <Select
          onChange={(event) => {
            setBatteryState(event.target.value);
          }}
          value={props.battery.chargeState}
          sx={{ mt: 1, width: "75%", height: "20px", background: "white" }}
        >
          <MenuItem value={BatteryChargeState.IDLE}>
            {BatteryChargeState.IDLE}
          </MenuItem>
          <MenuItem value={BatteryChargeState.CHARGING}>
            {BatteryChargeState.CHARGING}
          </MenuItem>
          <MenuItem value={BatteryChargeState.NO_POWER}>
            {BatteryChargeState.NO_POWER}
          </MenuItem>
          <MenuItem value={BatteryChargeState.FAIL}>
            {BatteryChargeState.FAIL}
          </MenuItem>
          <MenuItem value={BatteryChargeState.NO_IDEA}>{"UNKNOWN"}</MenuItem>
        </Select>
        <Box
          sx={{
            // visibility:oximeterState==OximeterState.SIGNAL_DATA?"visible":"hidden",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            mt: 2,
          }}
        >
          <Typography sx={{ color: colors.TEXT_COLOR }}>
            <Translate value="Battery" />:{props.battery.charge.toFixed(2)}%
          </Typography>
          <Box
            sx={{
              ml: 1,
              display: "flex",
              width: "100%",
              gap: 1,
            }}
          >
            <Box
              onClick={props.battery?.decrease}
              sx={{
                cursor: "pointer",
                width: "25px",
                height: "25px",
                background: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ userSelect: "none", color: "black" }}>
                -
              </Typography>
            </Box>
            <Box
              onClick={props.battery?.increase}
              sx={{
                cursor: "pointer",
                width: "25px",
                height: "25px",
                background: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography sx={{ userSelect: "none", color: "black" }}>
                +
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };
  const toggleBox = () => {
    setBoxStatus((boxStatus) => !boxStatus);
  };
  return (
    <Box sx={{ position: "relative", top: 0 }}>
      <Box
        onClick={toggleBox}
        sx={{ width: "50px", height: "100%", position: "absolute", zIndex: 10 }}
      ></Box>
      <Box
        sx={{
          left: `calc(0% - ${!boxStatus ? "0" : "200"}px)`,
          width: `${!boxStatus ? "50" : "250"}px`,
          height: "150px",
          background: "black",
          p: 1,
          border: "1px solid white",
          position: "relative",
        }}
      >
        {boxStatus ? renderControls() : null}
      </Box>
      <Typography
        variant="h4"
        sx={{
          whiteSpace: "nowrap",
          userSelect: "none",
          position: "absolute",
          transform: "rotate(90deg)",
          top: "10px",
          left: "50px",
          transformOrigin: "0 0",
          color: colors.TEXT_COLOR,
        }}
      >
        <Translate value="Battery" />
      </Typography>
    </Box>
  );
};
