import { Box, Typography } from "@mui/material";
import { colors } from "../../Style";

export const BatteryPopUp = (props) => {
  return (
    <Box
      sx={{
        p: 2,
        width: "350px",
        height: "150px",
        background: "black",
        border: "1px solid white",
        display: props.state.visible ? "flex" : "none",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <Box sx={{ height: "100%" }}>
        <Typography
          sx={{ color: colors.SPO2, fontWeight: "bold", fontSize: "30px" }}
          variant="h5"
        >
          Battery Mode
        </Typography>
      </Box>

      <Box sx={{ height: "100%" }}>
        <Typography
          sx={{
            color: colors.COLORS_ALARM_HIGH,
            fontWeight: "bold",
            fontSize: "30px",
          }}
          variant="h5"
        >
          Check Power
        </Typography>
      </Box>
      <Box sx={{ height: "100%" }}>
        {props.state.severity == 241 ? (
          <Typography
            sx={{ color: colors.COLORS_ALARM_HIGH, fontWeight: "bold", fontSize: "25px" }}
            variant="h5"
          >
            Battery critical
          </Typography>
        ) : null}
                {props.state.severity == 340 ? (
          <Typography
            sx={{ color: colors.COLORS_ALARM_HIGH, fontWeight: "bold", fontSize: "25px" }}
            variant="h5"
          >
            Shutdown imminent!
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};
