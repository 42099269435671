import { Box } from "@mui/material";
import { ItemMenu } from "./ItemMenu";
import { useEffect } from "react";
const generateMenuItems = (props) => {
  return [
    { label: "6_MWT", route: "6_MWT" },
    { label: "ISWT", route: "ISWT" },
    { label: "ESWT", route: "ESWT" },
  ];
};

const testProfiles = [
  { name: "6_MWT", duration:360,warmup:30,recovery:60 },
  { name: "ISWT", duration:600,warmup:30,recovery:60},
  { name: "ESWT", duration:600,warmup:30,recovery:60},
];

export const cycleValues = (direction, value, values, activeIndex) => {
  let currentIndex = activeIndex;

  let newIndex;
  if (direction === "up") {
    newIndex = currentIndex + 1;
    if (newIndex === values.length) {
      newIndex = values.length - 1;
    }
  } else if (direction === "down") {
    newIndex = currentIndex - 1;
    if (newIndex < 0) {
      newIndex = 0;
    }
  } else {
    return activeIndex;
  }

  return newIndex;
};
export const Tests = (props) => {
  const navigation = (navigator, action, menu, state) => {
    console.log({ action: action });
    console.log({ menu: menu });
    if (action.type === "CLICK") {
      switch (menu[action.button].route) {
        // case "NAVIGATE_CANCEL":
        //   cancelClicked(state);
        //   break;
        case "NAVIGATE_BACK":
          props.onCancel();
          break;
        case "NAVIGATE_SELECT":
          props.onComplete(testProfiles.find(profile=>profile.name==state.menuItems[state.activeItemIndex].route));
          break;

        case "VALUE_DOWN":
          let newValue = cycleValues(
            "up",
            null,
            state.menuItems,
            state.activeItemIndex
          );
          setWindowState({
            ...state,
            activeItemIndex: newValue,
            menu: updateNavigation(newValue, state.menuItems),
          });
          break;
        case "VALUE_UP":
          let newValue1 = cycleValues(
            "down",
            null,
            state.menuItems,
            state.activeItemIndex
          );
          setWindowState({
            ...state,
            activeItemIndex: newValue1,
            menu: updateNavigation(newValue1, state.menuItems),
          });
          break;
      }
    }
  };

  const shouldInit = props.menu == undefined ? true : false;
  const menuItems = props.menuItems;
  const defaultState = {
    activeItemIndex: 0,
    menuItems: generateMenuItems(),
    windowNavigation: navigation,
  };
  const menu = props.menu
    ? props.menu
    : {
        LEFT: { label: "Back", route: "NAVIGATE_BACK", local: true },
        UP: defaultState.modifyNavigation
          ? { label: "UP", route: "VALUE_UP", local: true }
          : null,
        RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
        MIDDLE: null,
        DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
      };
  defaultState.menu = menu;
  const windowState = props?.state
    ? {
        ...props.state,
        menu: menu,
      }
    : defaultState;

  console.log({ windowState: windowState });

  const setWindowState = (windowState) => {
    console.log({ setWindowState: windowState });
    props.setState(windowState.menu, windowState);
  };

  const updateNavigation = (index, values) => {
    let menu = {};
    let activeIndex = index;
    if (activeIndex == values.length - 1) {
      if (values[index].notSelectable) {
        menu = {
          LEFT: { label: "Back", route: "NAVIGATE_BACK", local: true },
          UP: { label: "UP", route: "VALUE_UP", local: true },
          RIGHT: null,
          MIDDLE: null,
          DOWN: null,
        };
      } else {
        menu = {
          LEFT: { label: "Back", route: "NAVIGATE_BACK", local: true },
          UP: { label: "UP", route: "VALUE_UP", local: true },
          RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
          DOWN: null,
        };
      }
    } else if (activeIndex == 0) {
      menu = {
        LEFT: { label: "Back", route: "NAVIGATE_BACK", local: true },
        UP: null,
        RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
        DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
        MIDDLE: null,
      };
    } else {
      menu = {
        LEFT: { label: "Back", route: "NAVIGATE_BACK", local: true },
        UP: { label: "UP", route: "VALUE_UP", local: true },
        RIGHT: { label: "Select", route: "NAVIGATE_SELECT", local: true },
        DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
        MIDDLE: null,
      };
    }
    return menu;
  };

  useEffect(() => {
    console.log("should init:" + shouldInit);
    if (true) {
      windowState.windowNavigation = navigation;
      props.setState(menu, windowState);
    }
  }, [windowState.name]);

  return (
    <Box
      sx={{
        width: "200px",
        height: "125px",
        background: "black",
        border: "1px solid white",
      }}
    >
      <ItemMenu
        margin={0}
        activeItemIndex={windowState.activeItemIndex}
        width="60%"
        menuItems={windowState.menuItems}
      />
    </Box>
  );
};
