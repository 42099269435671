import { Box } from "@mui/material";
import { colors } from "../Style";

export const MuteIndicator = (props) => {

const vissible=props.mute?.isMuted?"visible":"hidden"
const percentage=(props.mute?.time/props.mute?.duration*100)+"%"
  return (
    <Box
      sx={{
        visibility:vissible,
        width: "80px",
        height: "15px",
        background: "gray",
        border: "1px solid white",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          background: colors.BODY_BUTTON_COLOR,
          border: "1px solid black",
        }}
      >
        <Box
          sx={{
            width: percentage,
            height: "100%",
            background: "white"
          }}
        />
      </Box>
    </Box>
  );
};
