import { BlobServiceClient } from "@azure/storage-blob";
const { v4: uuidv4 } = require('uuid');
const connectionString = "BlobEndpoint=https://prosimulatordata.blob.core.windows.net/;QueueEndpoint=https://prosimulatordata.queue.core.windows.net/;FileEndpoint=https://prosimulatordata.file.core.windows.net/;TableEndpoint=https://prosimulatordata.table.core.windows.net/;SharedAccessSignature=sv=2022-11-02&ss=bfqt&srt=co&sp=rwautfx&se=2024-10-10T17:08:38Z&st=2023-05-31T09:08:38Z&spr=https,http&sig=1GUJZkOPw4NK3aEYR9OlVoa4UDhlko5M6t%2Fzo%2FBfdxg%3D";
const blobServiceClient = BlobServiceClient.fromConnectionString(connectionString);
const containerClient = blobServiceClient.getContainerClient("prosimulatordata");

export async function blobToString(blob) {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onloadend = (ev) => {
        resolve(ev.target.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsText(blob);
    });
  }
  export async function createKeyValuePair(key,value) {
    if(key==null){
      key = uuidv4();
    }
    const blobClient = containerClient.getBlockBlobClient(key);
    let result=await blobClient.upload(value, value.length);
    if(result._response.status==200||result._response.status==201){
        return {result:true,value:key}
    }
        return {result:false}
  }
  export async function readKeyValuePair(key) {
    const blobClient = containerClient.getBlockBlobClient(key);
    const downloadResponse = await blobClient.download();
    const downloaded = await blobToString(await downloadResponse.blobBody);
    console.log("Downloaded blob content", downloaded);
    return downloaded;
  }