import { Box, Typography } from "@mui/material";
import { colors } from "../../Style";
import { useEffect, useState } from "react";
import { useHistory, createSearchParams, useLocation } from "react-router-dom";
import { useQuery } from "../../Utils";

export const StringInputMODE = {
  MODE_DEFAULT: "MODE_DEFAULT",
  MODE_EDIT: "MODE_EDIT",
  MODE_CLEAR: "MODE_CLEAR",
};

export const StringInputMenu = (props) => {
  console.log({ stringProps: props });
  const navigation = (navigator, action, menu, state) => {
    console.log("StringInputMenuCLICK");
    console.log({ action: action });
    console.log({ menu: menu });
    if (action.type === "CLICK") {
      switch (menu[action.button].route) {
        case "NAVIGATE_CANCEL":
          cancelClicked(state);
          break;
        case "NAVIGATE_EDIT":
          editClicked(state);
          break;
        case "NAVIGATE_CLEAR":
          clearClicked(state);
          break;
        case "NAVIGATE_NEXT":
          nextClicked(state);
          break;
        case "NAVIGATE_PREVIOUS":
          previousClicked(state);
          break;
        case "NAVIGATE_SAVE":
          saveClicked(state);
          break;
        case "NAVIGATE_ACCEPT":
          acceptClicked(state);
          break;
        case "NAVIGATE_UNDO":
          undoClicked(state);
          break;
        case "VALUE_UP":
          valueUp(state);
          break;
        case "VALUE_DOWN":
          valueDown(state);
          break;
      }
    }
  };

  const shouldInit = props.menu == undefined ? true : false;

  const defaultState = {
    mode: StringInputMODE.MODE_DEFAULT,
    name: props.name,
    activeLetter: 0,
    label: props.label,
    value: props.value,
    newValue: props.value,
    characters: props.characters,
    mode: StringInputMODE.MODE_DEFAULT,
    windowNavigation: navigation,
  };

  const menu = props.menu
    ? props.menu
    : {
        LEFT: { label: "Cancel", route: "NAVIGATE_CANCEL", local: true },
        UP: { label: "Edit", route: "NAVIGATE_EDIT", local: true },
        RIGHT: { label: "Accept", route: "NAVIGATE_ACCEPT", local: true },
        MIDDLE: null,
        DOWN: { label: "Clear", route: "NAVIGATE_CLEAR", local: true },
      };

  defaultState.menu = menu;
  const windowState = props?.state
    ? { ...props.state, menu: menu }
    : defaultState;

  console.log({ windowState: windowState });

  const setWindowState = (windowState) => {
    console.log({ setWindowState: windowState });
    props.setState(windowState.menu, windowState);
  };

  const setMenu = (menu) => {
    setWindowState({ ...windowState, menu: menu });
  };

  const setWindowMode = (mode) => {
    if (mode === StringInputMODE.MODE_EDIT) {
      setWindowState({
        ...windowState,
        mode: StringInputMODE.MODE_EDIT,
        menu: {
          LEFT: { label: "Undo", route: "NAVIGATE_UNDO", local: true },
          UP: { label: "UP", route: "VALUE_UP", local: true },
          RIGHT: { label: "Next", route: "NAVIGATE_NEXT", local: true },
          MIDDLE: { label: "Value" },
          DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
        },
      });
      return;
    } else if (mode === StringInputMODE.MODE_CLEAR) {
      setWindowState({
        ...windowState,
        mode: StringInputMODE.MODE_EDIT,
        newValue: windowState.characters[0],
        menu: {
          LEFT: { label: "Undo", route: "NAVIGATE_UNDO", local: true },
          UP: { label: "UP", route: "VALUE_UP", local: true },
          RIGHT: { label: "Next", route: "NAVIGATE_NEXT", local: true },
          MIDDLE: { label: "Value" },
          DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
        },
      });
      return;
    }
    setWindowState({ ...windowState, mode: mode });
  };

  const cancelClicked = (state) => {
    props.onCancel(state);
  };
  const acceptClicked = (state) => {
    props.onComplete(state);
  };
  const valueUp = (state) => {
    let newValue = cycleCharacter(
      "up",
      state.newValue,
      state.characters,
      state.activeLetter
    );
    state.newValue=newValue;
    console.log({ valueUpState: state });
    setWindowState({
      ...state,
      newValue: newValue,
      menu: updateNavigation(state, state.activeLetter),
    });
  };
  const valueDown = (state) => {
    let newValue = cycleCharacter(
      "down",
      state.newValue,
      state.characters,
      state.activeLetter
    );
    state.newValue=newValue;
    setWindowState({
      ...state,
      newValue: newValue,
      menu: updateNavigation(state, state.activeLetter),
    });
  };

  const nextClicked = (state) => {
    setWindowState({
      ...state,
      activeLetter: state.activeLetter + 1,
      menu: updateNavigation(state, state.activeLetter + 1),
    });
  };
  const previousClicked = (state) => {
    setWindowState({
      ...state,
      activeLetter: state.activeLetter - 1,
      menu: updateNavigation(state, state.activeLetter - 1),
    });
  };

  const editClicked = () => {
    setWindowMode(StringInputMODE.MODE_EDIT);
  };
  const saveClicked = (state) => {
    setWindowState({
      ...state,
      mode:StringInputMODE.MODE_DEFAULT,
      menu: {
        LEFT: { label: "Cancel", route: "NAVIGATE_CANCEL", local: true },
        UP: { label: "Edit", route: "NAVIGATE_EDIT", local: true },
        RIGHT: { label: "Accept", route: "NAVIGATE_ACCEPT", local: true },
        MIDDLE: null,
        DOWN: { label: "Clear", route: "NAVIGATE_CLEAR", local: true },
      },
    });
  };
  const undoClicked = () => {
    setWindowState({
      ...windowState,
      newValue: windowState.value,
      mode: StringInputMODE.MODE_DEFAULT,
      menu: {
        LEFT: { label: "Cancel", route: "NAVIGATE_CANCEL", local: true },
        UP: { label: "Edit", route: "NAVIGATE_EDIT", local: true },
        RIGHT: { label: "Accept", route: "NAVIGATE_ACCEPT", local: true },
        MIDDLE: null,
        DOWN: { label: "Clear", route: "NAVIGATE_CLEAR", local: true },
      },
    });
    // setMenu({
    //   LEFT: { label: "Cancel", route: "NAVIGATE_CANCEL", local: true },
    //   UP: { label: "Edit", route: "NAVIGATE_EDIT", local: true },
    //   RIGHT: { label: "Accept", route: "NAVIGATE_ACCEPT", local: true },
    //   MIDDLE: null,
    //   DOWN: { label: "Clear", route: "NAVIGATE_CLEAR", local: true },
    // });
    // setValue(windowState.value);
    // setWindowMode(StringInputMODE.MODE_DEFAULT);
  };
  const clearClicked = (state) => {
    setWindowMode(StringInputMODE.MODE_CLEAR);
  };

  const activeLetter = windowState.activeLetter;
  const NUMBER_OF_LETTERS = 10;

  const setValue = (value) => {
    setWindowState((prev) => {
      return {
        ...prev,
        newValue: value,
      };
    });
  };

  const updateNavigation = (windowState, activeLetter) => {
    let menu = {};
    if (windowState.mode == StringInputMODE.MODE_EDIT) {
      if (activeLetter == 0) {
        menu = {
          LEFT: { label: "Undo", route: "NAVIGATE_UNDO", local: true },
          UP: { label: "UP", route: "VALUE_UP", local: true },
          RIGHT: { label: "Next", route: "NAVIGATE_NEXT", local: true },
          MIDDLE: { label: "Value" },
          DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
        };
      } else if (
        activeLetter == windowState.newValue.toString().length - 1 &&
        windowState.newValue.toString()[activeLetter] === " "
      ) {
        menu = {
          LEFT: { label: "Previous", route: "NAVIGATE_PREVIOUS", local: true },
          UP: { label: "UP", route: "VALUE_UP", local: true },
          RIGHT: { label: "Save", route: "NAVIGATE_SAVE", local: true },
          MIDDLE: { label: "Value" },
          DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
        };
      } else if (activeLetter >= windowState.newValue.toString().length) {
        menu = {
          LEFT: { label: "Previous", route: "NAVIGATE_PREVIOUS", local: true },
          UP: { label: "UP", route: "VALUE_UP", local: true },
          RIGHT: { label: "Save", route: "NAVIGATE_SAVE", local: true },
          MIDDLE: { label: "Value" },
          DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
        };
      } else {
        menu = {
          LEFT: { label: "Previous", route: "NAVIGATE_PREVIOUS", local: true },
          UP: { label: "UP", route: "VALUE_UP", local: true },
          RIGHT: { label: "Next", route: "NAVIGATE_NEXT", local: true },
          MIDDLE: { label: "Value" },
          DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
        };
      }
    }
    return menu;
  };
  useEffect(() => {
    console.log("should init:" + shouldInit);
    if (true) {
      windowState.windowNavigation = navigation;
      props.setState(menu, windowState);
    }
  }, [windowState.name]);

  // useEffect(() => {
  //   if (windowState.mode == StringInputMODE.MODE_EDIT) {
  //     if (activeLetter == 0) {
  //       setMenu({
  //         LEFT: { label: "Undo", route: "NAVIGATE_UNDO", local: true },
  //         UP: { label: "UP", route: "VALUE_UP", local: true },
  //         RIGHT: { label: "Save", route: "NAVIGATE_SAVE", local: true },
  //         MIDDLE: { label: "Value" },
  //         DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
  //       });
  //     } else if (
  //       activeLetter == windowState.newValue.toString().length - 1 &&
  //       windowState.newValue.toString()[activeLetter] === " "
  //     ) {
  //       setMenu({
  //         LEFT: { label: "Previous", route: "NAVIGATE_PREVIOUS", local: true },
  //         UP: { label: "UP", route: "VALUE_UP", local: true },
  //         RIGHT: { label: "Save", route: "NAVIGATE_SAVE", local: true },
  //         MIDDLE: { label: "Value" },
  //         DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
  //       });
  //     } else if (activeLetter >= windowState.newValue.toString().length) {
  //       setMenu({
  //         LEFT: { label: "Previous", route: "NAVIGATE_PREVIOUS", local: true },
  //         UP: { label: "UP", route: "VALUE_UP", local: true },
  //         RIGHT: { label: "Save", route: "NAVIGATE_SAVE", local: true },
  //         MIDDLE: { label: "Value" },
  //         DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
  //       });
  //     } else {
  //       setMenu({
  //         LEFT: { label: "Previous", route: "NAVIGATE_PREVIOUS", local: true },
  //         UP: { label: "UP", route: "VALUE_UP", local: true },
  //         RIGHT: { label: "Next", route: "NAVIGATE_NEXT", local: true },
  //         MIDDLE: { label: "Value" },
  //         DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
  //       });
  //     }
  //   }
  //   props.setState(windowState.menu, windowState);
  // }, [windowState.newValue, windowState.activeLetter]);

  return (
    <Box
      sx={{
        width: "450px",
        height: "225px",
        background: "black",
        border: "1px solid white",
        p: 1,
      }}
    >
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Typography variant="h6" sx={{color:colors.TEXT_COLOR, fontWeight: "bold" }}>
          {windowState?.label}
        </Typography>
      </Box>
      <Box
        sx={{ mt: 2, width: "100%", display: "flex", justifyContent: "center" }}
      >
        <Typography variant="h4" sx={{color:colors.TEXT_COLOR, fontWeight: "bold" }}>
          {windowState?.mode == StringInputMODE.MODE_DEFAULT
            ? windowState.newValue
            : null}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {windowState?.mode == StringInputMODE.MODE_EDIT ? (
          <RenderInputArea
            length={NUMBER_OF_LETTERS}
            activeIndex={activeLetter}
            value={windowState.newValue}
          />
        ) : null}
      </Box>
    </Box>
  );
};
const RenderInputArea = (props) => {
  const amountOfLetters = props.length;
  const activeIndex = props.activeIndex;
  const value = props.value.toString();

  return [...Array(amountOfLetters)].map((_, i) => {
    return (
      <Box
        key={i}
        sx={{
          width: "35px",
          height: "35px",
          background: activeIndex == i ? "gray" : "black",
          border: "1px solid white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "bold",color:colors.TEXT_COLOR }}>
          {value[i]}
        </Typography>
      </Box>
    );
  });
};

export const cycleCharacter = (direction, value, characters, activeIndex) => {
  // get the current character at the active index
  value = value.toString();
  let currentChar = value[activeIndex];

  // find the index of the current character in the characters array
  let currentIndex = characters.indexOf(currentChar);

  // calculate the new index based on the direction
  let newIndex;
  if (direction === "up") {
    newIndex = currentIndex + 1;
    if (newIndex === characters.length) {
      newIndex = 0;
    }
  } else if (direction === "down") {
    newIndex = currentIndex - 1;
    if (newIndex < 0) {
      newIndex = characters.length - 1;
    }
  } else {
    return value; // invalid direction, return original value
  }

  // update the value at the active index with the new character
  let newValue =
    value.slice(0, activeIndex) +
    characters[newIndex] +
    value.slice(activeIndex + 1);

  // if the activeIndex is not the last index and the new character is a space, skip it
  if (activeIndex < value.length - 1 && characters[newIndex] === " ") {
    return cycleCharacter(direction, newValue, characters, activeIndex);
  }

  return newValue;
};
