
export const getDateString = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const dateString = `${year}/${month}/${day}`;
    return dateString;
}


export const getTimeString = () => {
    const today = new Date();
    const hours = String(today.getHours()).padStart(2, '0');
    const minutes = String(today.getMinutes()).padStart(2, '0');
    const timeString = `${hours}:${minutes}`;
    return timeString;
}
export const getDateStringFromDate = (date) => {
    if(date==null||date==undefined){
        return ""
    }
    let dateObject=new Date(date);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const day = String(dateObject.getDate()).padStart(2, '0');
    const dateString = `${year}/${month}/${day}`;
    return dateString;
}


export const getTimeStringFromDate = (date) => {
    if(date==null||date==undefined){
        return ""
    }
    let dateObject=new Date(date);
    const hours = String(dateObject.getHours()).padStart(2, '0');
    const minutes = String(dateObject.getMinutes()).padStart(2, '0');
    const timeString = `${hours}:${minutes}`;
    return timeString;
}


export const getDateFromObject=(object)=>{
    return new Date(object.year, object.month - 1, object.day, object.hours, object.minutes);
}

export const getNumberOfDaysInMonth=(date)=> {
    const year = date.year
    const month = date.month
    return new Date(year, month, 0).getDate();
  }

export const getDateObject = (date)=>{
    const year = date.getFullYear();
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    return{
        year:year,
        month:month,
        day:day,
        hours:hours,
        minutes:minutes
    }
}


export const convertMinutesToTHHMM=(minutes)=> {
    if(minutes=="--"){
        return "T--:--"
    }
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
  
    let hoursString = hours.toString().padStart(2, '0');
    let minutesString = remainingMinutes.toString().padStart(2, '0');
  
    return `T-${hoursString}:${minutesString}`;
  }

  export const secondsToMMSS=(seconds) => {
    var minutes = Math.floor(seconds / 60);
    var remainingSeconds = seconds % 60;
  
    var formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    var formattedSeconds = remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;
  
    return formattedMinutes + ":" + formattedSeconds;
  }
  