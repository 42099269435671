import { OximeterInputState } from "../components/OxygenInput";

export const OximeterState = {
  NOT_CONNECTED: 'Not connected',
  NO_SIGNAL: 'No signal',
  SIGNAL_DATA: 'Connected'
};

export class OximeterSimulator {
  constructor() {
    this.spo2 = -1;
    this.pulse = -1;
    this.state = OximeterState.NOT_CONNECTED;
    this.listeners = new Set();
    this.incrementSpO2 = this.incrementSpO2.bind(this);
    this.decrementSpO2 = this.decrementSpO2.bind(this);
    this.incrementPulse = this.incrementPulse.bind(this);
    this.decrementPulse = this.decrementPulse.bind(this);
    this.setState = this.setState.bind(this)
    this.setPulse = this.setPulse.bind(this)
    this.setSpO2 = this.setSpO2.bind(this)

  }

  
  incrementSpO2() {
    if (this.state != OximeterState.SIGNAL_DATA) {
      this.spo2 = -1;
      this.pulse = -1;
      return;
    }
    if (this.spo2 < 100) {
      this.setSpO2(this.spo2 + 1);
      return
    }
    this._fireChangeEvent();

  }

  decrementSpO2() {
    if (this.state != OximeterState.SIGNAL_DATA) {
      this.spo2 = -1;
      this.pulse = -1;
      return;
    }
    this.setSpO2(this.spo2 - 1);
  }

  setSpO2(value) {
    this.spo2 = value;
    this._fireChangeEvent();
  }

  incrementPulse() {
    if (this.state != OximeterState.SIGNAL_DATA) {
      this.spo2 = -1;
      this.pulse = -1;
      return;
    }
    this.setPulse(this.pulse + 1);
  }

  decrementPulse() {
    if (this.state != OximeterState.SIGNAL_DATA) {
      this.spo2 = -1;
      this.pulse = -1;
      return;
    }
    this.setPulse(this.pulse - 1);
  }

  setPulse(value) {
    this.pulse = value;
    this._fireChangeEvent();
  }

  subscribe(listener) {
    this.listeners.add(listener);
  }

  unsubscribe(listener) {
    this.listeners.delete(listener);
  }

  _fireChangeEvent() {
    this.listeners.forEach(listener => listener("test"));
  }

  setState(state) {
    if (state != this.state && state == OximeterState.SIGNAL_DATA) {
      this.setPulse(70)
      this.setSpO2(95)
    }
    else if(state != this.state && state == OximeterState.NOT_CONNECTED|| state == OximeterState.NO_SIGNAL){
      this.setPulse(-1)
      this.setSpO2(-1)
    }
    // else{
    //   console.log("counter set to 0")
    //   this.counter=0;
    // }
    this.state = state;
    this._fireChangeEvent();
  }
}