import { Box, Typography } from "@mui/material";
import { colors } from "../../Style";
import { useEffect, useState } from "react";
import { useHistory, createSearchParams, useLocation } from "react-router-dom";
import { useQuery } from "../../Utils";

const findActiveIndex = (value, values) => {
  return values.indexOf(value);
};
export const SelectInputMenu = (props) => {
  console.log({ stringProps: props });
  const navigation = (navigator, action, menu, state) => {
    console.log({ action: action });
    console.log({ menu: menu });
    if (action.type === "CLICK") {
      switch (menu[action.button].route) {
        case "NAVIGATE_CANCEL":
          cancelClicked(state);
          break;
        case "NAVIGATE_SAVE":
          saveClicked(state);
          break;
        case "VALUE_UP":
          valueUp(state);
          break;
        case "VALUE_DOWN":
          valueDown(state);
          break;
      }
    }
  };

  const shouldInit = props.menu == undefined ? true : false;
  const menuItems = props.menuItems;
  const defaultState = {
    name: props.name,
    activeIndex: 0,
    label: props.label,
    value: props.value,
    newValue: props.value,
    values: props.values,
    windowNavigation: navigation,
  };

  const menu = props.menu
    ? props.menu
    : {
        LEFT: { label: "Cancel", route: "NAVIGATE_CANCEL", local: true },
        UP: { label: "UP", route: "VALUE_UP", local: true },
        RIGHT: { label: "Save", route: "NAVIGATE_SAVE", local: true },
        MIDDLE: { label: "Value" },
        DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
      };

  defaultState.menu = menu;
  const windowState = props?.state
    ? {
        ...props.state,
        menu: menu,
        activeIndex: findActiveIndex(props.value, props.values),
      }
    : defaultState;

  console.log({ windowState: windowState });

  const setWindowState = (windowState) => {
    console.log({ setWindowState: windowState });
    props.setState(windowState.menu, windowState);
  };


  const cancelClicked = (state) => {
    props.onCancel(state);
  };

  const valueUp = (state) => {
    let newValue = cycleValues(
      "up",
      state.newValue,
      state.values,
      state.activeIndex
    );

    setWindowState({
      ...state,
      newValue: newValue,
      menu: updateNavigation(state, newValue),
    });
  };

  const valueDown = (state) => {
    let newValue = cycleValues(
      "down",
      state.newValue,
      state.values,
      state.activeIndex
    );

    setWindowState({
      ...state,
      newValue: newValue,
      menu: updateNavigation(state, newValue),
    });
  };

  const saveClicked = (state) => {
    props.onComplete(state)
  };



  const updateNavigation = (windowState, value) => {
    let menu = {};
    let activeIndex = findActiveIndex(value, windowState.values);
    if (activeIndex == 0) {
      menu = {
        LEFT: { label: "Cancel", route: "NAVIGATE_CANCEL", local: true },
        UP: { label: "UP", route: "VALUE_UP", local: true },
        RIGHT: { label: "Save", route: "NAVIGATE_SAVE", local: true },
        MIDDLE: { label: "Value" },
        DOWN: null,
      };
    } else if (activeIndex == windowState.values.length - 1) {
      menu = {
        LEFT: { label: "Cancel", route: "NAVIGATE_CANCEL", local: true },
        UP: null,
        RIGHT: { label: "Save", route: "NAVIGATE_SAVE", local: true },
        MIDDLE: { label: "Value" },
        DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
      };
    } else {
      menu = {
        LEFT: { label: "Cancel", route: "NAVIGATE_CANCEL", local: true },
        UP: { label: "UP", route: "VALUE_UP", local: true },
        RIGHT: { label: "Save", route: "NAVIGATE_SAVE", local: true },
        MIDDLE: { label: "Value" },
        DOWN: { label: "DOWN", route: "VALUE_DOWN", local: true },
      };
    }
    return menu;
  };
  useEffect(() => {
    console.log("should init:" + shouldInit);
    if (true) {
      windowState.windowNavigation = navigation;
      props.setState(menu, windowState);
    }
  }, [windowState.name]);


  return (
    <Box
      sx={{
        width: "450px",
        height: "225px",
        background: "black",
        border: "1px solid white",
        p: 1,
      }}
    >
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Typography variant="h6" sx={{color:colors.TEXT_COLOR, fontWeight: "bold" }}>
          {windowState?.label}
        </Typography>
      </Box>
      <Box
        sx={{ mt: 2, width: "100%", display: "flex", justifyContent: "center" }}
      >
        <Typography variant="h4" sx={{color:colors.TEXT_COLOR, fontWeight: "bold" }}>
          {props.format(windowState.newValue)}
        </Typography>
      </Box>
    </Box>
  );
};

export const cycleValues = (direction, value, values, activeIndex) => {
  let currentIndex = values.indexOf(value);

  // calculate the new index based on the direction
  let newIndex;
  if (direction === "up") {
    newIndex = currentIndex + 1;
    if (newIndex === values.length) {
      newIndex = values.length - 1;
    }
  } else if (direction === "down") {
    newIndex = currentIndex - 1;
    if (newIndex < 0) {
      newIndex = 0;
    }
  } else {
    return value; // invalid direction, return original value
  }

  // update the value at the active index with the new character
  // let newValue =
  //   value.slice(0, activeIndex) +
  //   characters[newIndex] +
  //   value.slice(activeIndex + 1);

  // // if the activeIndex is not the last index and the new character is a space, skip it
  // if (activeIndex < value.length - 1 && characters[newIndex] === " ") {
  //   return cycleCharacter(direction, newValue, characters, activeIndex);
  // }

  return values[newIndex];
};
