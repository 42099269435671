import { Box, Typography } from "@mui/material";
import { colors } from "../../Style";
import { Translate, translate } from "react-i18nify";
import { useState, useEffect } from "react";
import { PIN_ROLE, PinMenu } from "../../components/UI/PinMenu";
import { useHistory, createSearchParams, useLocation } from "react-router-dom";
import { usePageState, useQuery } from "../../Utils";
import { PopUpWrapper } from "../../components/PopUpWrapper";
import { ConfirmRiskyProfile } from "../../components/UI/ConfirmRiskyProfile";
const adminReducer = (state, action) => {
  return action;
};

const GetWindowState = () => {
  let query = useQuery();
  let location = useLocation();
  let url = location.pathname;
  let state = JSON.parse(query.get("windowState"));
  return state;
};
export const Reset = (props) => {
  const history = useHistory();
  const location = useLocation();
  const state = GetWindowState();
  const [popup, setPopUp] = useState(null);
  const [pageState, setPageState] = usePageState(
    adminReducer,
    state
      ? state
      : {
          menu: {
            LEFT: { label: "Back", route: "home" },
            UP: null,
            RIGHT: { label: "Select", route: "select", local: true },
            MIDDLE: null,
            DOWN: { label: "Down", route: "down", local: true },
          },
          window: null,
          windowName:"pinMenu",
          activeItemIndex: 0,
        }
  );
  const renderPopUp = (state) => {
    console.log("RENDERIND POP UP");
    let popUp;
    switch (state.windowName) {
      case "pinMenu":
        popUp = (
          <PinMenu
          name={"pinMenu"}
          label={translate("InputUsbPin")}
          setState={setWindowState}
          role={PIN_ROLE.VERIFY_PIN}
          currentPin={props.pro.pin.usb}
          menu={state?.window?.menu}
          state={state?.window?.state}
          onComplete={(eneteredPin)=>{
            if(eneteredPin==props.pro.pin.usb){
              setPageState((prev) => {
                return {
                  ...prev,
                  windowName: "confirm",
                  window: null,
                };
              });
              return;
            }
            console.log("incorrect pin")
          }}
          onCancel={()=>{
            history.push({
              pathname: "/admin"
            });

          }}
          />
        );
        break;
        case "confirm":
          popUp=(
            <ConfirmRiskyProfile
              message={
                <Typography variant="h6" sx={{ color: colors.COLORS_ALARM_LOW }}>
                  <Translate value={"ConfirmReset"} />
                </Typography>
              }
              name={"confirm"}
              onComplete={(state) => {
                props.pro.format()
                history.push({
                  pathname: "/home"
                });
                return;
              }}
              onCancel={() => {
                history.push({
                  pathname: "/admin"
                });

              }}
              setState={setWindowState}
              menu={state?.window?.menu}
              state={state?.window?.state}
            />
          );
    }

    return popUp;
  };

  const setWindowState = (state, windowState) => {
    setPageState((prev) => {
      return {
        ...prev,
        window: { ...prev.window, state: windowState, menu: state },
      };
    });
    props.navigation.setMenu(state);
  };

  
  const localNavigation = (navigator, action, menu) => {
    console.log({ pageState: pageState });
    if (action.type == "CLICK") {
    }
    if (pageState.window?.state?.windowNavigation != null) {
      console.log("executing window naw");
      pageState.window?.state?.windowNavigation(
        navigator,
        action,
        menu,
        pageState?.window?.state
      );
      return;
    }

    if (action.type == "CLICK") {
      switch (action.button) {
        case "DOWN":
          //handleDown(navigator, action, menu);
          break;
        case "LEFT":
          //handleLeft(navigator, action, menu);
          break;
        case "UP":
          //handleUp(navigator, action, menu);
          break;
        case "RIGHT":
          //handleRight(navigator, action, menu);
          break;
      }
    }
  };
  useEffect(() => {
    const state = pageState;
    // if(state.window){
    //   state.window.menu=null;
    // }

    console.log({ pageState: pageState });
    const searchParams = new URLSearchParams();
    searchParams.append("windowState", JSON.stringify(state));
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });

    if (
      pageState?.window?.menu == null ||
      pageState?.window?.menu == undefined
    ) {
      props.navigation.setMenu(pageState.menu);
    }
    props.navigation.addLocalNavigation(localNavigation);
    return () => {
      props.navigation.addLocalNavigation(null);
    };
  }, [pageState]);

  useEffect(() => {
    let pop = renderPopUp(pageState);
    setPopUp(pop);
  }, [pageState.windowName, pageState.window]);


  return (
    <Box
      sx={{
        pb: 3,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        background: colors.COLORS_LAYOUT_BACKGROUND_NO_POWER,
        width: "100%",
        height: "100%",
      }}
    >
      {popup}
      <Typography
        variant="h5"
        sx={{ mt: 5, color: colors.COLORS_ALARM_MEDIUM, fontWeight: "bold" }}
      >
        <Translate value="DoNotTurnOfDevice" />
      </Typography>
    </Box>
  );
};
