import { setTranslations, setLocale,setHandleMissingTranslation } from 'react-i18nify';
import { English } from './English';
//https://icons8.com/icon/set/flags/fluency
const defaultLanguage="en";


const TranslationConfig=()=>{
    setTranslations({...English});
    setLocale(defaultLanguage);
}
setHandleMissingTranslation((key, replacements, options, err) => {
    console.error({"Missing translation": key});
    return key;
});
export default TranslationConfig;


