import { Box, Typography } from "@mui/material";
import { colors } from "../../Style";
import { useHistory, useLocation } from "react-router";
import { GetWindowState, defaultReducer, usePageState } from "../../Utils";
import { useEffect, useState } from "react";
import { TREND_MODE, getTimestamps } from "./Trends";
import hearth from "../../assets/ic_hearth_filled.svg";
import {
  calculateTrendInfoData,
  calculateTrendInfoTestData,
} from "./chartsUtils";
import { convertMinutesToTHHMM } from "../../TimeUtils";

const getHeaderFromMode = (mode) => {
  switch (mode) {
    case TREND_MODE.TREND_MODE_12_MIN:
      return "Last 12 Minutes";
    case TREND_MODE.TREND_MODE_1_HOUR:
      return "Last 1 Hour";
    case TREND_MODE.TREND_MODE_8_HOUR:
      return "Last 8 Hour";
    case TREND_MODE.TREND_MODE_24_HOUR:
      return "Last 24 Hour";
  }
};

const getTimeFromMode = (mode) => {
  switch (mode) {
    case TREND_MODE.TREND_MODE_12_MIN:
      return 12;
    case TREND_MODE.TREND_MODE_1_HOUR:
      return 60;
    case TREND_MODE.TREND_MODE_8_HOUR:
      return 60 * 8;
    case TREND_MODE.TREND_MODE_24_HOUR:
      return 60 * 24;
  }
};
export const TrendsInfo = (props) => {
  const history = useHistory();
  const location = useLocation();
  const state = GetWindowState();

  const deviceData = Array.from(props?.pro?.data);
  //const deviceData1 = calculateTrendInfoTestData()
  // deviceData?.reverse();

  const defaultMenu = {
    LEFT: { label: "8H", route: TREND_MODE.TREND_MODE_8_HOUR, local: true },
    UP: { label: "Trend Info", route: "trendInfo" },
    RIGHT: { label: "12M", route: TREND_MODE.TREND_MODE_12_MIN, local: true },
    MIDDLE: null,
    DOWN: { label: "Main", route: "home" },
  };

  const [pageState, setPageState] = usePageState(
    defaultReducer,
    state
      ? state
      : {
          menu: { ...defaultMenu },
          mode: TREND_MODE.TREND_MODE_1_HOUR,
        }
  );
  const updateNavigation = () => {
    setPageState((old) => {
      let menu = {};
      if (old.mode === TREND_MODE.TREND_MODE_12_MIN) {
        menu = {
          LEFT: {
            label: "1H",
            route: TREND_MODE.TREND_MODE_1_HOUR,
            local: true,
          },
          UP: { label: "Main", route: "home" },
          RIGHT: null,
          MIDDLE: null,
          DOWN: { label: "Trend", route: "trends" },
        };
      }
      if (old.mode === TREND_MODE.TREND_MODE_1_HOUR) {
        menu = {
          LEFT: {
            label: "8H",
            route: TREND_MODE.TREND_MODE_8_HOUR,
            local: true,
          },
          UP: { label: "Main", route: "home" },
          RIGHT: {
            label: "12M",
            route: TREND_MODE.TREND_MODE_12_MIN,
            local: true,
          },
          MIDDLE: null,
          DOWN: { label: "Trend", route: "trends" },
        };
      }

      if (old.mode === TREND_MODE.TREND_MODE_8_HOUR) {
        menu = {
          LEFT: {
            label: "24H",
            route: TREND_MODE.TREND_MODE_24_HOUR,
            local: true,
          },
          UP: { label: "Main", route: "home" },
          RIGHT: {
            label: "1H",
            route: TREND_MODE.TREND_MODE_1_HOUR,
            local: true,
          },
          MIDDLE: null,
          DOWN: { label: "Trend", route: "trends" },
        };
      }

      if (old.mode === TREND_MODE.TREND_MODE_24_HOUR) {
        menu = {
          LEFT: null,
          UP: { label: "Main", route: "home" },
          RIGHT: {
            label: "8H",
            route: TREND_MODE.TREND_MODE_8_HOUR,
            local: true,
          },
          MIDDLE: null,
          DOWN: { label: "Trend", route: "trends" },
        };
      }
      old.menu = menu;
      props.navigation.setMenu(menu);
      return old;
    });
  };
  const localNavigation = (navigator, action, menu) => {
    console.log({ pageState: pageState });

    if (action.type == "CLICK") {
      switch (menu[action.button].route) {
        case TREND_MODE.TREND_MODE_12_MIN:
          setPageState((state) => {
            return {
              ...state,
              mode: TREND_MODE.TREND_MODE_12_MIN,
            };
          });
          break;
        case TREND_MODE.TREND_MODE_1_HOUR:
          setPageState((state) => {
            return {
              ...state,
              mode: TREND_MODE.TREND_MODE_1_HOUR,
            };
          });
          break;
        case TREND_MODE.TREND_MODE_8_HOUR:
          setPageState((state) => {
            return {
              ...state,
              mode: TREND_MODE.TREND_MODE_8_HOUR,
            };
          });
          break;
        case TREND_MODE.TREND_MODE_24_HOUR:
          setPageState((state) => {
            return {
              ...state,
              mode: TREND_MODE.TREND_MODE_24_HOUR,
            };
          });
          break;
      }
    }
  };

  useEffect(() => {
    const state = pageState;
    const searchParams = new URLSearchParams();
    searchParams.append("windowState", JSON.stringify(state));
    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });

    if (
      pageState?.window?.menu == null ||
      pageState?.window?.menu == undefined
    ) {
      props.navigation.setMenu(pageState.menu);
    }
    props.navigation.addLocalNavigation(localNavigation);
    return () => {
      props.navigation.addLocalNavigation(null);
    };
  }, [pageState]);

  useEffect(() => {
    updateNavigation();
  }, [pageState.mode]);

  const [time, setTime] = useState(Date.now());
  const [timestamps, setTimestamps] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    setPageState((old) => {
      old.trendInfoData = calculateTrendInfoData(
        deviceData,
        getTimeFromMode(old.mode)
      );
      return old;
    });
  }, []);

  useEffect(() => {
    setPageState((old) => {
      old.trendInfoData = calculateTrendInfoData(
        deviceData,
        getTimeFromMode(old.mode)
      );
      return old;
    });
  }, [pageState.mode, time]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h6" sx={{ color: colors.COLORS_SUBITEM_DEFAULT }}>
        {getHeaderFromMode(pageState.mode)}
      </Typography>
      <TrendInfoComponent
        name={
          <Typography
            variant="h6"
            sx={{
              color: colors.SPO2,
              fontWeight: "bold",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            SpO2
          </Typography>
        }
        decimal={0}
        data={pageState.trendInfoData?.spo2}
        color={colors.SPO2}
      />
      <TrendInfoComponent
        decimal={1}
        data={pageState.trendInfoData?.o2}
        color={colors.O2}
        name={
          <Typography
            variant="h6"
            sx={{
              color: colors.O2,
              fontWeight: "bold",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            O2
          </Typography>
        }
      />
      <TrendInfoComponent
        decimal={0}
        name={
          <Box
          component="img"
          src={hearth}
          alt="Pulse"
          sx={{
            width: "30px",
            height: "30px",
          }}
        />
        }
        data={pageState.trendInfoData?.pulse}
        color={colors.PULSE}
      />
      <Box
        sx={{
          width: "100%",
          height: "50px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Box
          sx={{
            width: "33%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", ml: 10 }}>
            <Typography
              variant={"h6"}
              sx={{
                textAlign: "start",
                fontWeight: "bold",
                color: colors.COLORS_SUBITEM_DEFAULT,
                lineHeight: "0.5",
              }}
            >
              MIN
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                color: colors.COLORS_SUBITEM_DEFAULT,
              }}
            >
              T-HH:MM
            </Typography>
          </Box>
        </Box>
        <Box sx={{ width: "33%", display: "flex", justifyContent: "center" }}>
          <Typography
            variant={"h6"}
            sx={{ fontWeight: "bold", color: colors.COLORS_SUBITEM_DEFAULT }}
          >
            AVG
          </Typography>
        </Box>
        <Box
          sx={{
            width: "33%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", mr: 10 }}>
            <Typography
              variant={"h6"}
              sx={{
                textAlign: "end",
                fontWeight: "bold",
                color: colors.COLORS_SUBITEM_DEFAULT,
                lineHeight: "0.5",
              }}
            >
              MAX
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                color: colors.COLORS_SUBITEM_DEFAULT,
              }}
            >
              T-HH:MM
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const TrendInfoComponent = (props) => {
  let color = props.color;
  let lowValue = { value: props.data?.min, time: props.data?.minTimestampDiff };
  let hiValue = { value: props.data?.max, time: props.data?.maxTimestampDiff };
  let average = props.data?.average;
  let name = props.name;

  return (
    <Box
      sx={{
        mt: 1,
        mb: 1,
        width: "80%",
        height: "65px",
        display: "flex",
      }}
    >
      <Box
        sx={{
          mr: 1,
          width: "9%",
          height: "100%",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        {name}
      </Box>
      <Box sx={{ width: "2px", height: "100%", background: color }}></Box>
      <Box
        sx={{
          width: "30%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ height: "49%", width: "100%", ml: 1 }}>
          <Typography variant="h6" sx={{ color: color, fontWeight: "bold" }}>
            {lowValue.value}
          </Typography>
        </Box>
        <Box sx={{ height: "2px", width: "100%", background: color }}></Box>
        <Box sx={{ height: "49%", width: "100%", ml: 1 }}>
          <Typography variant="h6" sx={{ color: color, fontWeight: "bold" }}>
            {convertMinutesToTHHMM(lowValue.time)}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ width: "20%", height: "100%" }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            border: "2px solid " + color,
            borderRadius: "10px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" sx={{ color: color }}>
            {average?.toFixed(props.decimal)}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "30%",
          height: "100%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          flexDirection: "column",
        }}
      >
        <Box sx={{ height: "49%", width: "100%", mr: 1 }}>
          <Typography
            variant="h6"
            sx={{ textAlign: "end", color: color, fontWeight: "bold" }}
          >
            {hiValue.value}
          </Typography>
        </Box>
        <Box sx={{ height: "2px", width: "100%", background: color }}></Box>
        <Box sx={{ height: "49%", width: "100%", mr: 1 }}>
          <Typography
            variant="h6"
            sx={{ textAlign: "end", color: color, fontWeight: "bold" }}
          >
            {convertMinutesToTHHMM(hiValue.time)}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ width: "2px", height: "100%", background: color }}></Box>
      <Box sx={{ mr: 1, width: "9%", height: "100%" }}></Box>
    </Box>
  );
};
