import PlayArrowIcon from "@mui/icons-material/PlayArrow";
export const UpArrow=(props)=>{
    return(
      <PlayArrowIcon
      sx={{
        transform:"rotate(-90deg)"
      }}
    />
    )
  }
  export const DownArrow=(props)=>{
    return(
      <PlayArrowIcon
      sx={{
        ...props.sx,
        transform:"rotate(90deg)"
      }}
    />
    )
  }