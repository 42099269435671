import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const CustomLink = (props) => {
  const handleClick = (e) => {
    e.preventDefault();
    window.open(props.href, '_blank');
  };

  return (
    <Button sx={{height:"25px",display:"flex",justifyContent:"start",textAlign:"start"}} href={props.href} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
      {props.text}
    </Button>
  );
};

export default CustomLink;